.w-dropdown-search-desktop {
	position: static;
}

.top-nav-ul {
	display: block;
	float: right;
}

.top-nav-li {
	float: left;
}

.text-link {
	padding-top: 0rem;
	padding-bottom: 0rem;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #63ba00;
	font-size: 0.9rem;
	line-height: 1.25rem;
	font-weight: 500;
}

.text-link:hover {
	color: #00843d;
}

.logo-link {
	width: 351px;
	padding-top: 15px;
	padding-right: 20px;
	padding-bottom: 10px;
	border-bottom-style: none;
	background-color: white;
}

.logo-link:hover {
	background-color: transparent;
}

.logo-image {
	width: auto;
	height: 100%;
}

.header-section {
	background-color: white;
	box-shadow: none;
}

.slider-widget {
	height: 418px;
	margin-right: -50px;
	margin-left: -50px;
}

.home-cb-section {
	padding-top: 30px;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
}

.cb-col {
	padding-right: 25px;
	padding-bottom: 245px;
	padding-left: 25px;
	border-left: 1px solid #a6b1ab;
	box-shadow: #83c412 0px -30px 0px 0px inset;
}

.cb-col.last-cb-col {
	border-right: 1px solid #a6b1ab;
}

.header-div {
	z-index: 1200;
}

.slide-1 {
	height: 418px;
	background-image: url('/20150924150955/assets/images/farmer_1040x418.jpg');
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.slider-arrow {
	border: 1px solid black;
	border-radius: 40px;
}

.cb-image {
	position: absolute;
	left: 0px;
	right: 0px;
	bottom: 0px;
	display: block;
	margin-right: auto;
	margin-bottom: 20px;
	margin-left: auto;
	padding-right: 20px;
	padding-left: 20px;
}

.footer-section {
	padding-bottom: 10px;
}

.contact-info-div {
	padding: 1.25rem 5px;
	text-align: center;
}

.tse-logo-div {
	height: 57px;
	padding: 1.5rem 5px 0px;
	text-align: center;
}

.grip-logo-div {
	padding: 10px 5px 20px 5px;
	text-align: center;
}

.slider-arrow-div {
	display: none;
}

.slider-caption-div {
	position: relative;
	left: 0px;
	top: 40px;
	bottom: 40px;
	display: inline-block;
	margin-top: 0px;
	margin-bottom: 0px;
	padding: 1.125rem 2% 1.125rem 4.75%;
	float: left;
	background-color: rgba(255, 255, 255, 0.88);
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
}

.slider-caption-div.sub-caption {
	left: 0px;
	top: 50px;
	right: auto;
	bottom: -105px;
	display: block;
	max-height: 200px;
	max-width: 41%;
	margin-top: 0px;
	padding: 1rem 2% 1rem 4.75%;
	float: left;
	clear: left;
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
}

.slider-header {
	display: block;
	margin-top: 0px;
	margin-bottom: 0px;
	font-family: Oswald, sans-serif;
	font-size: 2.25rem;
	font-weight: 400;
	text-transform: uppercase;
	color: #83c412;
	line-height: 3.1rem;
}

.content .slider-header,
.content .slider-caption-div {
	display: none;
}

.mission-stmt,
.mission-stmt p {
	color: white;
	font-size: 1.1rem;
	line-height: 1.8rem;
	font-weight: 500;
	text-transform: none;
	text-align: center;
}

.mission-stmt p {
	padding-right: 4%;
	padding-left: 4%;
	margin-bottom: 0;
}

.mission-div {
	margin: 0px -50px;
	padding-top: 1.5rem;
	padding-bottom: 1.5rem;
	background-color: transparent;
	text-align: center;
}

.mission-section {
	margin-bottom: 0;
	background-color: #00843d;
}

.mission-container {
	background-color: transparent;
}

.slider-subheader,
.slider-caption-div p {
	color: #83c412;
	font-family: Oswald, sans-serif;
	display: inline-block;
	margin-top: 1rem;
	margin-bottom: 0.5rem;
	font-size: 1.5rem;
	line-height: 2rem;
	font-weight: 400;
	text-transform: none;
}

.slider-caption-div > p:last-of-type,
.slider-caption-div > p:only-child {
	margin-top: 0;
	margin-bottom: 0;
}

.slider-sub-subheader {
	display: block;
	margin-top: 0.5rem;
	margin-bottom: 0.65rem;
	font-family: Oswald, sans-serif;
	font-size: 1.25rem;
	line-height: 1.75rem;
	font-weight: 400;
	text-transform: none;
}

.slider-action-text {
	display: block;
	margin-top: 0px;
	margin-bottom: 0px;
	clear: left;
	color: #00843d;
	font-size: 1rem;
	line-height: 1.5rem;
}

.slide-2 {
	height: 418px;
	min-width: 0px;
	background-image: url('/20150924150955/assets/images/high-wires_1040x418.jpg');
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.more-link {
	margin-right: 4px;
	padding-top: 3px;
	padding-right: 1.2rem;
	padding-bottom: 3px;
	border-bottom-style: none;
	background-image: url('/20150924150955/assets/images/arrow_circle_medium_50pct_black.svg');
	background-position: 95% 50%;
	background-size: 0.9rem;
	background-repeat: no-repeat;
	font-size: 0.85rem;
	line-height: 1.2rem;
	text-decoration: none;
}

.more-link:hover {
	background-image: url('/20150924150955/assets/images/arrow_circle_medium_50pct_black.svg');
	background-position: 95% 50%;
	background-size: 0.9rem;
	background-repeat: no-repeat;
}

.nav-container {
	position: relative;
}

.nav-menu-icon {
	font-size: 1rem;
	line-height: 1.5rem;
}

.nav-menu-button-label {
	font-size: 1rem;
	line-height: 1.5rem;
}

.nav-dd {
	display: inline-block;
	margin-right: 0px;
	margin-left: 0px;
	margin-top: 0px;
	float: none;
	background-color: transparent;
	font-size: 1rem;
	vertical-align: top;
}

.nav-two-part-button {
	font-size: 1rem;
	line-height: 1.5rem;
}

.nav-dd-icon {
	width: 1rem;
	height: 1rem;
	font-size: 1rem;
	line-height: 1.5rem;
	text-align: center;
}

.nav-dd-list {
	padding: 0.25rem 0rem;
	background-color: #00843d;
}

.nav-dd-list.w--open {
	padding-right: 0rem;
	padding-bottom: 1rem;
	padding-left: 0rem;
	background-color: #00843d;
	color: transparent;
}

.nav-dd-link {
	margin-top: 1px;
	margin-bottom: 1px;
	padding: 0.625rem 2rem 0.625rem 0.75rem;
	border-bottom-style: none;
	color: white;
	font-size: 0.875rem;
	line-height: 1.125rem;
	font-weight: 400;
}

.nav-dd-link:hover {
	margin-bottom: 1px;
	background-color: #83c412;
	color: white;
	font-weight: 400;
}

.nav-dd-link.w--current {
	background-color: #83c412;
	color: white;
}

.nav-menu {
	float: none;
}

.two-part-button-arrow {
	width: 1.5rem;
	height: 1.5rem;
	min-height: 0px;
	min-width: 0px;
	float: right;
	background-image: url('/20150924150955/assets/images/example-bg.png');
	background-repeat: no-repeat;
}

.nav-bar-widget {
	background-color: #83c412;
}

.top-nav-wrapper {
	float: right;
}

.mag-glass {
	height: 51px;
	min-height: 0px;
	min-width: 50px;
	margin-top: 0px;
	background-image: url('/20150924150955/assets/images/mag_glass_white.svg');
	background-position: 50% 50%;
	background-size: 24px 24px;
	background-repeat: no-repeat;
}

.search-dd-toggle {
	padding: 0px;
	border-right: 1px dotted rgba(255, 255, 255, 0.49);
	border-left: 1px dotted rgba(255, 255, 255, 0.49);
	background-color: transparent;
	-moz-user-select: none;
	cursor: pointer;
	display: inline-block;
}

.search-dd-toggle:hover {
	background-color: #00843d;
}

.search-dd-toggle.w--open {
	border-right: 1px solid #00843d;
	border-left: 1px solid #00843d;
	background-color: #00843d;
}

.services-section {
	display: block;
}

.services-div {
	margin-right: -50px;
	margin-left: -50px;
	border-bottom: 1px solid #a6b1ab;
	background-color: #e8edea;
}

.services-links {
	padding: 0.75rem 0.8rem;
	color: #333;
	font-size: 0.85rem;
	font-weight: 600;
	letter-spacing: 0.025rem;
}

.services-links:hover {
	background-color: transparent;
	color: #83c412;
	font-weight: 600;
}

.services-arrow-div {
	width: 0px;
	height: 0px;
	min-height: 0px;
	min-width: 0px;
	margin-right: 0.4rem;
	float: left;
	border-top: 24px solid transparent;
	border-bottom: 24px solid transparent;
	border-left: 24px solid #83c412;
}

.services-wrapper {
	padding-right: 50px;
	background-color: transparent;
	font-size: 0;
}

.search-dd-list {
	display: none;
}

.search-dd-list.w--open {
	width: 975px;
	margin-left: 0;
	padding: 1rem 1rem 1rem 0rem;
	background-color: #00843d;
	min-width: 100%;
	position: absolute;
	right: -4px;
}

.search-dd-link {
	width: 23%;
	height: 44px;
	padding-right: 30px;
	padding-left: 30px;
	float: right;
	background-color: #83c412;
	color: white;
	text-align: center;
	text-transform: uppercase;
	border: 0 none;
}

.search-dd-link:hover {
	background-color: #00612d;
	color: white;
}

.search-field-fake {
	width: 75% !important;
	height: 44px !important;
	float: right;
	background-color: white !important;
	border: 0 none !important;
	margin-bottom: 0 !important;
}

.slide-3 {
	height: 418px;
	background-image: url('/20150924150955/assets/images/silos_1040x418.jpg');
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.slider-text-link,
span.slider_text_link a,
.slider-caption-div > p:last-of-type > a {
	display: inline-block;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	font-family: Oswald, sans-serif;
	color: #00843d;
	text-transform: uppercase;
}

.slider-text-link:hover,
span.slider_text_link a:hover,
.slider-caption-div > p:last-of-type > a:hover {
	color: #83c412;
}

.instagram-div {
	display: inline-block;
	width: 34px;
	height: 34px;
	min-width: 0px;
	background-image: url('/20190702134455/assets/images/instagram-dk-green.svg');
	background-position: 50% 50%;
	background-size: 28px 28px;
	background-repeat: no-repeat;
}

.instagram-div:hover {
	background-image: url('/20190702134455/assets/images/instagram-green.svg');
}

.inside-page-row {
	padding-top: 1.5rem;
	padding-bottom: 2rem;
}

.inside-page-left-column {
	background-color: transparent;
}

.inside-page-content-column {
	margin-left: -10px;
	padding-left: 20px;
	border-left: 1px solid transparent;
	min-height: 500px;
}

.breadcrumbs-text-block {
	margin-bottom: 1.5rem;
	color: #00843d;
	font-size: 0.8rem;
	line-height: 1.2rem;
	font-weight: 600;
}

.inside-page-nested-row {
	margin-top: 1.5rem;
	background-color: transparent;
}

.inside-page-nested-right-column {
	padding-top: 10px;
}

.right-sidebar-div {
	margin-bottom: 2rem;
	margin-left: 20px;
	padding-top: 0.75rem;
	border-top: 1px solid #a6b1ab;
}

.right-sidebar-div a {
	font-size: 0.8rem;
	line-height: 1.2rem;
	padding-top: 0;
	padding-bottom: 0;
}

.right-sidebar-div .content table {
	margin-bottom: 10px;
}

.right-sidebar-div .content td {
	vertical-align: top;
}

.right-sidebar-div .content td img {
	margin-right: 5px;
}

.right-sidebar-div h2 {
	font-size: 0.95rem;
	font-weight: 600;
	line-height: 1.6rem;
	margin-bottom: 0.3rem;
	margin-top: 0;
}

.intro-text-style {
	margin-top: 0rem;
	margin-bottom: 0.65rem;
	font-size: 1.25rem;
	line-height: 1.75rem;
}

.form-label {
	color: #636363;
	font-size: 0.9rem;
	line-height: 1.2rem;
	font-weight: 600;
}

.text-field {
	margin-bottom: 15px;
}

.list-item-text {
	color: #636363;
	font-size: 0.9rem;
}

.sidebar-heading {
	margin-top: 0px;
	font-weight: 600;
}

.right-sidebar-link {
	padding-top: 0rem;
	padding-bottom: 0rem;
	font-size: 0.9rem;
	line-height: 1.25rem;
}

.right-sidebar-li-link {
	padding-top: 0.35rem;
	padding-bottom: 0.35rem;
}

.right-sidebar-bullet-ul {
	margin-bottom: 0.65rem;
	padding-left: 20px;
}

.right-sidebar-ul-ol-item {
	color: #636363;
	font-size: 0.9rem;
	line-height: 1.25rem;
}

.inside-page-image {
	margin-top: 0.65rem;
	margin-bottom: 0.65rem;
}

.inside-page-image.photo-bottom-margin {
	margin-bottom: 1.5rem;
}

.left-nav-div {
	padding-right: 20px;
}

.inside-page-left-column .left-nav-div {
	padding-right: 0;
}

.left-nav-secondary {
	margin-right: -20px;
	margin-bottom: 0.75rem;
	padding: 5px 20px 5px 10px;
	background-color: transparent;
	color: #636363;
	font-size: 0.9rem;
	line-height: 1.25rem;
	font-weight: 500;
	letter-spacing: 0.03rem;
	text-transform: uppercase;
}

.left-nav-secondary:hover {
	color: #83c412;
}

.left-nav-secondary.current-section.left-nav-current {
	color: #00843d;
}

.left-nav-a {
	color: inherit;
	padding: 0;
	margin: 0;
	font-size: inherit;
	line-height: inherit;
	font-weight: inherit;
	letter-spacing: inherit;
}

.left-nav-a:hover {
	color: #83c412;
}

.left-nav-secondary.current-section {
	box-shadow: #a6b1ab 0px -1px 0px 0px inset;
}

.inner-left-list {
	list-style: none;
	display: inline;
}

.left-nav-tertiary {
	margin-right: -20px;
	margin-bottom: 0.5rem;
	padding: 5px 20px 5px 10px;
	color: #636363;
	font-size: 0.88rem;
	line-height: 1.25rem;
	font-weight: 500;
}

.left-nav-tertiary:hover {
	color: #83c412;
}

.left-nav-tertiary.left-nav-current {
	margin-right: -20px;
	background-color: rgba(71, 196, 18, 0.2);
	color: #00843d;
}

.left-nav-tertiary-ul {
	padding-left: 20px;
}

.outages-content-container {
	padding-top: 20px;
	padding-bottom: 30px;
}

.grip-logo-link {
	padding-top: 0rem;
	padding-bottom: 0rem;
}

.session-message-success,
.success {
	display: block;
	background-color: rgba(71, 196, 18, 0.2);
	text-align: left;
	padding: 6px 0 6px 20px;
	margin-bottom: 10px;
	color: black;
	font-weight: 500;
	font-size: 0.9rem;
	line-height: 1.25rem;
}

/* Begin Layout Adjustments */
.inside-page-row {
	margin-left: 0 !important;
}

.inside-page-left-column {
	padding-right: 17px;
}

.inside-page-content-column.with-left-container {
	margin-left: -17px;
	padding-right: 3px;
}

.inside-page-content-column.without-left-container {
	margin-left: 0;
}

.inside-page-left-column {
	background-color: var(--green-grey);
	border-radius: 10px;
	padding: 8px !important;
}

.inside-page-content-column.outages-dashboard {
	border-left : 0;
	padding-left: 0;
	padding-right: 0;
}
/* End Layout Adjustments */

span.mobile-view,
span.tablet-view {
	font-size: 0;
	width: 0;
	height: 0;
	display: none;
	color: white;
}

table.dataTable thead .sorting,
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc {
	background-image: none !important;
}

.dataTables_length,
.dataTables_length select {
	width: auto !important;
	height: auto;
	display: inline-block;
	padding: 0;
}

.dataTables_paginate a {
	font-size: 12px;
	line-height: 13px;
}

.dataTables_wrapper .dataTables_length,
.dataTables_wrapper .dataTables_info {
	float: left;
}

.dataTables_wrapper .dataTables_paginate,
.dataTables_wrapper .dataTables_filter {
	float: right;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
	padding: 1px 3px 2px 3px !important;
}

.dataTables_length input[type="text"] {
	display: inline-block;
}

.dataTables_wrapper .dataTables_filter input {
	margin-left: 0.5em !important;
}

.dataTables_wrapper .dataTables_length {
	float: none;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
	background: none !important;
	background-color: #83c412 !important;
	color: #ffffff !important;
	border: 1px solid #83c412 !important;
}

/* Wider Layout */
@media (min-width: 992px) {
	.wider-layout {
		max-width: 1200px;
	}

	.wider-main-column {
		width: 80%;
	}

	.smaller-left-column {
		width: 20%;
	}

	.smaller-right-column {
		width: 20%;
	}
}

@media (max-width: 991px) {
	.top-nav-li {
		float: left;
	}

	.logo-link {
		width: 39%;
		height: auto;
		margin-top: 3px;
		padding: 10px 0px;
	}

	.slider-widget {
		height: 308px;
		margin-right: 0px;
		margin-left: 0px;
	}

	.cb-row {
		padding-right: 10px;
		padding-left: 10px;
	}

	.slide-1 {
		height: 308px;
	}

	.contact-info-div {
		padding-top: 1rem;
		padding-right: 20px;
		padding-left: 20px;
	}

	.slider-caption-div {
		display: inline-block;
		margin-top: 5px;
		padding-right: 2%;
		padding-left: 2%;
		float: left;
		-webkit-transform: none;
		-ms-transform: none;
		transform: none;
	}

	.slider-caption-div.sub-caption {
		bottom: 37px;
		max-height: 140px;
		margin-top: 0px;
		padding-top: 0.125rem;
		padding-right: 2.5%;
		padding-left: 2%;
		clear: left;
		-webkit-transform: none;
		-ms-transform: none;
		transform: none;
	}

	.slider-header {
		font-size: 1.75rem;
		line-height: 2.25rem;
	}

	.mission-div {
		margin-right: 0px;
		margin-left: 0px;
	}

	.slider-subheader,
	.slider-caption-div p {
		margin-top: 0.75rem;
		margin-bottom: 0.25rem;
		font-size: 1.125rem;
		line-height: 1.75rem;
	}

	.slider-sub-subheader {
		margin-top: 0.25rem;
		margin-bottom: 0.5rem;
		font-size: 1rem;
		line-height: 1.5rem;
		font-weight: 400;
	}

	.slider-action-text {
		font-size: 0.85rem;
		line-height: 1.2rem;
	}

	.slide-2 {
		height: 308px;
	}

	.nav-container {
		max-width: 100%;
	}

	.nav-menu-button {
		width: 125px;
		height: 50px;
		padding-left: 20px;
		float: left;
	}

	.nav-menu-button:hover {
		background-color: #00843d;
	}

	.nav-menu-button.w--open {
		padding: 18px 18px 18px 20px;
		background-color: #00843d;
		font-size: 0.75rem;
		line-height: 1rem;
		text-align: center;
	}

	.nav-menu-icon {
		position: absolute;
		top: 50%;
		padding-left: 49px;
		float: right;
		-webkit-transform: translate(0px, -50%);
		-ms-transform: translate(0px, -50%);
		transform: translate(0px, -50%);
		color: white;
		font-size: 2.75rem;
		line-height: 1.75rem;
	}

	.nav-menu-button-label {
		position: absolute;
		top: 50%;
		float: left;
		-webkit-transform: translate(0px, -50%);
		-ms-transform: translate(0px, -50%);
		transform: translate(0px, -50%);
		color: white;
		font-size: 1rem;
		line-height: 1rem;
		text-align: center;
	}

	.nav-link {
		position: relative;
		bottom: 1px;
		display: block;
		margin-right: 0rem;
		padding-left: 1.125rem;
		border-bottom: 2px solid rgba(131, 196, 18, 0.4);
		font-size: 1.125rem;
		line-height: 1.375rem;
		text-align: left;
	}

	.nav-link:hover {
		background-color: #007336;
	}

	.bubble-section .navlinkwrapper .nav-link:hover {
		background-color: unset;
	}

	.nav-link.w--current {
		max-width: 100%;
		margin-right: auto;
		margin-bottom: 1px;
		padding-left: 1.125rem;
		box-shadow: rgba(255, 255, 255, 0.29) 1px 1px 0px 0px;
		color: white;
		font-size: 1.125rem;
		line-height: 1.375rem;
	}

	.nav-link.two-part-button-link {
		position: relative;
		z-index: 1;
		display: block;
		width: 80%;
		max-width: 100%;
		margin-right: auto;
		margin-bottom: 0px;
		padding: 1rem 0.75rem 1rem 1.125rem;
		float: left;
		border-bottom: 1px dotted rgba(255, 255, 255, 0.2);
		color: white;
		font-size: 1.125rem;
		line-height: 1.375rem;
		text-align: left;
		text-transform: uppercase;
	}

	.nav-link.two-part-button-link.child-link {
		height: 46px;
		padding-top: 0.75rem;
		padding-bottom: 0.75rem;
		padding-left: 2.5rem;
		border-bottom-style: none;
		border-left-style: none;
		box-shadow: rgba(255, 255, 255, 0.09) 0px 1px 0px 0px inset;
		font-size: 1rem;
		line-height: 1.35rem;
	}

	.nav-link.two-part-button-link.child-link:hover {
		background-color: #00612d;
	}

	.nav-link.two-part-button-link.child-link.w--current {
		background-color: #00612d;
	}

	.nav-link.dd-toggle.two-part-button-toggle {
		position: relative;
		z-index: 1;
		display: block;
		width: 20%;
		margin-right: auto;
		margin-bottom: 0px;
		padding-top: 0.75rem;
		padding-bottom: 0.75rem;
		float: right;
		border-left-color: rgba(255, 255, 255, 0.29);
		background-color: #007336;
		box-shadow: none;
		font-size: 1.125rem;
		line-height: 1.375rem;
		text-align: right;
	}

	.nav-link.dd-toggle.two-part-button-toggle:hover {
		background-color: #00612d;
	}

	.nav-link.dd-toggle.two-part-button-toggle.w--open {
		z-index: 1;
		box-shadow: none;
	}

	.nav-link.dd-toggle.two-part-button-toggle.child-toggle {
		border-bottom: 1px dotted rgba(255, 255, 255, 0.2);
		background-color: #00612d;
		box-shadow: none;
	}

	.nav-link.dd-toggle.two-part-button-toggle.child-toggle.w--open {
		background-color: #00612d;
		box-shadow: none;
	}

	.nav-link.nav-two-part-button-link {
		position: relative;
		z-index: 1;
		display: block;
		width: 80%;
		margin-right: auto;
		padding-top: 0.75rem;
		padding-bottom: 0.75rem;
		padding-left: 1.125rem;
		float: left;
		border-left-style: none;
		background-color: transparent;
		font-size: 1rem;
		line-height: 1.375rem;
	}

	.nav-link.nav-two-part-button-link:hover {
		background-color: #007336;
	}

	.nav-link.child-link {
		padding-top: 0.75rem;
		padding-bottom: 0.75rem;
		padding-left: 2.5rem;
		border-bottom: 1px none rgba(255, 255, 255, 0.2);
		border-left-style: none;
		font-size: 1rem;
	}

	.nav-link.child-link:hover {
		background-color: #00612d;
	}

	.nav-link.child-link.w--current {
		background-color: #00612d;
		box-shadow: none;
		color: #99d82a;
	}

	.nav-link.child-link.w--current:hover {
		color: white;
	}

	.nav-link.grandchild-link {
		padding-top: 0.75rem;
		padding-bottom: 0.75rem;
		padding-left: 3.5rem;
		border-bottom: 1px none rgba(255, 255, 255, 0.2);
		border-left-style: none;
		font-size: 0.95rem;
		line-height: 1.375rem;
		text-transform: none;
	}

	.nav-link.grandchild-link:hover {
		background-color: #004f25;
	}

	.nav-link.nav-link-mobile {
		padding-top: 0.75rem;
		padding-bottom: 0.75rem;
		border-left-style: none;
		font-size: 1rem;
	}

	.nav-dd.two-part-button {
		position: relative;
		top: 0px;
		right: 0px;
		z-index: 0;
		display: block;
		max-width: 100%;
		background-color: transparent;
		font-size: 1rem;
		line-height: 1.5rem;
		text-align: right;
	}

	.nav-two-part-button {
		position: relative;
		z-index: auto;
		display: block;
		max-width: 100%;
		margin-right: auto;
		margin-left: auto;
		clear: both;
		background-color: transparent;
		color: white;
	}

	.nav-dd-list {
		padding-bottom: 0.625rem;
		float: left;
		text-align: right;
	}

	.nav-dd-list.w--open {
		padding: 0rem 0rem 0.5rem;
		background-color: #007336;
		color: white;
	}

	.nav-dd-list.third-level-dd-list.w--open {
		background-color: #00612d;
	}

	.nav-dd-link {
		margin-bottom: 1px;
		padding: 1rem 0.75rem 1rem 2.5rem;
		font-size: 1.125rem;
		line-height: 1.375rem;
		text-align: left;
		text-transform: uppercase;
	}

	.nav-dd-link:hover {
		background-color: #004f25;
		color: white;
	}

	.nav-menu {
		padding-bottom: 2rem;
		background-color: #00843d;
		font-size: 1rem;
		line-height: 1.5rem;
	}

	.two-part-button-arrow {
		display: block;
		width: 1.375rem;
		height: 1.375rem;
		margin-right: auto;
		margin-left: auto;
		float: none;
		background-image: url('/20150924150955/assets/images/arrow_circle_medium_white.svg');
		background-size: 1.375rem 1.375rem;
	}

	.nav-bar-widget {
		height: 50px;
		margin-top: 4px;
	}

	.top-nav-wrapper {
		float: right;
	}

	.top-nav-wrapper-tablet-mobile-horiz {
		display: none !important;
	}

	.mag-glass {
		display: inline-block;
		width: 80px;
		height: 50px;
		background-color: transparent;
	}

	.mag-glass:hover {
		background-color: #00843d;
	}

	.search-dd-toggle.search-toggle-mobile {
		height: 50px;
		border-right: 1px none black;
		border-left-style: dotted;
		border-left-color: rgba(255, 255, 255, 0.49);
		background-color: transparent;
	}

	.search-dd-toggle.search-toggle-mobile.w--open {
		display: inline-block;
		border-left-style: dotted;
		border-left-color: rgba(255, 255, 255, 0.49);
		background-color: #00843d;
	}

	.search-dd-widget.search-widget-mobile {
		z-index: 1001;
		max-width: 80px;
		float: right;
		border-left-style: none;
		background-color: transparent;
	}

	.search-dd-widget.search-widget-mobile:hover {
		border-left-width: 1px;
		border-left-color: rgba(255, 255, 255, 0.49);
	}

	.services-div {
		margin-right: 0px;
		margin-left: 0px;
	}

	.services-links {
		padding-right: 0.45rem;
		padding-left: 0.45rem;
	}

	.services-arrow-div {
		display: block;
		width: 100%;
		margin-bottom: 2px;
		border-width: 20px 364px 0px;
		border-top-color: #83c412;
		border-right-style: solid;
		border-right-color: transparent;
		border-bottom-style: none;
		border-left-color: transparent;
	}

	.services-wrapper {
		padding-right: 0px;
		background-color: transparent;
		text-align: center;
	}

	.search-dd-list {
		display: none;
	}

	.search-dd-list.w--open {
		position: absolute !important;
		top: 49px;
		width: 100%;
		margin-left: 0;
		right: 0;
		/*margin-left: -668px;*/
		padding-top: 16px;
	}

	.facebook-div {
		-webkit-transition-property: none;
		transition-property: none;
		-webkit-transition-timing-function: ease;
		transition-timing-function: ease;
	}

	.facebook-div:hover {
		background-size: auto 28px;
	}

	.twitter-div {
		-webkit-transition-property: none;
		transition-property: none;
		-webkit-transition-timing-function: ease;
		transition-timing-function: ease;
	}

	.social-media-wrapper {
		display: block;
		margin-top: 4px;
	}

	.top-nav-row {
		display: inline-block;
		width: 50%;
		margin-top: 34px;
		padding-right: 10px;
		float: right;
	}

	.top-nav-col-1 {
		padding-right: 0px;
		padding-left: 0px;
	}

	.top-nav-col-2 {
		padding-right: 0px;
		padding-left: 0px;
	}

	.slide-3 {
		height: 308px;
	}

	.slider-text-link,
	span.slider_text_link a,
	.slider-caption-div > p:last-of-type > a {
		font-size: 0.9rem;
	}

	.top-nav-wrapper-mobile-horiz {
		display: block;
		float: left;
	}

	.top-nav-wrapper-tablet-mobile-horiz {
		float: right;
	}

	.inside-page-content-column {
		width: 100%;
		margin-left: 0px;
		padding-left: 10px;
		border-left-style: none;
	}

	.intro-text-style {
		font-size: 1.125rem;
		line-height: 1.65rem;
	}

	.left-nav-div {
		display: none;
	}

	.facebook-link-block {
		margin-left: 10px;
	}

	.twitter-link-block {
		margin-left: 10px;
	}

	.instagram-link-block {
		margin-left: 10px;
	}
}

@media (max-width: 767px) {
	.top-nav-ul {
		display: inline-block;
		float: left;
		font-size: 0;
	}

	.top-nav-li {
		display: inline-block;
		float: none;
	}

	.logo-link {
		width: 100%;
		margin-top: -1px;
		margin-bottom: 5px;
		padding-right: 25%;
		padding-bottom: 5px;
		padding-left: 25%;
		border-bottom: 1px dotted #a6b1ab;
	}

	.logo-link.w--current {
		border-bottom: 1px dotted #a6b1ab;
	}

	.slider-widget {
		height: 412px;
		background-color: white;
	}

	.home-cb-section {
		padding-top: 10px;
	}

	.cb-row {
		padding-right: 0px;
		padding-left: 0px;
	}

	.cb-col {
		padding-top: 10px;
		padding-bottom: 31px;
		border-left-style: none;
	}

	.cb-col.last-cb-col {
		border-right-style: none;
	}

	.slide-1 {
		height: 228px;
	}

	.cb-image {
		position: relative;
		left: auto;
		top: 0px;
		right: 0px;
		bottom: auto;
		width: 40%;
		margin-top: 20px;
		padding-right: 10px;
		float: right;
	}

	.cb-image.cb-image-even {
		width: 38%;
		padding-left: 10px;
		float: left;
	}

	.slider-caption-div.sub-caption {
		-webkit-transform: none;
		-ms-transform: none;
		transform: none;
	}

	.slider-header {
		margin-bottom: 0.25rem;
		font-size: 1.5rem;
		line-height: 2rem;
	}

	.slider-subheader,
	.slider-caption-div p {
		margin-top: 0.25rem;
		font-size: 1.125rem;
		line-height: 2rem;
	}

	.slide-2 {
		height: 228px;
	}

	.nav-menu-button {
		height: 45px;
	}

	.nav-link.two-part-button-link {
		font-size: 1rem;
	}

	.nav-link.two-part-button-link.child-link {
		border-bottom-style: none;
	}

	.nav-link.dd-toggle.two-part-button-toggle {
		box-shadow: none;
	}

	.nav-link.dd-toggle.two-part-button-toggle.w--open {
		box-shadow: none;
	}

	.nav-link.dd-toggle.two-part-button-toggle.child-toggle {
		border-bottom-style: none;
		box-shadow: none;
	}

	.nav-link.dd-toggle.two-part-button-toggle.child-toggle.w--open {
		box-shadow: none;
	}

	.nav-link.nav-two-part-button-link {
		width: 80%;
	}

	.nav-link.grandchild-link {
		border-bottom-style: none;
		font-size: 0.95rem;
	}

	.nav-link.nav-link-mobile {
		border-bottom-color: rgba(131, 196, 18, 0.4);
	}

	.nav-bar-widget {
		height: 45px;
	}

	.top-nav-wrapper {
		display: block;
		width: 50%;
		padding-top: 10px;
		padding-right: 15px;
	}

	.mag-glass {
		height: 45px;
	}

	.search-dd-toggle.search-toggle-mobile.w--open {
		background-color: #00843d;
	}

	.search-dd-widget.search-widget-mobile {
		max-width: 80px;
		background-color: transparent;
		height: 45px;
	}

	.services-div {
		overflow: hidden;
	}

	.services-links {
		padding-right: 1.125rem;
		padding-left: 1.125rem;
	}

	.services-arrow-div {
		display: block;
		margin-bottom: 2px;
		border-top-width: 10px;
		border-right-width: 284px;
		border-left-width: 284px;
	}

	.services-wrapper {
		text-align: center;
	}

	.search-dd-list.w--open {
		top: 45px;
	}

	.facebook-div {
		display: inline-block;
		width: 26px;
		height: 26px;
		margin-top: 0px;
		float: none;
		background-size: 24px 24px;
		-webkit-transition-property: none;
		transition-property: none;
		-webkit-transition-timing-function: ease;
		transition-timing-function: ease;
	}

	.facebook-div:hover {
		background-size: 24px 24px;
		-webkit-transition-property: none;
		transition-property: none;
		-webkit-transition-timing-function: ease;
		transition-timing-function: ease;
	}

	.twitter-div {
		display: inline-block;
		width: 26px;
		height: 26px;
		margin-top: 0px;
		margin-left: 0px;
		float: none;
		background-size: 24px 24px;
		-webkit-transition-property: none;
		transition-property: none;
		-webkit-transition-timing-function: ease;
		transition-timing-function: ease;
	}

	.twitter-div:hover {
		background-size: 24px 24px;
		-webkit-transition-property: none;
		transition-property: none;
		-webkit-transition-timing-function: ease;
		transition-timing-function: ease;
	}

	.social-media-wrapper {
		display: inline-block;
		margin-top: 0px;
		margin-right: 17px;
		margin-bottom: 4px;
	}

	.social-media-wrapper.mobile-soc-media {
		display: block;
		margin-right: auto;
		margin-bottom: 0px;
		margin-left: auto;
		padding-top: 4px;
		padding-right: 15px;
		float: right;
		clear: both;
		border-top: 1px none #a6b1ab;
		border-bottom: 1px none #a6b1ab;
		text-align: center;
	}

	.top-nav-row {
		display: inline-block;
		width: 30%;
		margin-top: 0px;
		padding-right: 20px;
	}

	.top-nav-col-2 {
		margin-bottom: 5px;
		text-align: right;
	}

	.slider-caption-mobile {
		position: absolute;
		left: 0px;
		top: 227px;
		right: 0px;
		bottom: 0px;
		display: block;
		width: 100%;
		height: 150px;
		padding: 1rem 20px 1.5rem;
		background-color: white;
	}

	.cb-div {
		display: block;
		width: 60%;
		margin-bottom: 30px;
		float: left;
	}

	.cb-div.cb-div-even {
		float: right;
	}

	.slide-3 {
		height: 228px;
	}

	.slider-text-link,
	span.slider_text_link a,
	.slider-caption-div > p:last-of-type > a {
		display: block;
	}

	.slide-nav {
		height: 32px;
		padding-top: 8px;
	}

	.instagram-div {
		display: inline-block;
		width: 26px;
		height: 26px;
		margin-top: 0px;
		margin-left: 0px;
		float: none;
		background-size: 24px 24px;
	}

	.top-nav-row-mobile {
		display: inline-block;
		width: 43%;
		float: right;
	}

	.top-nav-col-mobile {
		display: block;
		padding-right: 0px;
		padding-left: 0px;
		text-align: center;
	}

	.top-nav-div-mobile-vert {
		display: inline-block;
		padding-top: 4px;
		padding-left: 20px;
		float: left;
		clear: left;
		text-align: center;
	}

	.top-nav-wrapper-mobile-horiz {
		display: inline-block;
		padding-top: 4px;
		padding-left: 20px;
		float: left;
		clear: left;
	}

	.content-container {
		padding-right: 10px;
		padding-left: 10px;
	}

	.inside-page-row {
		padding-top: 1rem;
		padding-bottom: 1rem;
	}

	.right-sidebar-div {
		margin-left: 0px;
	}

	.intro-text-style {
		font-size: 1rem;
		line-height: 1.5rem;
	}

	.top-nav-wrapper-tablet-mobile-horiz {
		display: inline-block !important;
		margin-left: 0px;
		padding-top: 4px;
		padding-left: 6px;
		float: left;
	}

	.login-profile-dropdown {
		display: inline-block;
		height: 28px;
		margin-left: 10px;
		padding: 3px 30px 4px 10px;
		background-image: url('/20150924150955/assets/images/person_00843dDarkGreen.svg');
		background-position: 0px 50%;
		background-size: 20px 20px;
		background-repeat: no-repeat;
	}

	.login-profile-dropdown:hover {
		background-image: url('/20150924150955/assets/images/person_83c412green.svg');
		background-size: 20px 20px;
	}

	.dropdown-arrow-icon {
		margin-right: 0px;
		color: rgba(0, 0, 0, 0.5);
	}

	.account-dropdown {
		z-index: 1100;
		display: block;
		padding-right: 10px;
		float: left;
		box-shadow: #d6e4dd -1px 0px 0px 0px inset;
	}

	.account-dropdown-list {
		padding-top: 13px;
	}

	.account-dropdown-list.w--open {
		background-image: url('/20150924150955/assets/images/white-spacer.gif');
		background-size: 100% 6px;
		background-repeat: repeat-x;
		background-position: top;
		padding-bottom: 10px;
		background-color: #00843d;
	}

	.account-dropdown-link {
		background-color: transparent;
		color: white;
		font-size: 0.875rem;
		line-height: 1.25rem;
		font-weight: 400;
	}

	.account-dropdown-link:hover {
		background-color: #007336;
		color: white;
	}

	.header-container {
		margin-bottom: 6px;
	}

	.inside-page-content-column.with-left-container {
		margin-left: 0;
	}
}

@media (max-width: 479px) {
	.account-dropdown-list.w--open {
		background-image: url('/20150924150955/assets/images/white-spacer.gif');
		background-size: 100% 3px;
		background-repeat: repeat-x;
		background-position: top;
		padding-bottom: 10px;
		background-color: #00843d;
	}

	.top-nav-ul {
		display: block;
		padding-left: 0;
		float: none;
	}

	.top-nav-li {
		display: inline-block;
	}

	.top-nav-wrapper-tablet-mobile-horiz {
		display: none !important;
	}

	.logo-link {
		width: 100%;
		margin-bottom: 0px;
		padding-right: 30px;
		padding-left: 30px;
		border-bottom-style: none;
	}

	.logo-link.w--current {
		display: block;
		margin-right: auto;
		margin-left: auto;
		border-bottom: 1px dotted #a6b1ab;
	}

	.slider-widget {
		height: 358px;
		background-color: white;
	}

	.home-cb-section {
		padding-top: 10px;
	}

	.cb-col {
		padding-bottom: 235px;
	}

	.slide-1 {
		height: 128px;
	}

	.cb-image {
		position: absolute;
		left: 0px;
		top: auto;
		right: 0px;
		bottom: 0px;
		width: auto;
		margin-top: 10px;
		margin-right: auto;
		margin-left: auto;
	}

	.cb-image.cb-image-even {
		width: auto;
	}

	.contact-info-div {
		padding-right: 20px;
		padding-left: 20px;
		text-align: center;
	}

	.slider-header {
		font-size: 1.25rem;
		line-height: 1.75rem;
	}

	.mission-stmt,
	.mission-stmt p {
		font-size: 1rem;
		line-height: 1.7rem;
	}

	.slider-subheader,
	.slider-caption-div p {
		font-size: 1.25rem;
		line-height: 1.75rem;
	}

	.slider-sub-subheader {
		font-size: 1rem;
		line-height: 1.5rem;
	}

	.slide-2 {
		height: 128px;
	}

	.nav-menu-button {
		padding-top: 14px;
		padding-bottom: 14px;
	}

	.nav-menu-button.w--open {
		padding-top: 14px;
		padding-bottom: 12px;
	}

	.nav-link {
		display: block;
		margin-right: auto;
		padding-left: 1rem;
		border-left-style: none;
	}

	.nav-link.two-part-button-link {
		z-index: auto;
		width: 80%;
		padding-left: 2rem;
	}

	.nav-link.two-part-button-link.child-link {
		padding-left: 1rem;
		font-size: 0.95rem;
	}

	.nav-link.dd-toggle.two-part-button-toggle {
		box-shadow: none;
	}

	.nav-link.dd-toggle.two-part-button-toggle.w--open {
		box-shadow: none;
	}

	.nav-link.dd-toggle.two-part-button-toggle.child-toggle {
		box-shadow: none;
	}

	.nav-link.dd-toggle.two-part-button-toggle.child-toggle.w--open {
		box-shadow: none;
	}

	.nav-link.child-link {
		margin-right: 0px;
		padding-left: 1rem;
		font-size: 0.95rem;
	}

	.nav-link.grandchild-link {
		padding-left: 1rem;
	}

	.nav-dd.two-part-button {
		font-size: 0.97rem;
		text-align: left;
	}

	.nav-dd-list.w--open {
		max-width: 100%;
	}

	.nav-menu {
		top: 44px;
	}

	.nav-bar-widget {
		margin-top: 0px;
	}

	.search-dd-toggle.search-toggle-mobile.w--open {
		border-left-color: rgba(255, 255, 255, 0.49);
		background-color: #00843d;
	}

	.search-dd-widget.search-widget-mobile {
		background-color: transparent;
	}

	.services-links {
		display: block;
		text-align: left;
	}

	.services-arrow-div {
		display: block;
		border-left-width: 160px;
		border-right-width: 160px;
	}

	.services-wrapper {
		display: block;
		width: 50%;
		float: left;
		border-left: 1px solid #d7e1c6;
	}

	.search-dd-list.w--open {
		top: 45px;
	}

	.search-dd-link {
		width: 23%;
		padding-right: 10px;
		padding-left: 10px;
		font-size: 0.75rem;
	}

	.search-field-fake {
		width: 72% !important;
	}

	.top-nav-row {
		display: block;
		width: 100%;
		margin-bottom: 3px;
		padding-right: 0px;
		float: none;
		clear: left;
	}

	.top-nav-col-2 {
		margin-bottom: 0px;
		padding-right: 10px;
	}

	.slider-caption-mobile {
		top: 127px;
		height: 196px;
	}

	.cb-div {
		width: auto;
		margin-bottom: 10px;
	}

	.slide-3 {
		height: 128px;
	}

	.instagram-div {
		display: inline-block;
		width: 26px;
		height: 26px;
		margin-top: 0px;
		text-align: center;
	}

	.top-nav-div-mobile-vert {
		display: block;
		margin-right: auto;
		margin-left: auto;
		padding: 4px 10px;
		float: none;
	}

	.facebook-link-block {
		display: inline-block;
		width: 26px;
		margin-right: 8px;
		margin-left: 8px;
		float: none;
		text-align: center;
	}

	.twitter-link-block {
		width: 26px;
		margin-right: 8px;
		margin-left: 8px;
		float: none;
		text-align: center;
	}

	.instagram-link-block {
		width: 26px;
		margin-right: 8px;
		margin-left: 8px;
		float: none;
		text-align: center;
	}

	.login-profile-dropdown {
		padding-right: 31px;
	}

	.account-dropdown {
		display: inline-block;
		float: right;
		box-shadow: none;
	}

	.account-dropdown-list.w--open {
		margin-top: 0;
		position: absolute;
		right: 0;
	}

	.header-container {
		margin-bottom: 0px;
	}
}

/* CODE TO CHANGE THE STYLE OF THE WEBFLOW SLIDER INDICATORS */
.w-slider-nav > div {
	border: white 2px solid;
	background: green;
}

.w-slider-nav > div.w-active {
	border: white 2px solid;
	background-color: yellowgreen;
}
/* EQUAL HEIGHT HOMEPAGE CONTENT BOX COLUMNS */
@media screen and (min-width: 768px) {
	.cb-row {
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		overflow: hidden;
		align-items: stretch;
	}

	.cb-image {
		max-height: 223px;
	}
}
/* Services Liks - Even Distribution for Desktop */
@media screen and (min-width: 992px) {
	.department-wrapper {
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		justify-content: space-around;
	}
}

/*outages home page*/
.outages_wrapper {
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
}

.map {
	height: 500px;
}

#mapwindow {
	width: 85%;
}

#mapinfo {
	width: 15%;
	padding-left: 15px;
}

.map-layers-button {
	display: none;
}

.map-layers {
	display: block;
}

.map_layers h6 {
	padding-top: 10px;
	font-weight: 500;
	font-size: 14px;
}

.outages_wrapper .map_layers .layer-selector {
	margin-bottom: 0.625rem;
	padding: 5px;
}

.outages_wrapper .map_layers .form_button {
	margin-bottom: 10px;
	width: 100%;
}

.outages_wrapper .map_layers .reset a {
	font-size: 14px;
	display: block;
	text-align: center;
}

.map_layers .weather-radar-info {
	font-size: 10px;
	position: relative;
	bottom: 7px;
	display: none;
}

.map_layers .service-area-layer {
	padding-top: 0;
	margin-top: 0;
}

.map_layers .counties-filter-title,
.map_layers .cooperatives-filter-title {
	padding-top: 5px;
	margin-top: 2px;
}

.map_layers .weather-layer {
	margin-top: 3px;
}

#mapinfo .map_legend {
	position: absolute;
	bottom: 0;
}

#mapinfo .map_legend .map-legend-title,
#mapinfo .map_legend .map-legend-item {
	clear: both;
	float: left;
	padding-bottom: 3px;
}

#mapinfo .map_legend .map-legend-item .map-legend-color-wrapper {
	border: 1px solid;
	font-size: 11px;
}

#mapinfo .map_legend .map-legend-item .map-legend-color-wrapper .map-legend-color {
	padding-left: 12px;
	opacity: 0.75;
}

.outages_header_info {
	font-size: 17px;
	font-weight: 600;
	padding-top: 15px;
}

.outages-map-loading {
	display: inline-block;
	margin-left: 10px;
	width: 16px;
	height: 16px;
	background-image: url('/core/public/shared/assets/images/userinterface/processing.gif');
	background-repeat: no-repeat;
}

.loading {
	display: none;
	width: 16px;
	height: 16px;
	padding-left: 25px;
	background-image: url("/core/public/shared/assets/images/userinterface/processing.gif");
	background-repeat: no-repeat;
}

.outages_update_information {
	color: #000;
	padding-top: 5px;
	margin-bottom: 10px;
}

.outages_wrapper .map dl {
	margin-top: 0px;
	margin-bottom: 0px;
}

.outages_wrapper dl.outage_report,
.outages_wrapper dl.resource_details {
	margin-bottom: 0px;
	margin-top: 0px;
}

.outages_wrapper dl dt {
	color: #008457;
}

.outages_wrapper dl dd {
	margin: 0 0 0.5em 1.5em;
}

.outages_wrapper #popupWindow dd,
#legislativedistricts_module #popupWindow dd {
	padding-left: 0;
	margin-left: 0;
}

.outages_wrapper #popupWindow dd .date,
#legislativedistricts_module #popupWindow dd .date {
	display: block;
	font-size: 12px;
}

.outages_wrapper #popupWindow dd .subtitle,
#legislativedistricts_module #popupWindow dd .subtitle {
	font-size: 12px;
	font-weight: bold;
}

.outages_wrapper #popupWindow dd ul,
#legislativedistricts_module dd ul {
	padding-left: 25px;
}

.outages_wrapper #popupWindow dd ul li,
#legislativedistricts_module dd ul li {
	font-size: 12px;
}

.outages_wrapper #popupWindow dd a,
#legislativedistricts_module #popupWindow dd a {
	font-size: 12px;
	line-height: 13px;
}

.outages_wrapper .map dl dt,
.outages_wrapper dl.outage_report dt,
.outages_wrapper dl.resource_details dt,
#legislativedistricts_module .map dl dt {
	font-size: 0.9rem;
	font-weight: bold;
	margin: 0.1em 0.5em 0 0;
	padding: 0;
	line-height: normal;
}

.outages_wrapper .map dl dt,
#legislativedistricts_module .map dl dt {
	font-size: 1.1em;
}

.outages_wrapper .map dl dt a,
.outages_wrapper dl.outage_report dt a,
.outages_wrapper dl.resource_details dt a,
#legislativedistricts_module .map dl dt a {
	font-size: 0.9rem;
	font-weight: bold;
	margin: 1em 0.5em 0 0;
	padding: 0;
	line-height: normal;
	color: #008457;
}

.outages_wrapper dl.outage_report dd,
.outages_wrapper dl.resource_details dd {
	font-weight: bold;
	margin: 0 0.5em 0 1em;
	padding: 0;
	font-size: 0.9rem;
	line-height: normal;
}

.outages_wrapper .map dl dd {
	font-weight: bold;
	margin: 0 0.5em 0 0;
	padding: 0;
}

.outages_wrapper .map dl dd div {
	font-weight: normal;
	padding-bottom: 5px;
}

.outages_wrapper .map dl dd div dl dd {
	font-weight: normal;
}

.outages_wrapper .map dl dd div dl dd span {
	font-weight: bold;
}

.outages_wrapper .map dl dd span {
	display: block;
	font-size: 0.95em;
	font-weight: normal;
}

.outages_wrapper .map dl dd p,
.outages_wrapper dl.resource_details dd p {
	font-weight: normal;
	font-size: 12px;
	padding-top: 2px;
	padding-bottom: 2px;
	margin-top: 0px;
	margin-bottom: 0px;
}

.outages_wrapper .map dl dd ul,
.outages_wrapper dl.outage_report dd ul,
.outages_wrapper dl.resource_details dd ul {
	padding: 2px 0px 0px 25px;
	margin: 0px;
}

.outages_wrapper .map dl dd ul li,
.outages_wrapper dl.outage_report dd ul li,
.outages_wrapper dl.resource_details dd ul li {
	line-height: 1em;
	padding: 0px 0px 5px 0px;
	font-weight: normal;
}

.outages_wrapper .coopupdated,
.outages_wrapper .report_request {
	display: block;
	font-weight: normal;
	font-size: 0.95em;
}

#outagesCbBgBottom {
	min-height: 215px;
}

.outagesCbMedium {
	display: inline;
	float: left;
	margin: 0 1% 0 0;
	padding: 14px 0 0;
	width: 49%;
}

.outagesCbSmall {
	display: inline;
	float: left;
	margin: 0 0 0 1%;
	padding: 14px 0 0;
	width: 49%;
}

.outages_wrapper #outagesCbBgTop .outage_tabs {
	width: 145px;
}

.outage_tabs {
	width: 100%;
	max-width: 339px;
	padding-bottom: 5px;
}

.outage_tabs_bg {
	background-color: #d4d4d4;
	border-radius: 4px;
	padding: 2px 10px;
}

.outage_tabs a,
.outage_tabs a:hover,
.outage_tabs a.selected,
.outage_tabs a.selected:hover {
	display: inline-block;
	font-size: 12px;
	font-weight: 600;
	padding: 0 15px 0 0;
	text-decoration: none;
	color: #333333;
}

.outage_tabs a.no-right-padding,
.outage_tabs a.no-right-padding:hover,
.outage_tabs a.no-right-padding.selected,
.outage_tabs a.no-right-padding.selected:hover {
	padding-right: 0;
}

.outage_tabs a:hover,
.outage_tabs a.selected,
.outage_tabs a.selected:hover {
	color: #006630;
}

.map_layers .form_button {
	margin-right: 0 !important;
}

.select2-search-choice-close,
.select2-search-choice-close:hover {
	padding: 0;
	transition-property: none;
}

@media (max-width: 991px) {
	span.tablet-view {
		color: black;
	}

	span.mobile-view {
		color: white;
	}
}

@media (max-width: 767px) {
	.outagesCbMedium,
	.outagesCbSmall {
		float: none;
		width: 100%;
		display: block;
	}

	span.tablet-view {
		color: white;
	}

	span.mobile-view {
		color: black;
	}

	.outages_wrapper {
		padding-left: 15px;
		padding-right: 15px;
	}

	.outages_header_info {
		padding-top: 2px;
		font-size: 14px;
	}


	.outagesCbMedium {
		margin-left: 0;
	}

	.map {
		height: 400px;
	}

	#mapwindow,
	#mapinfo {
		display: block;
		width: 100%;
	}

	#mapinfo {
		padding-left: 0;
	}

	#mapinfo .map_layers {
		display: none;
		padding-bottom: 10px;
	}

	.map_layers h6 {
		display: inline-block;
		padding-top: 5px;
	}

	.map_layers .weather-layer {
		display: block;
		margin-top: 0;
		padding-top: 0;
	}

	.map_layers .weather-layer .Weather {
		margin-left: 10px;
	}

	.map_layers .service-area-layer,
	.map_layers .legislative-layer,
	.map_layers .counties-filter-title,
	.map_layers .cooperatives-filter-title {
		width: 49% !important;
	}

	.map_layers .ServiceAreaGroupLayer,
	.map_layers .LegislativeGroupLayer {
		display: inline-block;
		text-align: left;
		padding-left: 0;
		margin-left: 0;
		width: 49% !important;
	}

	.map_layers .buttons-wrapper {
		width: 99%;
		overflow: hidden;
	}

	.map_layers .buttons-wrapper button {
		float: left;
	}

	.map_layers .buttons-wrapper span {
		float: right;
	}

	.map_layers .buttons-wrapper span a {
		display: inline-block;
	}

	.map_layers .ServiceAreaGroupLayer {
		margin-bottom: 5px;
	}

	.map_layers .select2-container {
		width: 49% !important;
	}

	.map_layers .select2-container-multi .select2-choices .select2-search-field input {
		margin: 0;
		height: auto;
		line-height: inherit;
	}

	.map_layers .form_button.primary {
		display: block;
		margin-right: 10px !important;
	}

	.map_layers .reset {
		display: inline-block;
	}

	#mapinfo .map_legend {
		position: relative;
		padding-top: 10px;
	}

	#mapinfo #ResourcesMapLegend .map-legend-title {
		width: 100%;
	}

	#mapinfo .map_legend .map-legend-item {
		clear: none;
		padding-left: 10px;
		padding-bottom: 0;
	}

	#mapinfo .map_legend .map-legend-item .map-legend-color-wrapper .map-legend-color {
		padding-left: 15px;
	}

	.outagesCbBody {
		padding-bottom: 5px;
	}
}

@media (max-width: 479px) {
	span.tablet-view {
		color: white;
	}

	span.mobile-view {
		color: black;
	}

	.map {
		height: 275px;
	}

	.outages_update_information {
		font-size: 12px;
		line-height: 13px;
	}

	.map-layers-button {
		display: block;
		margin-bottom: 0;
	}

	.cb-image {
		max-height: 223px;
	}
}

@media (max-width: 400px) {
	span.mobile-view {
		color: black;
	}

	#mapinfo .map_legend .map-legend-title {
		width: 100%;
	}

	#mapinfo .map_legend .map-legend-item {
		padding-left: 5px;
		margin-bottom: 3px;
	}

	#mapinfo #CountiesMapLegend.map_legend .map-legend-item.first {
		padding-left: 0;
	}

	.outagesCbMedium {
		padding-top: 0;
	}
}

/*members login page*/
.mm-page.mm-slideout > #account_module {
	padding: 30px 15px;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
	margin-left: auto;
	margin-right: auto;
	max-width: 940px;
}

/*members home page*/
#membersCbBgTopStretch {
	margin-top: 10px;
}

#membersCbBgBottomStretch {
	margin-top: 5px;
}

#membersCbBgTop {
	min-height: 255px;
	overflow: hidden;
}

#membersCbBgBottomStretch {
	margin-top: 5px;
}

.membersCbBig {
	display: inline;
	float: left;
	margin: 0 20px 0 0;
	padding: 14px 0 0;
	width: 58%;
}

.membersCbMedium {
	display: inline;
	float: left;
	padding: 14px 0 0;
	width: 38%;
}

.membersCbSmall {
	display: inline;
	float: left;
	margin: 0 20px 0 0;
	padding: 14px 0 0;
	width: 28%;
}

#newsletters_module.homepage_contentbox.displaymode_list_with_issues .newsletters a,
#newsletters_module.homepage_contentbox.displaymode_list_with_issues .newsletters a:hover,
#newsletters_module.homepage_contentbox.displaymode_list_with_issues .newsletters a.selected,
#newsletters_module.homepage_contentbox.displaymode_list_with_issues .newsletters a.selected:hover {
	display: inline-block;
	font-size: 12px !important;
	font-weight: 600 !important;
	padding: 0 15px 0 0 !important;
	text-decoration: none;
	color: #83c412 !important;
}

#newsletters_module.homepage_contentbox.displaymode_list_with_issues .newsletters a:hover,
#newsletters_module.homepage_contentbox.displaymode_list_with_issues .newsletters a.selected,
#newsletters_module.homepage_contentbox.displaymode_list_with_issues .newsletters a.selected:hover {
	color: #00843d !important;
}

#newsletters_module.homepage_contentbox.displaymode_list_with_issues .list_item .metadata1.title a:hover {
	text-decoration: underline;
}

#membersCbBgTop #newsletters_module .footer a:hover {
	text-decoration: underline;
}

#membersCbBgTop #news_module .content a {
	font-size: 11px;
}

#membersCbBgTop #news_module .list_item {
	border-bottom: 1px solid #dddddd;
	padding-bottom: 5px;
	margin-bottom: 5px;
}

#membersCbBgTop #news_module .footer a:hover {
	text-decoration: underline;
}

#membersCbBgBottom #events_module a:hover {
	text-decoration: underline;
}

@media (max-width: 767px) {
	.members-home-cb {
		padding: 20px;
	}

	#membersCbBgTop .membersCbBig,
	#membersCbBgTop .membersCbMedium {
		float: none;
	}

	#membersCbBgBottom .membersCbMedium {
		clear: both;
		width: 100%;
	}

	#membersCbBgBottom .membersCbSmall {
		width: 45%;
	}
}

@media (max-width: 479px) {
	#membersCbBgBottom .membersCbMedium,
	#membersCbBgBottom .membersCbSmall {
		float: none;
		width: 100%;
	}
}

/*Members Landing Page*/
#membersNavigationCbBgBottomStretch {
	clear: both;
	padding-top: 25px;
	overflow: hidden;
}

#membersNavigationCbBgBottom {
	min-height: 215px;
}

.membersNavigationCbBig {
	display: inline;
	float: left;
	margin: 0 2% 0 0;
	padding: 0;
	width: 66%;
}

.membersNavigationCbSmall {
	display: inline;
	float: left;
	margin: 0 2% 0 0;
	width: 32%;
}

.membersNavigationCbBody {
	padding: 10px 10px 2px;
}

.membersNavigationCbBody p {
	color: #333333;
	font-size: 12px;
	line-height: normal;
	text-decoration: none;
}

.membersNavigationCbBody a {
	font-size: 12px;
}

@media (max-width: 767px) {
	.membersNavigationCbBig,
	.membersNavigationCbSmall {
		float: none;
		width: 100%;
	}

	.membersNavigationCbBody {
		padding-left: 0;
		padding-right: 0;
	}

	.membersNavigationCbBig,
	.membersNavigationCbSmall {
		margin: 0;
	}
}

/*forms*/
.real-content input[type="text"],
.real-content input[type="tel"],
.real-content input[type="password"],
.real-content input[type="search"],
.real-content select,
.real-content textarea,
.real-content optgroup {
	width: 100% !important;
	max-width: 500px;
}

/*feature pages*/
/*accounts*/
#account_module.form .form_item.required .field {
	background: none !important;
}

#account_module.form .form_item.required .label:after {
	content: url("/core/public/shared/assets/images/required.gif");
	padding-left: 5px;
}

#account_module.form .form_item .label,
#account_module.form .form_item.required .label,
#account_module.form .form_item.required label {
	display: inline !important;
}

#account_module.form .form_buttons {
	text-align: left !important;
}

#account_module label[for="PersistentLogin"] {
	display: inline;
}

#account_module .more_options {
	text-align: left;
}

#account_module.form .more_options ul {
	margin: 20px 0 0;
	width: 100%;
}

/*blogs*/
@media (max-width: 767px) {
	#blogs_module td {
		display: block;
		width: 100% !important;
	}
}

/*faqs*/
.box.tools input[type="text"] {
	max-width: 100%;
	width: 100%;
}

.box.tools button[type="submit"] {
	margin-top: 0;
}

@media (max-width: 767px) {
	.box.tools button[type="submit"] {
		display: block;
	}
}

/*files*/
.box.filter .select2-container,
.box.filter #filter-title {
	width: 100% !important;
	max-width: none;
}

.box.filter .select2-container a {
	border: 1px solid #aaa;
}

.filter-row {
	overflow: visible;
}

.field--category--title {
	padding-left: 22px !important;
}

.field--category--title a {
	padding-top: 0 !important;
}

.field--file--title {
	padding-left: 22px !important;
}

.field--file--title a {
	padding-top: 0 !important;
}

/*jobs*/
#jobs_module .form-fields {
	max-width: 100%;
}

#jobs_module div.application-for-employment input[type="text"],
#jobs_module div.application-for-employment form div.form-field span.text input {
	height: 38px !important;
	font-size: 16px !important;
}

@media (max-width: 991px) {
	#jobs_module div.application-for-employment * {
		width: 100% !important;
	}

	#jobs_module div.application-for-employment button {
		width: auto !important;
	}
}

.right-sidebar .box .content .contentbox_item .contentbox_item_content a {
	display: block;
}

#jobs_module h2 {
	font-size: 2rem;
}

#jobs_module h3 {
	font-size: 1.5rem;
}

#jobs_module h4 {
	font-size: 1.25rem;
}

#jobs_module.cms_form .cms_label {
	width: 30%;
	background-color: transparent !important;
	display: inline;
}

#jobs_module.cms_form .cms_required {
	width: 5%;
	display: inline;
	background-color: transparent !important;
}

#jobs_module.cms_form .cms_field {
	background-color: transparent !important;
}

#jobs_module.cms_form .cms_field input[type="text"] {
	width: 300px !important;
	max-width: 100% !important;
}

/*locations*/
@media (max-width: 767px) {
	#locations_module .type_item {
		float: none !important;
		clear: both;
		width: 100% !important;
	}
}

/*news*/
@media (max-width: 767px) {
	#news_module td {
		display: block;
		width: 100% !important;
	}

	#news_module td.cms_list_item.right {
		padding-left: 0 !important;
	}
}

#news_module .tile_description .cms_metadata1.cms_title h3 {
	margin-top: 0;
}

/*newsletters*/
#newsletters_module .form_container tr.required .label:after {
	content: url("/core/public/shared/assets/images/required.gif");
	padding-left: 5px;
}

#newsletters_module.form .form_buttons {
	text-align: left !important;
}

#news_module .cms_metadata2.cms_date h3,
#news_module .cms_list_item .cms_title h3,
#news_module .cms_list_item .cms_title h3 a,
#news_module .cms_list_item .description a {
	font-size: inherit !important;
	line-height: inherit !important;
}

/*photo albums*/
@media (max-width: 767px) {
	#photoalbums_module td {
		display: block;
		width: 100% !important;
	}
}
/*resources*/
#moduleDocuments_module .cms_title .icon {
	margin-bottom: 0 !important;
	height: 30px !important;
}

#moduleDocuments_module .cms_items .cms_description {
	background-position: left 8px;
}

/*staff*/
@media (max-width: 767px) {
	#staffdirectory_module td {
		display: block;
		width: 100% !important;
	}
}

#staffdirectory_module .cms_list_subheading {
	margin-top: 0.75rem;
	margin-bottom: 0.3rem;
	color: #00843d;
	font-size: 1.75rem;
	line-height: 2.35rem;
	font-weight: 500;
}

/*videos*/
@media (max-width: 767px) {
	#video_module td {
		display: block;
		width: 100% !important;
	}
}

/* Outages Dashboard */
.ui-datepicker {
	z-index: 2000 !important;
}

.outages_wrapper .big-cb-body-wrapper .big-cb-body .dataTables_paginate *,
.outages_wrapper .outagesCbBody .outage-details * {
	margin: 0;
	padding: 0;
	font-weight: normal;
}

.outages_wrapper .big-cb-body a {
	font-size: 12px;
}

.outages_wrapper .big-cb-body .dataTables_filter,
.outages_wrapper .big-cb-body .dataTables_length,
.outages_wrapper .outagesCbBody .outage-details .dataTables_filter,
.outages_wrapper .outagesCbBody .outage-details .dataTables_length {
	padding-bottom: 5px;
}

.outages_wrapper .big-cb-body .dataTables_info,
.outages_wrapper .big-cb-body .dataTables_paginate,
.outages_wrapper .outagesCbBody .outage-details .dataTables_info,
.outages_wrapper .outagesCbBody .outage-details .dataTables_paginate {
	padding-top: 5px;
}

.outages_wrapper .big-cb-body .paginate_button.previous.disabled,
.outages_wrapper .outagesCbBody .outage-details .paginate_button.previous.disabled {
	padding-right: 5px;
}

.outages_wrapper .big-cb-body .paginate_button.next.disabled,
.outages_wrapper .outagesCbBody .outage-details .paginate_button.next.disabled {
	padding-left: 5px;
}

.outages_wrapper .big-cb-body table,
.outages_wrapper .outagesCbBody .outage-details table {
	border-collapse: collapse;
	width: 100%;
}

.outages_wrapper .big-cb-body table thead tr th,
.outages_wrapper .outagesCbBody .outage-details table thead tr th {
	margin: 0;
	padding: 5px;
	background-color: #83c412;
	color: #ffffff;
	font-size: 12px;
	font-weight: bold;
	text-align: left;
	cursor: pointer;
}

.outages_wrapper .big-cb-body table thead tr th.nowrap,
.outages_wrapper .big-cb-body table tbody tr td.nowrap,
.outages_wrapper .outagesCbBody .outage-details table thead tr th.nowrap,
.outages_wrapper .outagesCbBody .outage-details table tbody tr td.nowrap {
	white-space: nowrap;
}

.outages_wrapper .big-cb-body table thead tr th.sortable:hover,
.outages_wrapper .outagesCbBody .outage-details table thead tr th.sortable:hover {
	cursor: pointer;
}

.outages_wrapper .big-cb-body table thead tr th:first-child,
.outages_wrapper .outagesCbBody .outage-details table thead tr th:first-child {
	border-top-left-radius: 4px;
}

.outages_wrapper .big-cb-body table thead tr th:last-child,
.outages_wrapper .outagesCbBody .outage-details table thead tr th:last-child {
	border-top-right-radius: 4px;
}

.outages_wrapper .big-cb-body table tbody tr td,
.outages_wrapper .big-cb-body table tfoot tr td,
.outages_wrapper .outagesCbBody .outage-details table tbody tr td,
.outages_wrapper .outagesCbBody .outage-details table tfoot tr td {
	padding: 5px;
}

.outages_wrapper .big-cb-body table tbody tr td:last-child,
.outages_wrapper .outagesCbBody .outage-details table tbody tr td:last-child {
	white-space: nowrap;
}

@media (max-width: 767px) {
	.outages_wrapper .big-cb-body table thead tr th.nowrap,
	.outages_wrapper .big-cb-body table tbody tr td.nowrap,
	.outages_wrapper .outagesCbBody .outage-details table thead tr th.nowrap,
	.outages_wrapper .outagesCbBody .outage-details table tbody tr td.nowrap,
	.outages_wrapper .big-cb-body table tbody tr td:last-child,
	.outages_wrapper .outagesCbBody .outage-details table tbody tr td:last-child {
		white-space: normal;
	}
}

.outages_wrapper .outagesCbBody .resources-summary span {
	display: inline-block;
	margin-right: 10px;
	min-width: 25px;
	text-align: right;
}

.outages_wrapper .outagesCbBody .resources-summary ul {
	list-style-type: none;
	margin: 0;
	padding: 0;
}

.outages_wrapper .outagesCbBody .resources-summary ul li {
	padding-bottom: 5px;
}

.outages_wrapper .select2-container-multi .select2-choices .select2-search-choice,
.select2-results .select2-result-label {
	font-size: 11px;
	line-height: 12px;
}

.membersNavigationCbBody .slider-widget {
	height: auto !important;
	margin: 0 !important;
}

.membersNavigationCbBody .w-slide {
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-size: cover;
	height: 120px !important;
}

@media (max-width: 767px) {

	.page-14 .mobile-sections {
		padding: 10px;
	}

	.page-14 .mobile-sections .mobile-section {
		width: 50%;
	}

	.page-14 .mobile-sections .mobile-section a.button {
		display: block;
		margin: 10px 0;
		padding: 20px;
	}

	.page-14 .mobile-sections .mobile-section a.button span.icon {
		background-position: center;
		background-repeat: no-repeat;
		display: block;
		height: 42px;
		text-align: center;
	}

	.page-14 .mobile-sections .mobile-section a.button span.directory {
		background-image: url( "/images/mobile/directory.png" );
	}

	.page-14 .mobile-sections .mobile-section a.button span.activations {
		background-image: url( "/images/mobile/activations.png" );
	}

	.page-14 .mobile-sections .mobile-section a.button span.forums {
		background-image: url( "/images/mobile/forums.png" );
	}

	.page-14 .mobile-sections .mobile-section a.button span.events {
		background-image: url( "/images/mobile/events.png" );
	}

	.page-14 .mobile-sections .mobile-section a.button span.text {
		display: block;
		margin: 10px auto 0px auto;
		text-align: center;
	}

	.page-14 .content-container {
		display: none;
	}
}

#events_module.cms_list h4 {
	font-size: inherit;
}

.page-5293 .map-wrapper {
	height: auto !important;
}

#video_module.cms_list .cms_list_item .cms_video {
	padding-right: 10px;
}

#video_module.cms_list .cms_list_item .cms_video .padded {
	padding: 0;
}

.homepage_contentbox a {
	font-size: inherit;
}

/* News Homepage */
#news_module.homepage_contentbox .list_item .content {
	padding-top: 5px !important;
	padding-bottom: 5px !important;
}

#submissionforms_module.cms_form .cms_buttons {
	margin-left: 0;
}

.search-collection > .search-node {
	margin-top: 1.5em;
	padding-top: 1.5em;
}

/* Show session message on the Members homepage */
.members-session-message .error {
	text-align: center;
	margin-bottom: 0;
}

/* hide search form for specific page */
.page-10436 .w-dropdown-search-desktop,
.page-10436 .w-nav[data-collapse="medium"] .w-dropdown-toggle.search-toggle-mobile,
.page-10436 .search-dd-link,
.page-10436 .search-field-fake {
	display: none;
}

.page-10436 .inside-page-content-column > h1 {
	display: none;
}

.collection + .collection {
	margin-top: 18px;
	padding-top: 18px;
}

.collection > .node + .node {
	margin-top: 18px;
	padding-top: 18px;
}

.node .field.field--file.field--file--title {
	display: flex !important;
}

.field.field--file.field--file--add {
	margin: 36px 0 0 0;
}

.module-files .right-sidebar-div .filter-row {
	margin: 0 0 18px 0;
	width: 216px;
}

.module-files .right-sidebar-div .select2-container-multi {
	float: none;
}

.module-files .right-sidebar-div .filter-row.ui-form-buttons {
	align-items: center;
	display: flex;
}

.module-files .right-sidebar-div .filter-row.ui-form-buttons .ui-form-field {
	margin: 0;
}

.module-files .right-sidebar-div .filter-row.ui-form-buttons .ui-form-field + .ui-form-field {
	margin: 0 0 0 18px;
}

.module-files .right-sidebar-div .filter-row.ui-form-buttons .ui-form-field button {
	margin: 0;
}

.hide {
	display: none;
}

.invisible {
	visibility: hidden;
}

:root {
	--black: #272727;
	--dark-green-iaec: #008457;
	--green-grey: #e8edea;
	--white: white;
	--light-green-iaec: #82c50f;
	--maroon\<deleted\|variable-5e915bfa\>: #870011;
	--gutterworks: #50064d;
	--darker-green: #005a3b;
	--white-smoke: #efefef;
	--dark: #303;
}

.w-backgroundvideo-backgroundvideoplaypausebutton:focus-visible {
	outline-offset: 2px;
	border-radius: 50%;
	outline: 2px solid #3b79c3;
}

.w-layout-grid {
	grid-row-gap: 16px;
	grid-column-gap: 16px;
	grid-template-rows: auto auto;
	grid-template-columns: 1fr 1fr;
	grid-auto-columns: 1fr;
	display: grid;
}

body {
	color: #333;
	font-family: Montserrat, sans-serif;
	font-size: 16px;
	line-height: 20px;
}

a {
	color: var(--black);
	text-decoration: underline;
}

a:hover {
	color: var(--dark-green-iaec);
	text-decoration: none;
}

.top-nav-div {
	border-bottom: 1px solid var(--green-grey);
	background-color: var(--white);
	justify-content: flex-end;
	padding-top: 10px;
	padding-bottom: 6px;
	padding-right: 5%;
	display: none;
}

.links-container {
	color: var(--black);
	cursor: pointer;
	padding-left: 20px;
	padding-right: 20px;
	font-size: 14px;
	font-weight: 500;
	text-decoration: none;
}

.links-container:hover {
	font-size: 14px;
	text-decoration: underline;
}

.links-container.on-footer {
	padding-left: 0;
	font-size: 16px;
	font-weight: 500;
	margin-bottom: 8px;
	display: inline-block;
}

.links-container.on-footer:hover {
	text-decoration: underline;
}

.links-container.selected {
	color: #757575;
	background-color: #fff;
	border-radius: 20px;
}

.grip-logo-link {
	float: right;
	opacity: 0.95;
	transition: background-color 0.2s, color 0.2s, border-color 0.2s, opacity 0.2s;
}

.grip-logo-link:hover {
	opacity: 1;
}

.footer-signoff-links {
	margin-right: 1rem;
	line-height: 1.25rem;
	display: inline-block;
}

.footer-signoff-links:hover {
	border-bottom-color: #0000;
}

.sign-off-div-wrap {
	width: 100%;
	max-width: 1100px;
}

.footer-signoff-link {
	color: #fff;
	letter-spacing: 1px;
	font-size: 1rem;
	text-decoration: none;
	transition: color 0.3s;
	border-bottom: 1px solid transparent;
}

.footer-signoff-link:hover {
	color: var(--light-green-iaec);
	text-decoration: underline;
}

.signoff-div {
	z-index: 1;
	background-color: var(--dark-green-iaec);
	justify-content: center;
	width: 100%;
	padding: 1.5rem 5%;
	display: flex;
	position: relative;
}

.column-3 {
	align-items: center;
	padding-left: 0;
	padding-right: 0;
	display: flex;
	justify-content: center;
}

.column-2 {
	justify-content: flex-end;
	padding-right: 0;
	display: flex;
}

.footer-copyright {
	color: #fff;
	margin-bottom: 0;
	margin-right: 1.5rem;
	font-size: 1rem;
	display: inline-block;
}

.footer-copyright h1 {
	display: inline;
	margin: 0px;
	padding: 0px;
	font-size: inherit;
	line-height: inherit;
	color: inherit;
	font-weight: inherit;
	font-family: inherit;
}

.footer-signoff-link-wrap {
	display: inline-block;
}

.grip-logo-image {
	width: 9rem;
	min-width: 150px;
	transition: all 0.45s;
}

.grid-3 {
	grid-column-gap: 4rem;
	grid-row-gap: 4rem;
	grid-template-rows: auto;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
	place-content: center flex-start;
	place-items: flex-start center;
	margin-left: auto;
	margin-right: auto;
	display: flex;
}

.footer-links {
	color: var(--black);
	object-fit: cover;
	flex-direction: column;
	align-items: flex-start;
	display: flex;
}

.footer-links.margin {
	grid-column-gap: 10px;
	grid-row-gap: 10px;
	flex-flow: column;
	margin-right: 32px;
	font-size: 21px;
}

.is-red {
	background-color: var(--maroon\<deleted\|variable-5e915bfa\>);
}

.heading-4 {
	margin-top: 0;
	line-height: 36px;
	font-size: 20px;
	margin-bottom: 10px;
}

.paragraph-2 {
	font-size: 18px;
	font-weight: 500;
	line-height: 25px;
	text-align: center;
}

.mega-div-wrap {
	flex-flow: column wrap;
	justify-content: space-between;
	align-self: flex-start;
	align-items: flex-start;
	width: 100%;
	max-height: 500px;
	display: flex;
}

.dropdown-link-2 {
	color: #676767;
	text-transform: none;
	border-bottom: 1px #0003;
	width: 100%;
	padding: 0.1rem 0.5rem 0.1rem 0.75rem;
	font-size: 0.8rem;
	font-weight: 400;
	line-height: 1.3rem;
	text-decoration: none;
	transition: all 0.2s;
	display: block;
}

.dropdown-link-2:hover {
	color: #000;
	background-color: #ffffffb3;
}

.dropdown-link-2.w--current {
	color: #1f304f;
	background-color: #ffffffb3;
	box-shadow: inset 4px 0 #e77c25;
}

.dropdown-link-2.subcategory {
	text-transform: none;
	font-weight: 700;
}

.container-4 {
	max-width: 1300px;
	margin-left: auto;
	margin-right: auto;
	display: block;
}

.container-4.nav-container {
	float: none;
	float: none;
	float: none;
	justify-content: center;
	align-items: center;
	width: 100%;
	max-width: 1600px;
	height: 3.25rem;
	margin-left: auto;
	margin-right: auto;
	padding-left: 0;
	padding-right: 0;
	display: flex;
}

.subcateogry-div {
	float: left;
	width: 12%;
	padding-top: 1.25rem;
	padding-bottom: 1.25rem;
	display: inline-block;
}

.dropdown-list-2 {
	z-index: 3;
	opacity: 1;
	text-align: left;
	min-width: 100%;
	max-width: none;
	height: 0;
	margin-top: 51px;
	padding-left: 0;
	padding-right: 0;
	display: none;
	position: absolute;
	inset: 0% 0% auto;
	overflow: visible;
}

.div-block-33 {
	display: none;
}

.dropdown-inner-wrap-2 {
	z-index: 1;
	box-shadow: none;
	opacity: 1;
	background-color: #fff;
	flex-flow: wrap;
	justify-content: space-between;
	align-items: flex-start;
	width: 93%;
	max-width: 1600px;
	margin-left: auto;
	margin-right: auto;
	padding: 0.75rem 1rem 1.5rem;
	display: flex;
	position: relative;
}

.icon-column-3 {
	flex: 0 auto;
	justify-content: flex-end;
	align-self: center;
	display: flex;
}

.dropdown-wrapper-3 {
	text-align: center;
	border-radius: 5px;
	justify-content: center;
	align-items: center;
	display: flex;
	overflow: hidden;
}

.dropdown-wrapper-3:hover {
	color: #363636;
	background-color: #0000;
}

.nav-menu-2 {
	z-index: 2;
	float: right;
	color: #f7f7f7;
	background-color: #fff;
	border: 1px #000;
	justify-content: space-between;
	align-items: stretch;
	width: 100%;
	height: 3.25rem;
	margin-left: auto;
	margin-right: auto;
	display: block;
}

.nav-menu-2._1.nav-menu-scrollable {
	border-top-style: solid;
	border-top-color: var(--green-grey);
	background-color: var(--green-grey);
	justify-content: center;
	margin-left: 0;
	margin-right: 0;
	padding-left: 5%;
	padding-right: 5%;
}

.navbar-2.inside .nav-menu-2._1.nav-menu-scrollable {
	background-color: var(--green-grey) !important;
}

.navbar-2.inside .text-block-11 {
	color: #222222 !important;
}

.navbar-2.inside .text-block-11:hover {
	color: #fff !important;
}

.main-nav-link-2 {
	z-index: 1;
	text-align: center;
	letter-spacing: 0.01rem;
	text-transform: uppercase;
	border-radius: 10px;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	margin-left: 0;
	padding: 0.6rem 1.5rem;
	font-size: 0.8rem;
	font-weight: 500;
	line-height: 1rem;
	text-decoration: none;
	transition: all 0.3s;
	display: flex;
}

.main-nav-link-2:hover {
	background-color: var(--dark-green-iaec);
	color: #fff;
}

.main-nav-link-2:hover .text-block-11 {
	color: #fff !important;
}

.main-nav-link-2.w--current {
	border-color: #e77c25;
}

.main-nav-link-2.w--open {
	border-radius: 8px;
}

.top-half {
	z-index: 35;
	grid-column-gap: 16px;
	grid-row-gap: 16px;
	grid-template-rows: auto;
	grid-template-columns: 1fr 1fr;
	grid-auto-columns: 1fr;
	place-content: center space-between;
	align-self: stretch;
	place-items: center stretch;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	padding: 0.5rem 3%;
	display: flex;
}

.icon-column-wrap {
	justify-content: flex-end;
	display: flex;
	overflow: visible;
}

.navbar-2 {
	z-index: 1200;
	border-bottom: 2px solid var(--light-green-iaec);
	box-shadow: none;
	-webkit-backdrop-filter: blur(13px);
	backdrop-filter: blur(13px);
	background-color: #0084571c;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	margin-left: 0;
	margin-right: 0;
	display: flex;
	position: fixed;
	inset: 0% 0% auto;
}

.navbar-2.inside {
	background-color: var(--dark-green-iaec);
	-webkit-backdrop-filter: none;
	backdrop-filter: none;
	position: sticky;
}

.ths05-dropdown-links {
	justify-content: space-between;
	align-items: flex-start;
	padding: 20px;
}

.ths05-dropdown-links-wrapper {
	grid-column-gap: 10px;
	grid-row-gap: 10px;
	flex-direction: column;
	width: 100%;
	display: flex;
}

.ths05-dropdown-link-text-wrapper {
	width: 240px;
}

.ths05-dropdown {
	border-radius: 5px;
	height: 100%;
	padding-top: 8px;
	padding-bottom: 8px;
	position: static;
}

.ths05-dropdown:focus {
	color: #fff;
	background-color: #7dbd40;
}

.ths05-dropdown-list-wrapper {
	background-color: #fff;
	border-radius: 8px;
	width: 300px;
	box-shadow: 7px 7px 30px #08165d1a;
	overflow: hidden;
	overflow-y: scroll;
	max-height: 75vh;
}

.ths05-dropdown-list {
	z-index: 2000;
	background-color: #0000;
	width: 800px;
}

.ths05-dropdown-list.w--open {
	z-index: 10000;
	background-color: #fff0;
	width: auto;
	min-width: auto;
	max-width: 500px;
	padding-top: 20px;
	position: absolute;
}

.ths05-dropdown-link {
	color: #000;
	text-transform: uppercase;
	border-radius: 8px;
	justify-content: flex-start;
	align-items: center;
	margin-right: 0;
	padding: 6px 15px;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 25px;
	text-decoration: none;
	display: flex;
}

.ths05-dropdown-link:hover {
	background-color: var(--green-grey);
}

.columns {
	align-items: center;
	display: flex;
}

.text-block-11 {
	align-items: center;
	margin-left: 16px;
	margin-right: 8px;
	font-size: 1rem;
	display: flex;
	color: #fff;
}

.body {
	font-family: Jost, sans-serif;
}

.d-66-button-wrapper {
	background-color: var(--gutterworks);
	border-radius: 5px;
	justify-content: flex-start;
	align-items: stretch;
	width: 170px;
	height: 40px;
	margin-left: auto;
	margin-right: auto;
	text-decoration: none;
	display: flex;
	position: relative;
	overflow: hidden;
}

.footer-social-icons-links-wrap {
	flex-flow: row;
	flex: none;
	justify-content: center;
	align-self: center;
	align-items: center;
	display: flex;
}

.footer-social-icons-links-wrap.vertical {
	flex-flow: row;
}

.footer-social-icon-link {
	background-color: var(--light-green-iaec);
	opacity: 1;
	border-radius: 50%;
	margin-top: 4px;
	margin-bottom: 8px;
	margin-right: 6px;
}

.footer-social-icon-link:hover {
	background-color: var(--dark-green-iaec);
	opacity: 1;
}

.footer-social-icon-link:active {
	text-transform: capitalize;
}

.footer-social-icon-image {
	width: 32px;
	height: 32px;
	padding: 4px;
	transition: all 0.2s;
}

.footer-social-icon-image.xlogo {
	padding: 8px;
}

.lvl3-dropdwon {
	width: 100%;
}

.rotated {
	transform: rotate(-90deg);
}

.ths05-dropdown-list-wrapper-copy {
	background-color: #fff;
	border-radius: 8px;
	width: 300px;
	box-shadow: 7px 7px 30px #08165d1a;
}

.ths05-dropdown-list-wrapper-copy.w--open {
	box-shadow: none;
	background-color: #fff0;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	padding-left: 20px;
	top: 0;
	left: 260px;
	overflow: hidden;
}

.ths05-dropdown-link-2 {
	color: #000;
	text-transform: capitalize;
	background-color: #fff;
	border-radius: 8px;
	justify-content: flex-start;
	align-items: center;
	margin-right: 0;
	padding: 6px 15px;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 25px;
	text-decoration: none;
	display: flex;
}

.ths05-dropdown-link-2:hover {
	background-color: var(--green-grey);
}

._3rd-lvl-link-wrap {
	background-color: #fff;
	padding: 16px;
}

.content-wrap-stats {
	grid-column-gap: 10px;
	grid-row-gap: 10px;
	text-align: left;
	background-color: #0000;
	border-radius: 20px;
	flex-direction: column;
	grid-template-rows: auto auto;
	grid-template-columns: 1fr;
	grid-auto-columns: 1fr;
	justify-content: center;
	place-items: center start;
	width: 100%;
	padding: 0;
	display: flex;
}

.counter-2 {
	color: #fff;
	width: 2ch;
	font-size: 40px;
	font-weight: 700;
	line-height: 1;
}

.footer {
	background-color: #0000;
	flex-direction: column;
	justify-content: center;
	align-items: stretch;
	padding-bottom: 0;
	display: flex;
}

.footer.is-red {
	color: var(--white);
}

.footer.is-red.footer-links {
	z-index: 1;
	border-top: 3px solid var(--light-green-iaec);
	background-color: var(--green-grey);
	color: var(--black);
	flex-flow: row;
	justify-content: flex-start;
	align-items: stretch;
	width: 100%;
	padding: 50px 3%;
	position: relative;
}

.sticky_container {
	color: #fff;
	flex-flow: column;
	justify-content: center;
	align-items: flex-start;
	width: 100%;
	max-width: 1600px;
	margin-left: auto;
	margin-right: auto;
	padding-top: 40vh;
	display: flex;
	position: relative;
	left: 0;
}

.green-section-sticky {
	z-index: 1;
	background-image: linear-gradient(90deg, #0420178a, #fff0 45%), linear-gradient(#00130c80, #00130c80);
	justify-content: space-between;
	align-items: flex-start;
	width: 100%;
	display: flex;
	position: relative;
}

.green-section-sticky.image {
	background-image: linear-gradient(90deg, #0420178a, #fff0 45%), url('/20150924150955/assets/images/iStock-807017234-min.png');
	background-position: 0 0, 50%;
	background-repeat: repeat, no-repeat;
	background-size: auto, cover;
	background-attachment: scroll, fixed;
}

.heading-h3 {
	font-size: 1.8em;
	font-weight: 300;
	line-height: 1.4em;
}

.heading-h3.mb-8 {
	max-width: 500px;
}

.sticky_txt-container {
	grid-column-gap: 50vh;
	grid-row-gap: 50vh;
	flex-flow: column;
	justify-content: flex-start;
	align-items: flex-start;
	width: 50%;
	margin-bottom: 40vh;
	margin-left: 32px;
	display: flex;
	z-index: 5;
}

.heading-h2 {
	font-size: 2.4em;
	font-weight: 400;
	line-height: 1.35em;
}

.heading-h2.heading-h2--large.mb-8 {
	width: 100%;
	font-size: 65px;
	font-weight: 700;
}

.search-content {
	z-index: 0;
	opacity: 0;
	transform-origin: 50% 0;
	background-color: #fff;
	border-radius: 5px;
	justify-content: space-around;
	align-self: center;
	align-items: center;
	width: 45%;
	height: 65px;
	margin: 235px 0 0 auto;
	padding: 0 5rem;
	display: flex;
	position: absolute;
	inset: 0 5% 0% auto;
	box-shadow: -5px 4px 15px -2px #0000001a;
}

.search-3 {
	flex-direction: row;
	align-items: center;
	width: 100%;
	margin-bottom: 0;
	display: flex;
}

.search-input-3 {
	opacity: 1;
	color: var(--black);
	letter-spacing: 0.5px;
	background-color: #0000;
	border: 1px #000;
	margin-bottom: 0;
	padding-left: 0;
	font-size: 0.9rem;
}

.search-button-2 {
	color: #444545;
	letter-spacing: 1px;
	text-transform: uppercase;
	background-color: #0000;
	border: 2px #b08d57;
	border-radius: 100px;
	padding: 0;
	font-size: 0.9rem;
	line-height: 1rem;
	transition: color 0.2s;
}

.search-button-2:hover {
	color: var(--dark-green-iaec);
	background-color: #0000;
	border-style: none;
}

.search-icon {
	margin-top: 5px;
	margin-left: 40px;
	margin-right: 30px;
	display: inline-block;
}

.search-icon.mobile {
	background-color: var(--dark-green-iaec);
	border-radius: 100px;
	margin-top: 0;
	margin-left: 16px;
	margin-right: 0;
	font-size: 16px;
	display: block;
	position: relative;
}

.search-button-icon {
	z-index: 2;
	border: 1px none var(--green-grey);
	background-color: var(--darker-green);
	background-image: url('/20150924150955/assets/images/search-icon-white.svg');
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 32px;
	border-radius: 50px;
	width: 46px;
	height: 46px;
	margin-top: 0;
	font-size: 18px;
	position: absolute;
	top: 0;
	font-size: 0px;
	right: 0;
}

.search-button-icon:hover {
	background-image: url('/20150924150955/assets/images/search-icon-white.svg');
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 28px;
}

.chat-bubble-wrapper {
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	width: 100%;
	margin-top: 10px;
	display: flex;
}

.chat-wrapper {
	z-index: 99;
	border: 1px solid var(--light-green-iaec);
	-webkit-backdrop-filter: blur(10px);
	backdrop-filter: blur(10px);
	background-color: #008356a1;
	border-radius: 8px;
	flex-direction: column;
	justify-content: space-between;
	width: 300px;
	margin-top: 0px;
	margin-bottom: 10px;
	margin-right: 9px;
	display: none;
	inset: auto 3% -8% auto;
	overflow: hidden;
	box-shadow: 0 2px 5px #0003;
}

.chat-wrapper .button {
	margin-left: auto;
	margin-right: auto;
}

.chat-bubble {
	background-color: var(--dark-green-iaec);
	color: #fff;
	border-radius: 9px;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	width: auto;
	max-width: 80%;
	margin-top: 0;
	padding: 12px 20px;
	display: flex;
	overflow: hidden;
}

.smtxt {
	color: var(--white);
	padding-top: 10px;
	padding-bottom: 10px;
	padding-left: 0;
	font-size: 16px;
}

.bubble-section {
	z-index: 1100;
	flex-flow: column;
	justify-content: flex-end;
	align-items: flex-end;
	display: flex;
	position: fixed;
	inset: 0% 3% 3% auto;
}

.bubble-section .navlinkwrapper .nav-link {
	margin-top: 5px;
}

.chat-top {
	justify-content: space-between;
	align-items: center;
	width: 100%;
	height: 45px;
	padding-top: 16px;
	padding-left: 20px;
	padding-right: 20px;
	display: flex;
}

.input-wrapper {
	grid-column-gap: 16px;
	grid-row-gap: 16px;
	flex-direction: column;
	justify-content: center;
	align-items: flex-end;
	padding-bottom: 32px;
	padding-left: 16px;
	padding-right: 16px;
	display: flex;
	overflow: hidden;
}

.chat-button {
	z-index: 100;
	border: 1px solid var(--light-green-iaec);
	background-color: var(--dark-green-iaec);
	color: #fff;
	cursor: pointer;
	border-radius: 100rem;
	justify-content: center;
	align-items: center;
	width: 160px;
	height: 45px;
	padding-left: 1.15rem;
	padding-right: 1.15rem;
	transition: all 0.2s;
	display: flex;
	position: static;
	inset: auto 2% 2% auto;
}

.chat-button:hover {
	background-color: var(--darker-green);
}

.lightning-ixon {
	grid-column-gap: 3px;
	grid-row-gap: 3px;
	color: #fff;
	justify-content: space-between;
	align-items: center;
	height: 50%;
	display: flex;
}

.small-lightning {
	color: var(--light-green-iaec);
	justify-content: center;
	align-items: center;
	width: 26px;
	height: 26px;
	display: flex;
}

.dropdown-4 {
	margin-left: 0;
	margin-right: 0;
}

.dropdown-list-4 {
	border-bottom-left-radius: 5px;
}

.dropdown-list-4.w--open {
	z-index: auto;
	background-color: #fff;
	border-radius: 8px;
	height: 175px;
	overflow-y: scroll;
}

.dropdown-list-4._2nd.w--open {
	margin-left: -115px;
}

.number {
	font-size: 40px;
	font-weight: 700;
	line-height: 40px;
}

.paragraph-8 {
	color: var(--white);
	width: 60%;
	margin-bottom: 0;
}

.widget-item-title-wrapper {
	width: 100%;
	text-align: center;
}

.widget-item-title {
	font-size: 22px;
	line-height: 1em;
	color: var(--white);
}

.line {
	background-color: var(--dark-green-iaec);
	width: 100%;
	height: 1px;
	margin: 16px auto;
}

.ths02-close-icon {
	color: #fff;
	width: 22px;
	margin-left: 8px;
}

.button-wrapper {
	flex-flow: column;
	justify-content: center;
	align-self: flex-end;
	align-items: flex-end;
	margin-right: 2%;
	padding-right: 15px;
	display: flex;
	position: static;
	bottom: 0;
	right: 2%;
}

.button-link-2 {
	border: 2px solid var(--light-green-iaec);
	background-color: var(--green-grey);
	border-radius: 50%;
	justify-content: center;
	align-items: center;
	width: 45px;
	height: 45px;
	margin-bottom: 7.5px;
	margin-left: auto;
	margin-right: auto;
	padding-left: 10px;
	padding-right: 10px;
	display: flex;
}

.button-2 {
	z-index: 10;
	background-color: var(--light-green-iaec);
	cursor: pointer;
	border-radius: 50%;
	justify-content: center;
	align-items: center;
	width: 50px;
	height: 50px;
	display: flex;
	position: relative;
}

.button-line-1 {
	background-color: #fff;
	width: 20px;
	height: 3px;
}

.button-line-2 {
	background-color: #fff;
	width: 3px;
	height: 25px;
	position: absolute;
}

.button-link-3,
.button-link-1 {
	border: 2px solid var(--light-green-iaec);
	background-color: var(--green-grey);
	border-radius: 50%;
	justify-content: center;
	align-items: center;
	width: 45px;
	height: 45px;
	margin-bottom: 7.5px;
	margin-left: auto;
	margin-right: auto;
	padding-left: 10px;
	padding-right: 10px;
	display: flex;
}

.dummy-img {
	background-image: url('/20150924150955/assets/images/link-icon-white.svg');
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: contain;
	width: 35px;
	height: 30px;
}

.bold-text-4 {
	font-size: 50px;
	line-height: 1.7em;
}

.project-content {
	flex-direction: column;
	justify-content: center;
	width: 70%;
	margin-left: auto;
	margin-right: auto;
	display: flex;
	position: relative;
}

.image-overlay {
	background-color: var(--white);
	justify-content: space-between;
	align-items: center;
	display: flex;
	position: absolute;
	inset: 0%;
}

.project-image {
	background-image: url('/20150924150955/assets/images/3img_project_1.jpg');
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: cover;
	width: 70%;
	height: 300px;
	margin-bottom: 50px;
	margin-left: auto;
	margin-right: auto;
	position: relative;
	overflow: hidden;
}

.project-image.six {
	background-color: var(--green-grey);
	background-image: none;
	border: 1px solid #e8edea;
	border-radius: 20px;
	flex-flow: row;
	justify-content: center;
	align-items: center;
	width: 100%;
	max-width: 1350px;
	height: 400px;
	margin-bottom: 0;
	margin-left: 0;
	margin-right: 0;
	padding: 32px;
	display: flex;
}

.counter-2 {
	color: var(--dark-green-iaec);
	text-align: center;
	letter-spacing: 0;
	width: auto;
	margin-right: 5px;
	font-size: 56px;
	line-height: 1;
}

.text-center {
	text-align: center;
}

.square-icon {
	border-radius: 16px;
}

.grid-4-columns {
	grid-column-gap: 28px;
	grid-row-gap: 28px;
	grid-template-rows: auto;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	grid-auto-columns: 1fr;
	display: grid;
	position: absolute;
}

.flex-vertical {
	flex-direction: column;
	display: flex;
}

.flex-vertical.center {
	align-items: center;
}

.mg-bottom-24px {
	background-color: #fff;
	border-radius: 15px;
	justify-content: center;
	align-items: center;
	width: 72px;
	height: 72px;
	margin-bottom: 24px;
	display: none;
}

._6-categories {
	z-index: 1;
	background-color: var(--white);
	flex-direction: column;
	justify-content: center;
	padding: 100px 3%;
	display: flex;
	position: relative;
}

.card-arrow {
	background-color: #fff;
	border: 1px #c0c0d333;
	border-style: none none solid solid;
	border-bottom-left-radius: 3px;
	width: 24px;
	height: 24px;
	position: absolute;
	inset: auto -51px auto auto;
	transform: rotate(45deg);
}

.subtitle {
	letter-spacing: 3px;
	text-transform: uppercase;
	font-size: 11px;
	font-weight: 400;
	line-height: 1.2em;
}

.background-load-bottom {
	z-index: 25;
	background-color: #fff;
	height: 50%;
	display: none;
	position: absolute;
	inset: auto 0% 0%;
}

.subtitle-intro-wrapper {
	z-index: 10;
	grid-column-gap: 16px;
	grid-row-gap: 16px;
	grid-template-rows: auto;
	grid-template-columns: auto 1fr;
	grid-auto-columns: auto;
	display: grid;
	position: relative;
}

.steps-card-wrapper {
	grid-column-gap: 16px;
	grid-row-gap: 60px;
	grid-template-rows: auto auto;
	grid-template-columns: 1fr;
	grid-auto-columns: 1fr;
	min-height: 900px;
	display: grid;
}

.button-3 {
	color: #fff;
	text-align: center;
	letter-spacing: 3px;
	text-transform: uppercase;
	background-color: #3572ff;
	border-style: none;
	border-radius: 5px;
	justify-content: center;
	align-items: center;
	padding: 16px 32px;
	font-size: 10px;
	text-decoration: none;
	transition: all 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
	display: flex;
}

.button-3:hover {
	background-color: #081c3a;
}

.steps-grid {
	grid-column-gap: 80px;
	grid-row-gap: 36px;
	grid-template-rows: auto;
	grid-template-columns: 0.8fr 1fr;
	grid-auto-columns: 1fr;
	width: 100%;
	max-width: 1450px;
	margin-left: auto;
	margin-right: auto;
	display: grid;
}

.steps-content {
	grid-column-gap: 16px;
	grid-row-gap: 32px;
	grid-template-rows: auto 1fr;
	grid-template-columns: 1fr;
	grid-auto-columns: 1fr;
	padding-top: 0;
	padding-bottom: 0;
	display: grid;
}

.grid-wrapper {
	z-index: 10;
	grid-column-gap: 0px;
	grid-row-gap: 0px;
	flex-flow: row;
	grid-template-rows: 1fr;
	grid-template-columns: minmax(80px, 1fr) minmax(auto, 1400px) minmax(80px, 1fr);
	grid-auto-columns: 1fr;
	justify-content: center;
	align-self: center;
	align-items: stretch;
	width: 100%;
	display: flex;
	position: relative;
}

.steps-tracker {
	background-color: #c0c0d333;
	border-radius: 100px;
	flex-direction: column;
	width: 2px;
	margin-top: 80px;
	margin-bottom: 80px;
	display: flex;
}

.steps-sticky-card {
	z-index: 30;
	grid-column-gap: 16px;
	grid-row-gap: 36px;
	border: 1px solid var(--green-grey);
	box-shadow: none;
	border-radius: 12px;
	flex-direction: column;
	grid-template-rows: auto auto;
	grid-template-columns: 1fr;
	grid-auto-columns: 1fr;
	width: 100%;
	min-width: 300px;
	max-width: 500px;
	padding: 18px 18px 32px;
	display: grid;
	position: sticky;
	top: 185px;
	overflow: visible;
}

.steps-sticky-card.lightgreen {
	background-color: var(--green-grey);
	grid-template-rows: auto;
	width: 25%;
}

.hero-background-image {
	background-position: 50% 0;
	background-repeat: no-repeat;
	background-size: cover;
	width: 100%;
	height: 100%;
	position: absolute;
	inset: 0%;
}

.hero-background-image.steps-background {
	background-position: 50% 0;
	position: static;
}

.how-to-image {
	border-radius: 5px;
	flex-direction: column;
	align-items: flex-start;
	min-height: 250px;
	padding: 18px;
	display: flex;
	position: relative;
	overflow: hidden;
}

.steps-cards {
	grid-column-gap: 60px;
	grid-row-gap: 24px;
	grid-template-rows: auto;
	grid-template-columns: auto 1fr;
	grid-auto-columns: 1fr;
	width: 100%;
	display: grid;
}

.background-load-top {
	z-index: 25;
	background-color: #fff;
	height: 50%;
	display: none;
	position: absolute;
	inset: 0% 0% auto;
}

.steps-card {
	grid-column-gap: 36px;
	grid-row-gap: 36px;
	border: 1px solid #c0c0d333;
	border-radius: 5px;
	grid-template-rows: auto;
	grid-template-columns: 1fr;
	grid-auto-columns: 1fr;
	align-content: stretch;
	align-items: center;
	min-height: 275px;
	padding: 24px;
	display: grid;
	position: relative;
}

.steps-circle {
	border: 0 #c0c0d333;
	border-radius: 100px;
	justify-content: center;
	align-items: center;
	width: 48px;
	height: 48px;
	display: flex;
	position: absolute;
	inset: 36px auto auto -86px;
}

.steps-circle.center {
	top: auto;
}

.steps-circle.end {
	top: auto;
	bottom: 36px;
}

.steps-dot {
	z-index: 10;
	background-color: #c0c0d333;
	border-radius: 100px;
	width: 12px;
	height: 12px;
	position: relative;
}

.horizontal-line {
	background-color: #c0c0d333;
	flex: 1;
	height: 1px;
}

.steps-icon {
	z-index: 10;
	color: var(--black);
	background-color: #e8edea5e;
	border: 1px solid #c0c0d333;
	border-radius: 100%;
	justify-content: center;
	align-items: center;
	width: 60px;
	height: 60px;
	font-weight: 700;
	display: flex;
	position: relative;
}

.steps-intro {
	grid-column-gap: 26px;
	grid-row-gap: 26px;
	flex-flow: column;
	grid-template-rows: auto auto;
	grid-template-columns: 1fr;
	grid-auto-columns: 1fr;
	justify-content: center;
	align-items: flex-start;
	padding-left: 12px;
	padding-right: 12px;
	display: flex;
}

.steps-circle-outline {
	background-color: #fff;
	border: 2px solid #c0c0d333;
	border-radius: 100%;
	position: absolute;
	inset: 0%;
}

.steps-circle-outline.two {
	background-color: #fff;
}

.how-it-works-fill {
	background-color: #081c3a;
	border-radius: 100px;
	width: 100%;
	height: 0;
}

.body-display {
	color: var(--black);
	font-size: 17px;
	font-weight: 400;
	line-height: 1.6em;
}

.body-display.large {
	color: var(--black);
	font-size: 18px;
	line-height: 1.4em;
}

.steps-description {
	grid-column-gap: 10px;
	grid-row-gap: 10px;
	grid-template-rows: auto auto;
	grid-template-columns: 1fr;
	grid-auto-columns: 1fr;
	place-items: center start;
	padding-top: 17px;
	padding-right: 17px;
	display: grid;
}

.bold-text-5 {
	color: var(--black);
	font-weight: 500;
	text-decoration: none;
}

.heading-8 {
	margin-bottom: 0;
}

.btn-2 {
	z-index: 1;
	color: #181f25;
	letter-spacing: 0.03px;
	text-transform: uppercase;
	justify-content: center;
	align-items: center;
	min-width: 150px;
	min-height: 45px;
	margin-top: 0;
	padding-top: 0;
	padding-bottom: 0;
	font-weight: 500;
	text-decoration: none;
	transition-duration: 0.3s;
	display: flex;
	position: relative;
	overflow: hidden;
}

.btn-2.mod--orange {
	background-color: var(--dark-green-iaec);
	color: #fff;
	border: 0 solid #181f25;
	border-radius: 5px;
	min-height: 45px;
	padding-left: 16px;
	padding-right: 16px;
}

.overflow-hidden {
	overflow: hidden;
}

.btn__txts {
	position: relative;
	bottom: 0;
}

.btn__txt {
	z-index: 0;
	text-transform: capitalize;
	justify-content: center;
	align-items: center;
	width: 100%;
	display: flex;
	position: relative;
}

.btn__txt.mod--2 {
	color: var(--black);
	position: absolute;
	bottom: -40px;
}

.btn__bg-wrap {
	z-index: -1;
	justify-content: center;
	align-items: center;
	height: 100%;
	display: flex;
	position: absolute;
	inset: auto 0% 0;
}

.btn__bg {
	background-color: var(--light-green-iaec);
	border-radius: 100px;
	width: 0;
	height: 0;
}

.link-4 {
	color: var(--black);
	text-decoration: underline;
}

.heading-9 {
	margin-top: 10px;
}

.navbar-link {
	grid-column-gap: 16px;
	grid-row-gap: 16px;
	color: #c33f40;
	letter-spacing: 0.02rem;
	text-transform: uppercase;
	border-top: 1px solid #e5ceb1;
	border-right: 1px #e9dbcf;
	flex-direction: row;
	grid-template-rows: auto;
	grid-template-columns: 1fr auto;
	grid-auto-columns: 1fr;
	place-content: center space-between;
	align-items: center;
	padding: 1.5rem;
	font-size: 0.875rem;
	font-weight: 500;
	text-decoration: none;
	display: grid;
	position: relative;
	overflow: hidden;
}

.clip {
	position: relative;
	overflow: hidden;
}

.home-faq-block {
	grid-column-gap: 5rem;
	grid-row-gap: 5rem;
	flex-direction: column;
	justify-content: space-between;
	width: 100%;
	min-height: 35rem;
	display: flex;
}

.text-size-large {
	font-size: 1.4rem;
	line-height: 1.45;
}

.line-fill {
	background-color: #2e313a;
	width: 100%;
	height: 1px;
}

.line-fill.light-line-fill {
	background-color: #e7eeee;
}

.home-faq-list {
	grid-column-gap: 0px;
	grid-row-gap: 0px;
	border: 1px solid #e7eeee;
	flex-direction: column;
	grid-template-rows: auto;
	grid-template-columns: auto;
	grid-auto-columns: auto;
	grid-auto-flow: column;
	justify-content: space-between;
	margin-top: 0;
	margin-bottom: 0;
	padding: 0.5rem 2rem;
	display: flex;
	overflow: hidden;
}

.home-faq-list.radius-regular {
	border-radius: 20px;
	padding-top: 2rem;
	padding-bottom: 2rem;
}

.text-size-small {
	font-size: 0.975rem;
	line-height: 1.35;
}

.home-faq-item {
	grid-column-gap: 0px;
	grid-row-gap: 0px;
	cursor: pointer;
	background-color: #ddd0;
	border-top: 1px solid #e7eeee;
	border-bottom: 1px #e7eeee;
	flex-direction: column;
	flex: 0 auto;
	grid-template-rows: auto;
	grid-template-columns: auto 1fr;
	grid-auto-columns: 1fr;
	justify-content: flex-end;
	place-items: stretch stretch;
	width: 100%;
	padding: 0;
	transition: all 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);
	display: flex;
}

.home-faq-item.w--current {
	background-color: #ddd0;
	overflow: hidden;
}

.home-faq-item.no-border {
	border-top-style: none;
	position: relative;
}

.home-faq-top {
	grid-column-gap: 1rem;
	grid-row-gap: 1rem;
	border-bottom: 1px #e7eeee;
	border-left: 1px #e7eeee;
	border-right: 1px #e7eeee;
	flex-direction: row;
	grid-template-rows: auto;
	grid-template-columns: 5rem 1fr auto;
	grid-auto-columns: 1fr;
	justify-content: start;
	place-items: center start;
	width: 100%;
	padding-top: 2rem;
	padding-bottom: 2rem;
	display: grid;
}

.home-faq-content {
	background-color: #0b0c0f;
	border: 1px #e7eeee;
	flex-direction: row;
	justify-content: space-between;
	height: 100%;
	margin-bottom: 2.5rem;
	padding: 4rem 2rem 4rem 6rem;
	display: flex;
	overflow: hidden;
}

.home-faq-content.radius-regular {
	background-color: var(--dark-green-iaec);
	color: #fff;
	border-radius: 15px;
	margin-bottom: 0;
	padding-top: 2rem;
	padding-bottom: 2rem;
}

.icon-1x1-small {
	flex: none;
	justify-content: center;
	align-items: center;
	width: 1.25rem;
	height: 1.25rem;
	display: flex;
	overflow: hidden;
}

.hover-text.bottom-hover-text {
	position: absolute;
	inset: auto 0% -100%;
}

.home-faq-icon {
	border-radius: 100rem;
	justify-content: center;
	align-items: center;
	width: 2.5rem;
	height: 2.5rem;
	display: flex;
}

.text-size-regular {
	font-size: 1.2rem;
	line-height: 1.4;
}

.line-2 {
	position: absolute;
	inset: auto 0% 0%;
	overflow: hidden;
}

.home-faq-number {
	grid-column-gap: 1.5rem;
	grid-row-gap: 1.5rem;
	align-items: center;
	display: flex;
}

.hame-faq-bottom {
	flex-direction: column;
	flex: 0 auto;
	align-items: stretch;
	width: 100%;
	padding-top: 0;
	padding-bottom: 0;
	display: flex;
	position: relative;
	overflow: hidden;
}

.home-service_grid-2 {
	grid-column-gap: 3rem;
	grid-row-gap: 0rem;
	border: 1px solid var(--green-grey);
	border-radius: 20px;
	flex-flow: row;
	grid-template-rows: auto;
	grid-template-columns: 1fr 1fr;
	grid-auto-columns: 1fr;
	place-content: stretch center;
	align-items: center;
	max-width: 1250px;
	margin-left: auto;
	margin-right: auto;
	padding: 2rem;
	display: flex;
}

.sectionwrap {
	padding-top: 0;
	padding-left: 0;
	padding-right: 0;
}

.sectionwrap.padding-large {
	z-index: 1;
	background-color: var(--white);
	padding: 4rem 3%;
	position: relative;
}

.sectionwrap.padding-large.more {
	z-index: 1;
	background-color: var(--white);
	padding-top: 6rem;
	position: relative;
}

.product_list {
	width: 100%;
}

.product_link {
	border-bottom: 1px solid var(--dark-green-iaec);
	justify-content: center;
	align-items: center;
	width: 100%;
	padding-top: 1.875rem;
	padding-bottom: 1.875rem;
	text-decoration: none;
	display: flex;
	position: relative;
}

.product_img {
	z-index: 100;
	opacity: 0;
	pointer-events: none;
	width: 25%;
	position: absolute;
}

.product_content {
	color: var(--black);
	justify-content: space-between;
	align-items: center;
	width: 100%;
	padding-right: 3vw;
	text-decoration: none;
	display: flex;
	position: relative;
}

.product_title {
	font-size: 2rem;
	line-height: 1;
}

.inside-wrap-vertical {
	grid-column-gap: 0rem;
	grid-row-gap: 0rem;
	flex-flow: column;
	grid-template-rows: auto;
	grid-template-columns: 1fr 1fr;
	grid-auto-columns: 1fr;
	place-content: stretch center;
	align-items: center;
	max-width: 1250px;
	margin-left: auto;
	margin-right: auto;
	display: flex;
}

.row-title-block {
	flex-direction: column;
	justify-content: center;
	align-items: center;
	display: flex;
}

.row-title-text {
	letter-spacing: -0.35vw;
	text-transform: uppercase;
	margin-bottom: -3.5vw;
	font-size: 13.5vw;
	font-weight: 400;
	line-height: 0.89;
}

.row-title-text.text-align-center {
	color: var(--green-grey);
	margin-bottom: -1.5vw;
	margin-left: auto;
	margin-right: auto;
	font-size: 6.4vw;
	line-height: 6.5vw;
	letter-spacing: -0.2vw;
	font-weight: 700;
}

.letter-1 {
	z-index: 1;
	color: var(--dark-green-iaec);
	display: inline-block;
	position: relative;
}

.row-title-clip {
	display: flex;
	overflow: hidden;
}

.letter-9 {
	display: inline-block;
}

.letter-animation {
	justify-content: center;
	align-items: center;
	display: flex;
}

.row-title {
	width: 100%;
}

.button {
	border: 1px solid var(--green-grey);
	background-color: var(--white);
	color: var(--black);
	text-align: center;
	text-transform: capitalize;
	border-radius: 100rem;
	padding: 0.75rem 1.05rem 0.75rem 1.15rem;
	font-size: 1rem;
	font-weight: 500;
	text-decoration: none;
	transition: background 0.2s;
}

.button:hover {
	background-color: var(--light-green-iaec);
	color: var(--black);
}

.button.main {
	background-color: var(--dark-green-iaec);
	color: var(--white);
}

.button.main:hover {
	background-color: var(--darker-green);
}

.button.secondary {
	background-color: var(--green-grey);
}

.button.secondary:hover {
	background-color: var(--dark-green-iaec);
	color: var(--white);
}

.text-size-xsmall {
	z-index: 5;
	text-transform: capitalize;
	font-weight: 500;
	position: relative;
}

.nav-link {
	flex-flow: row;
	justify-content: flex-end;
	align-items: center;
	width: 100%;
	margin-top: 5px;
	margin-bottom: 0;
	display: flex;
}

.navlinkwrapper {
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	max-height: 50vh;
	margin-bottom: 60px;
	padding-top: 0;
	padding-bottom: 5px;
	padding-right: 10px;
	display: flex;
	position: absolute;
	overflow: auto;
}

.text-size-xsmall-2 {
	text-transform: capitalize;
	white-space: nowrap;
	font-size: 0.9rem;
	overflow: visible;
}

.button-line-3 {
	background-color: #fff;
	width: 3px;
	height: 20px;
	position: absolute;
}

.button-4 {
	background-color: var(--green-grey);
	color: #272727;
	text-align: center;
	text-transform: uppercase;
	border: 1px solid #e8edea;
	border-radius: 100rem;
	padding: 0.45rem 1.05rem 0.45rem 1.15rem;
	font-size: 0.875rem;
	font-weight: 500;
	text-decoration: none;
	transition: all 0.2s;
	box-shadow: 0 2px 9px -5px #0003;
	text-transform: capitalize;
	white-space: nowrap;
	font-size: 0.9rem;
}

.button-4:hover {
	background-color: var(--light-green-iaec);
	color: var(--black);
}

.button-4.outline {
	border-color: var(--dark-green-iaec);
}

.button-5 {
	z-index: 10;
	cursor: pointer;
	border-radius: 50%;
	justify-content: center;
	align-items: center;
	max-width: 40px;
	height: 50%;
	max-height: 40px;
	margin-left: 10px;
	display: flex;
	position: relative;
}

.navwrapper {
	justify-content: flex-end;
	align-items: flex-end;
	margin-bottom: 10px;
	display: flex;
}

.logoblock {
	display: flex;
}

.logo-copy {
	align-self: center;
	width: auto;
	max-width: none;
	height: 71px;
	margin-top: 10px;
	margin-bottom: 10px;
	display: none;
	top: 0;
	left: 0;
	right: 0;
}

.div-block-35 {
	z-index: 1;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	display: flex;
	background-position: 50%;
	background-attachment: fixed;
	position: relative;
	background-repeat: no-repeat;
	background-size: cover;
	background-color: #00130c80;
	background-image: linear-gradient(90deg, #0420178a, #fff0 31%);
}

.cb1.slide-image {
	width: 100%;
	height: 100%;
	background-position: 50%;
	background-size: cover;
	position: absolute;
	background-repeat: no-repeat;
	top: 0;
	position: fixed;
}

.background-video-container {
	z-index: 1;
	width: 100%;
	height: 100%;
	position: absolute;
	background-color: #00130c80;
	background-image: linear-gradient(90deg, #0420178a, #fff0 31%);
}

.w-background-video--control img {
	width: 44px;
	height: 44px;
}

.w-background-video--control {
	position: relative;
	bottom: auto;
	right: 0;
	width: 44px;
	top: calc(100% - 58px);
	height: 44px;
	left: calc(100% - 58px);
	z-index: 1000;
}

.logotype_white {
	background-image: url('/20150924150955/assets/images/logo-white-full---no-r.svg');
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: contain;
	width: 300px;
	height: 70px;
}

.faq-question {
	color: #000;
	border-top: 1px solid #00bed2;
	border-bottom: 1px #00bed2;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	padding: 20px;
	font-size: 16px;
	font-weight: 600;
	text-decoration: none;
	display: flex;
}

.faq-question:hover {
	color: #00778b;
}

.faq-question.special {
	border-top-style: none;
	padding-left: 0;
	font-size: 24px;
}

.div-block-36 {
	border: 1px solid var(--green-grey);
	border-radius: 20px;
	width: 100%;
	padding: 32px;
}

.dropdown {
	background-color: var(--white);
}

.dropdown:hover {
	background-color: var(--green-grey);
}

.green-link {
	color: var(--black);
	cursor: pointer;
	padding-left: 20px;
	padding-right: 20px;
	font-size: 14px;
	font-weight: 500;
	text-decoration: none;
}

.green-link:hover {
	font-size: 14px;
	text-decoration: underline;
}

.green-link.on-footer-copy {
	color: var(--light-green-iaec);
	text-transform: capitalize;
	padding-top: 10px;
	padding-left: 10px;
	padding-right: 10px;
	font-size: 16px;
	font-weight: 500;
}

.green-link.on-footer-copy:hover {
	text-decoration: underline;
}

.member-site-link {
	color: var(--black);
	cursor: pointer;
	padding-left: 20px;
	padding-right: 20px;
	font-size: 14px;
	font-weight: 500;
	text-decoration: none;
}

.member-site-link:hover {
	font-size: 14px;
	text-decoration: underline;
}

.member-site-link.on-footer-copy {
	color: var(--white);
	text-transform: capitalize;
	padding-top: 0px;
	padding-left: 10px;
	padding-right: 10px;
	font-size: 16px;
	font-weight: 500;
}

.member-site-link.on-footer-copy:hover {
	text-decoration: underline;
}

.member-site-link.on-footer-copy.my-profile {
	border: 1px solid var(--white);
	color: var(--white);
	text-transform: capitalize;
	background-image: url("/assets/images/my-profile-icon.svg");
	background-position: 15px;
	background-repeat: no-repeat;
	background-size: auto 20px;
	border-radius: 50px;
	justify-content: flex-start;
	align-self: center;
	align-items: center;
	height: 46px;
	margin-left: 10px;
	margin-right: 16px;
	padding: 9px 16px 9px 38px;
	font-size: 16px;
	font-weight: 500;
	display: flex;
}

.member-site-link.on-footer-copy.my-profile.hide {
	display: none;
}

.member-site-link.on-footer-copy.my-profile.active {
	color: var(--white) !important;
}

.member-site-link.on-footer-copy.my-profile.active:hover {
	color: var(--white) !important;
}

.html-embed {
	justify-content: center;
	align-items: center;
	height: 100%;
	display: flex;
}

.loginsitewrap {
	flex-flow: row;
	flex: none;
	justify-content: center;
	align-self: flex-start;
	align-items: center;
	display: flex;
}

.footerlogo {
	background-image: url('/20150924150955/assets/images/c4b6410f36dbd787228d606a641fae4222432bcfa2a9c9639ffb878ccd6ac78b.png');
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: contain;
	width: 300px;
	height: 175px;
}

.footerwrap {
	flex-flow: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	font-family: Jost, sans-serif;
	display: flex;
}

.wraper {
	grid-column-gap: 0px;
	grid-row-gap: 0px;
	flex-flow: column;
	justify-content: flex-start;
	align-items: flex-start;
	display: flex;
}

.footer-links-copy {
	color: var(--black);
	object-fit: cover;
	flex-direction: column;
	align-items: flex-start;
	display: flex;
}

.footer-links-copy.main {
	justify-content: center;
	align-items: center;
	height: 100%;
}

.div-block-38 {
	display: flex;
}

.bold-text-6 {
	font-size: 16px;
	font-weight: 500;
	line-height: 22px;
}

.bold-text-7 {
	color: #272727;
	font-size: 18px;
	font-weight: 500;
}

.quicklinksmobile {
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding-bottom: 60px;
	display: none;
	position: absolute;
}

.qlinksclose {
	color: #fff;
	letter-spacing: 2px;
	text-transform: uppercase;
	cursor: pointer;
	align-items: center;
	padding-top: 5px;
	padding-bottom: 5px;
	font-size: 12px;
	font-weight: 700;
	display: flex;
}

.button-1_color {
	z-index: 1;
	filter: blur(16px);
	mix-blend-mode: normal;
	background-image: linear-gradient(112deg, #ff0f33d6, #ff6600db);
	border-radius: 50%;
	justify-content: center;
	align-items: center;
	width: 50px;
	height: 50px;
	display: flex;
	position: absolute;
	top: -21px;
	left: -14px;
}

.button-1_color._1 {
	background-image: linear-gradient(#ffe202, #ffe202);
	top: -30%;
	left: -5%;
}

.new-ql-button {
	cursor: pointer;
	border-radius: 100px;
	justify-content: center;
	align-items: center;
	width: 160px;
	height: 45px;
	margin-top: 0;
	display: flex;
	position: relative;
	overflow: hidden;
}

.button-1_color-wrap-2 {
	background-color: #008457;
	border-radius: 100px;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	display: flex;
	position: absolute;
	overflow: hidden;
}

.label-3 {
	z-index: 5;
	color: #fff;
	font-size: 16px;
	font-weight: 500;
	position: relative;
}

.button-1_bg-2 {
	z-index: 3;
	background-color: #82c50f;
	border-radius: 100px;
	justify-content: center;
	align-items: center;
	width: 97%;
	height: 90%;
	transition: all 0.3s ease-in-out;
	display: flex;
	position: absolute;
	overflow: hidden;
}

.button-1_bg-2:hover {
	-webkit-backdrop-filter: blur(6px);
	backdrop-filter: blur(6px);
	background-color: #0084579e;
}

.button-1_bg-2:active {
	background-color: #05021c66;
}

.jobopeningssection {
	z-index: 1;
	background-color: var(--white);
	margin-bottom: 0;
	padding-bottom: 4rem;
	padding-left: 3%;
	padding-right: 3%;
	position: relative;
}

.jobopening-card {
	z-index: 30;
	grid-column-gap: 16px;
	grid-row-gap: 36px;
	border: 1px solid var(--green-grey);
	box-shadow: none;
	border-radius: 12px;
	flex-flow: row;
	grid-template-rows: auto auto;
	grid-template-columns: 1fr;
	grid-auto-columns: 1fr;
	width: 100%;
	max-width: 1250px;
	margin-left: auto;
	margin-right: auto;
	padding: 18px;
	display: flex;
	position: static;
	top: 185px;
	overflow: visible;
}

.jobcalloutboximg {
	border-radius: 5px;
	flex-direction: column;
	align-items: flex-start;
	width: 50%;
	display: flex;
	position: relative;
	overflow: hidden;
}

.jobcontent {
	grid-column-gap: 26px;
	grid-row-gap: 26px;
	flex-flow: column;
	grid-template-rows: auto auto;
	grid-template-columns: 1fr;
	grid-auto-columns: 1fr;
	justify-content: center;
	align-items: flex-start;
	width: 50%;
	padding-bottom: 16px;
	padding-left: 16px;
	padding-right: 12px;
	display: flex;
}

.content-container-2 {
	z-index: 2;
	grid-column-gap: 32px;
	grid-row-gap: 32px;
	border: 1px solid #e7eeee;
	border-radius: 20px;
	flex-flow: column;
	width: 100%;
	max-width: 1350px;
	margin-top: -65px;
	margin-left: auto;
	margin-right: auto;
	padding: 32px;
	display: flex;
	position: relative;
}

.buttonwrapper {
	grid-column-gap: 32px;
	grid-row-gap: 32px;
	justify-content: center;
	align-items: center;
	width: 100%;
	max-width: 1350px;
	display: flex;
}

.advant__columns {
	grid-column-gap: 32px;
	grid-row-gap: 32px;
	align-items: stretch;
	display: flex;
}

.dividerline {
	background-color: var(--light-green-iaec);
	border-radius: 20px;
	width: 90%;
	height: 1px;
}

.newsdate {
	z-index: 1;
	margin-bottom: 0;
	font-size: 15px;
	position: relative;
}

.advant__img-wrap {
	z-index: 1;
	background-color: #fff;
	border-radius: 100px;
	justify-content: center;
	align-items: center;
	margin-bottom: 16px;
	padding: 8px 16px;
	display: flex;
	position: relative;
	overflow: hidden;
	box-shadow: 0 10px 30px #005a8d0d;
}

.advant__img-grad {
	z-index: 0;
	background-color: var(--dark-green-iaec);
	color: #fff;
	height: 0%;
	transition: all 0.3s;
	position: absolute;
	inset: auto 0% 0%;
}

.advant__heading {
	height: 74px;
	margin-top: 16px;
	overflow: hidden;
}

.newssection {
	z-index: 1;
	grid-column-gap: 64px;
	grid-row-gap: 64px;
	background-color: var(--white);
	flex-flow: column;
	justify-content: flex-start;
	align-items: center;
	padding: 6rem 5% 100px;
	display: flex;
	position: relative;
}

.advant__block {
	background-color: var(--dark-green-iaec);
	box-shadow: none;
	color: var(--white);
	border-radius: 8px;
	flex-flow: column;
	justify-content: flex-start;
	align-items: flex-start;
	height: 100%;
	padding: 32px 32px 16px;
	transition: all 0.3s;
	display: flex;
}

.advant__block:hover {
	background-color: var(--green-grey);
	color: var(--black);
	transform: translate(0, -20px);
	box-shadow: 0 100px 80px -60px #090d1c0f;
}

.advant__col {
	flex: 1;
}

.articleimg {
	background-position: 0 0;
	background-repeat: no-repeat;
	background-size: cover;
	border-radius: 10px;
	width: 100%;
	height: 200px;
}

.p {
	font-size: 16px;
	font-weight: 300;
	line-height: 1.5em;
}

.link-5,
.link-6 {
	color: var(--black);
}

.search-form {
	justify-content: flex-end;
	align-items: center;
	width: 50%;
	margin-bottom: 0;
	transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
	display: flex;
	position: relative;
}

.search-form.mobile {
	display: none;
}

.ths01-search-input {
	border: 1px solid var(--dark-green-iaec);
	color: #221f20;
	background-color: #fff;
	border-radius: 320px;
	width: 0;
	height: 45px;
	margin-bottom: 0;
	padding-right: 30px;
	font-family: Jost, sans-serif;
	font-size: 16px;
	font-weight: 400;
	line-height: 20px;
	display: none;
}

.ths01-search-input:focus {
	border-color: var(--dark-green-iaec);
}

.ths01-search-input::placeholder {
	color: #a0989b;
}

.background-video {
	z-index: 0;
	width: 100%;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 50%;
}

.homepage-featured-video .hero-heading {
	display: none;
}

.paragraph-16 {
	color: #000;
	font-family: Montserrat, sans-serif;
	font-size: 16px;
}

.paragraph-16.white {
	color: #fff;
}

.main-content-column {
	padding-left: 0;
	padding-right: 0;
}

.sidebar-section-title-2 {
	color: var(--darker-green);
	margin-top: 0;
	margin-bottom: 0.5rem;
	font-weight: 700;
	line-height: 20px;
}

.sidebar-section-title-2.white {
	color: #fff;
	margin-bottom: 0.5rem;
}

.inside-pages-paragraph {
	margin-bottom: 1rem;
	padding-bottom: 0;
	font-size: 1rem;
	line-height: 1.25rem;
	text-decoration: none;
}

.inside-pages-paragraph.list-item {
	margin-bottom: 0;
}

.inside-pages-paragraph.underline {
	border-bottom: 1px solid #0000000d;
	margin-bottom: 2rem;
	padding-bottom: 2rem;
}

.heading-18-copy-copy {
	margin-bottom: 5px;
	font-size: 32px;
	font-weight: 700;
}

.paragraph-12 {
	color: #333;
	font-size: 1.2rem;
	font-weight: 400;
	line-height: 1.5rem;
}

.block-quote-2 {
	background-color: var(--dark-green-iaec);
	color: var(--white);
	background-image: url('/20150924150955/assets/images/reverse-iowarec-white-15-opacity.svg');
	background-position: 150% -10%;
	background-repeat: no-repeat;
	background-size: 75%;
	border-radius: 20px;
	flex-flow: column;
	margin-top: 32px;
	margin-bottom: 32px;
	padding: 32px;
	font-size: 1.5rem;
	font-style: italic;
	font-weight: 400;
	line-height: 1.75rem;
	display: flex;
}

.left-nav-nested-list-item {
	box-shadow: none;
	margin-bottom: 0;
}

.sidebar-list {
	grid-column-gap: 8px;
	grid-row-gap: 8px;
	flex-flow: column;
	margin-top: 0;
	margin-bottom: 0.75rem;
	padding-left: 0;
	display: flex;
}

.left-nav-list-content-wrap {
	background-color: var(--green-grey);
	border-right: 1px #0000000d;
	border-radius: 10px;
	height: auto;
	margin-top: 0;
	margin-right: 2rem;
	padding: 8px;
	display: block;
	overflow: hidden;
}

.inside-page-left-column .left-nav-div .left-nav-list-content-wrap {
	margin-right: 0;
}

.main-content-section {
	color: #131313;
	width: 100%;
	margin-right: 2rem;
}

.left-nav-list-item {
	box-shadow: none;
}

.h2-header-link {
	margin-bottom: 1rem;
	font-size: 1.8rem;
	font-weight: 400;
	line-height: 2.25rem;
	transition: all 0.2s;
	display: inline-block;
}

.h2-header-link:hover {
	background-color: #0000;
}

.image-block {
	width: 75%;
	height: 375px;
	position: relative;
	overflow: hidden;
}

.image-block.inside-page {
	width: 100%;
	height: 300px;
	margin-bottom: 2rem;
}

.h4-header-link {
	margin-bottom: 1rem;
	font-size: 22px;
	font-weight: 400;
	line-height: 24px;
	transition: all 0.2s;
	display: inline-block;
}

.h4-header-link:hover {
	background-color: #0000;
	border-bottom-width: 2px;
	border-bottom-color: #0000;
	font-weight: 400;
}

.inside-page-header-wrap {
	border-bottom: 0.5px solid #33333326;
	margin-bottom: 2rem;
	padding-top: 2rem;
	padding-bottom: 2rem;
}

.unordered-list {
	margin-bottom: 1rem;
}

.h3-header-link {
	margin-bottom: 1rem;
	font-size: 24px;
	font-weight: 400;
	line-height: 30px;
	transition: all 0.2s;
	display: inline-block;
}

.h3-header-link:hover {
	background-color: #0000;
}

.breadcrumb-current {
	color: var(--dark-green-iaec);
	text-transform: capitalize;
	border-bottom: 0.5px solid #0000;
	font-weight: 700;
	text-decoration: none;
	display: inline;
}

.breadcrumb-current:hover {
	border-bottom-style: solid;
	border-bottom-color: #643572;
}

.breadcrumb-current.w--current {
	color: #28313b;
	font-weight: 700;
}

.left-nav-list-link {
	text-transform: capitalize;
	border-radius: 10px;
	padding: 0.75rem 1.5rem 0.75rem 1rem;
	font-weight: 500;
	text-decoration: none;
	transition: all 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94);
	display: block;
	font-family: Jost, sans-serif;
}

.left-nav-list-link:hover {
	color: var(--black);
	background-color: #ceddd4;
	text-decoration: none;
}

.heading-11 {
	text-transform: capitalize;
	margin-bottom: 5px;
	font-size: 18px;
	font-weight: 700;
}

.left-nav-column {
	grid-column-gap: 16px;
	grid-row-gap: 16px;
	flex-flow: column;
	max-width: 350px;
	padding-left: 0;
	padding-right: 0;
	display: flex;
}

.horiz-divider-2 {
	color: #003c43;
	background-color: #5b5b5b26;
	height: 1px;
	margin-top: 1rem;
	margin-bottom: 1rem;
}

.sidebar-section-wrap {
	border-top: 1px #00000026;
	line-height: 1.35rem;
	position: relative;
}

.heading-18 {
	margin-bottom: 5px;
	font-size: 22px;
	font-weight: 700;
}

.heading-18.button-style {
	margin-bottom: 1rem;
}

.link-7 {
	font-weight: 400;
	text-decoration: none;
}

.link-7:hover {
	color: var(--dark-green-iaec);
	text-decoration: none;
}

.link-7.w--current {
	color: #28313b;
	font-weight: 700;
}

.breadcrumb-divider {
	float: right;
	margin-left: 0.5rem;
}

.breadcrumb-list-item {
	float: left;
	color: #000;
	justify-content: center;
	align-items: flex-start;
	margin-right: 0.5rem;
	padding-top: 0;
	font-size: 1rem;
	display: flex;
}

.div-block-152-copy {
	background-color: var(--green-grey);
	color: var(--black);
	border-radius: 10px;
	margin-left: 2rem;
	padding: 32px;
	font-size: 16px;
}

.intro-paragraph-2 {
	color: #333;
	margin-bottom: 1rem;
	font-size: 1.25rem;
	font-weight: 500;
	line-height: 1.5rem;
}

.h5-header-link {
	text-transform: capitalize;
	margin-bottom: 1rem;
	font-size: 18px;
	font-weight: 400;
	line-height: 20px;
	transition: all 0.2s;
	display: inline-block;
}

.h5-header-link:hover {
	background-color: #0000;
	border-bottom-color: #0000;
}

.heading-10-copy {
	text-transform: capitalize;
	margin-bottom: 5px;
	font-size: 16px;
	font-weight: 700;
}

.left-nav-nested-list {
	background-color: #dce4df;
	border-radius: 0 0 16px 16px;
	margin-top: 0;
	margin-bottom: 0;
	padding-top: 0;
	padding-bottom: 0;
}

.heading-6 {
	background-color: #0000;
	width: 100%;
	margin-top: 0;
	margin-bottom: 2rem;
	padding: 0;
	font-size: 2.75rem;
}

.table-div {
	color: #131313;
	font-family: Montserrat, sans-serif;
	font-size: 0.8rem;
	font-weight: 400;
}

.sidebar-list-item {
	color: #0915f8;
	margin-bottom: 0.5rem;
	padding-left: 0;
	font-family: Montserrat, sans-serif;
	text-decoration: none;
}

.inside-content-beckground {
	background-color: #fff;
	max-width: 1250px;
	margin-bottom: 6px;
	margin-left: auto;
	margin-right: auto;
	padding-top: 0;
	padding-bottom: 4rem;
	display: block;
	position: relative;
	min-height: 500px;
	box-shadow: 0 -20px 20px -4px #00000029;
}

.inside-content-beckground.center {
	max-width: 800px;
}

.ordered-list-2 {
	margin-bottom: 1rem;
	font-family: Open Sans, sans-serif;
	font-size: 1rem;
	line-height: 1.5rem;
}

.left-nav-nested-list-link {
	color: #333;
	text-transform: capitalize;
	border: 2px #0000;
	padding: 0.75em 1.5rem;
	font-family: Montserrat, sans-serif;
	font-size: 0.85em;
	font-weight: 500;
	line-height: 1.375em;
	font-family: Jost, sans-serif;
	text-decoration: none;
	display: block;
}

.left-nav-nested-list-link:hover {
	color: #0915f8;
	background-color: #0000;
	background-image: none;
	border: 2px #48bdd7;
}

.left-nav-nested-list-link.grandchild-nav-link {
	color: var(--black);
	border-radius: 25px;
	font-size: 0.9em;
	transition: all 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.left-nav-nested-list-link.grandchild-nav-link:hover {
	background-color: #ceddd4;
	border-radius: 10px;
}

.left-nav-nested-list-link.grandchild-nav-link.great {
	padding-left: 2.5rem;
	font-size: 0.9em;
}

.heading-18-copy {
	margin-bottom: 5px;
	font-size: 24px;
	font-weight: 700;
}

.inner-image-content {
	background-image: url('/20150924150955/assets/images/GettyImages-1180123651.jpg');
	background-position: 0 0;
	background-repeat: no-repeat;
	background-size: auto;
	height: 100%;
}

.inner-image-content.recent-blog-post {
	background-image: url('/20150924150955/assets/images/AdobeStock_106067507_Preview.jpeg');
	background-position: 50%;
	background-repeat: repeat;
	background-size: cover;
	align-items: flex-end;
	width: 100%;
	height: 100%;
	padding-top: 0;
	padding-bottom: 0;
	padding-left: 0;
	display: flex;
}

.right-sidebar-column {
	max-width: 350px;
}

.left-nav-list {
	box-shadow: none;
	margin-top: 0;
	margin-bottom: 0;
	padding-left: 0;
}

.link-8 {
	color: #000;
	text-decoration: underline;
}

.link-8:hover {
	color: var(--dark-green-iaec);
	text-decoration: none;
}

.link-8.w--current {
	color: #28313b;
	font-weight: 700;
}

.link-8.white {
	color: #fff;
}

.link-8.white:hover {
	color: var(--light-green-iaec);
}

.h6-header-link {
	text-transform: capitalize;
	margin-bottom: 1rem;
	font-size: 16px;
	font-weight: 400;
	line-height: 18px;
	transition: all 0.2s;
	display: inline-block;
}

.section-wrap {
	overflow: hidden;
}

.quote-content {
	border-left-style: none;
	margin-bottom: 0;
	padding: 0;
	font-size: 1.3rem;
	font-style: italic;
	line-height: 1.75rem;
}

.breadcrumb-list {
	text-transform: uppercase;
	margin: 0 0.125rem;
	padding-left: 0;
	font-size: 0.6875rem;
	line-height: 1em;
}

.button-styling {
	grid-column-gap: 16px;
	grid-row-gap: 16px;
	align-items: center;
	padding-left: 0;
	font-family: Open Sans, sans-serif;
	font-weight: 800;
	display: flex;
}

.left-side-secondary-content-box {
	background-color: var(--darker-green);
	border-radius: 10px;
	margin-right: 32px;
	padding: 32px;
	font-size: 16px;
}

.breadcrumb-link-1 {
	color: #000;
	text-transform: none;
	border-bottom: 0.5px solid #0000;
	text-decoration: none;
	display: inline;
}

.breadcrumb-link-1:hover {
	border-bottom-style: solid;
	border-bottom-color: #643572;
}

.breadcrumb-link-1.w--current {
	color: #28313b;
	font-weight: 700;
}

.main-content-wrapper {
	margin-left: auto;
	margin-right: auto;
	padding-left: 64px;
	padding-right: 64px;
	display: block;
}

.inside-content {
	justify-content: space-between;
	align-items: stretch;
	display: flex;
}

.body-2 {
	font-family: Jost, sans-serif;
}

.member-site-link-copy {
	color: #272727;
	cursor: pointer;
	padding-left: 20px;
	padding-right: 20px;
	font-size: 14px;
	font-weight: 500;
	text-decoration: none;
}

.member-site-link-copy:hover {
	font-size: 14px;
	text-decoration: underline;
}

.member-site-link-copy.on-footer-copy {
	color: #fff;
	text-transform: capitalize;
	padding-top: 10px;
	padding-left: 10px;
	padding-right: 10px;
	font-size: 16px;
	font-weight: 500;
}

.member-site-link-copy.on-footer-copy:hover {
	text-decoration: underline;
}

.mobile-site-links {
	display: none;
}

.menu-drop {
	border-radius: 0;
	flex-direction: column;
	align-items: flex-start;
	width: 100%;
	margin-bottom: 10px;
	display: flex;
}

.link {
	color: #005eb8;
	text-align: left;
	align-self: auto;
	font-size: 15px;
	text-decoration: none;
}

.link:hover {
	text-decoration: underline;
}

.link.white {
	color: #fff;
	text-transform: capitalize;
	font-size: 18px;
	text-decoration: none;
}

.link.white:hover {
	text-decoration: underline;
}

.p-m-wrap {
	justify-content: center;
	align-items: center;
	width: 15px;
	height: 15px;
	display: flex;
	position: relative;
}

.ths02-side-nav-link {
	color: #fff;
	cursor: pointer;
	margin-bottom: 10px;
	font-size: 25px;
	font-weight: 500;
	line-height: 34px;
	text-decoration: none;
}

.ths02-side-nav-link:hover {
	color: #82c50f;
}

.text-block {
	color: #fff;
	text-transform: capitalize;
	font-weight: 700;
}

.ths02-side-nav-links-wrapper {
	z-index: 1;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	height: 80%;
	display: flex;
	position: relative;
}

.faq-answer {
	grid-column-gap: 5px;
	grid-row-gap: 5px;
	text-align: left;
	border-bottom: 1px #cfcfcf;
	flex-direction: column;
	align-items: flex-start;
	width: 100%;
	padding-left: 20px;
	display: flex;
	overflow: hidden;
}

.footer-social-icon-link-2 {
	opacity: 1;
	background-color: #82c50f;
	border-radius: 50%;
	margin-top: 4px;
	margin-bottom: 8px;
	margin-right: 6px;
}

.footer-social-icon-link-2:hover {
	opacity: 1;
	background-color: #008457;
}

.footer-social-icon-link-2:active {
	text-transform: capitalize;
}

.footer-social-icon-link-2.hoverwhite:hover {
	background-color: #008457;
}

.green-link-copy {
	color: #272727;
	cursor: pointer;
	padding-left: 20px;
	padding-right: 20px;
	font-size: 14px;
	font-weight: 500;
	text-decoration: none;
}

.green-link-copy:hover {
	font-size: 14px;
	text-decoration: underline;
}

.green-link-copy.on-footer-copy {
	color: #82c50f;
	text-transform: capitalize;
	padding-top: 10px;
	padding-left: 10px;
	padding-right: 10px;
	font-size: 16px;
	font-weight: 500;
}

.green-link-copy.on-footer-copy:hover {
	text-decoration: underline;
}

.plus {
	background-color: #ffffffbf;
	border-radius: 20px;
	width: 3px;
	height: 100%;
	position: absolute;
}

.burgersidemenu {
	z-index: 25000;
	background-color: #008457;
	flex-flow: column;
	justify-content: space-between;
	align-items: flex-start;
	min-width: 400px;
	max-width: 400px;
	height: 100vh;
	padding-left: 50px;
	padding-right: 50px;
	display: flex;
	position: absolute;
	inset: 0 0% 0% 100%;
}

.sidemenubtn {
	text-transform: capitalize;
	font-size: 24px;
	font-weight: 600;
	line-height: 40px;
}

.ths02-side-nav-links-block {
	flex-direction: column;
	font-size: 14px;
	display: flex;
}

.ths02-side-nav-header {
	z-index: 1;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	width: 100%;
	height: 90px;
	display: flex;
	position: relative;
}

.minus {
	background-color: #ffffffbf;
	border-radius: 20px;
	width: 100%;
	height: 3px;
	position: absolute;
}

.ths02-side-nav-close {
	color: #fff;
	letter-spacing: 2px;
	text-transform: uppercase;
	cursor: pointer;
	align-items: center;
	padding-top: 5px;
	padding-bottom: 5px;
	font-size: 12px;
	font-weight: 700;
	display: flex;
}

.ths02-menu-bars-wrapper {
	cursor: pointer;
	background-color: #e8edea;
	border-radius: 100px;
	flex-direction: column;
	justify-content: center;
	align-items: flex-end;
	margin-left: 16px;
	padding: 14px 10px;
	display: none;
}

.ths02-menu-bar {
	background-color: #008457;
	width: 28px;
	height: 2px;
	margin-top: 4px;
	margin-bottom: 4px;
}

.ths02-menu-bar-small {
	background-color: #008457;
	width: 18px;
	height: 2px;
	margin-top: 4px;
	margin-bottom: 4px;
}

.wrapper-2xs {
	padding-top: 16px;
	padding-bottom: 16px;
}

.toggle-button-fill-m {
	opacity: 0;
	border-radius: 12px;
	width: 44px;
	height: 24px;
	position: absolute;
}

.button-toggle-m {
	z-index: 2;
	background-color: #fff;
	border-radius: 100%;
	justify-content: center;
	align-items: center;
	width: 20px;
	height: 20px;
	margin-left: 4px;
	margin-right: 4px;
	display: flex;
	position: relative;
	box-shadow: 0 1px 2px #1018280f, 0 1px 3px #1018281a;
}

.button-toggle-m.dark {
	background-color: #fff;
	justify-content: center;
	align-items: center;
	display: flex;
}

.toggle-button-text {
	display: flex;
}

.toggle-button-m-dark {
	background-color: #3c404b;
	border-radius: 12px;
	justify-content: flex-start;
	align-items: center;
	width: 44px;
	height: 24px;
	text-decoration: none;
	display: flex;
	position: relative;
}

.text {
	margin-bottom: 0;
}

.text.s-medium {
	margin-bottom: 1.142em;
	font-size: 14px;
	font-weight: 500;
	line-height: 1.714em;
}

.text.s-medium.toggle-margin-m {
	margin-bottom: 0;
	margin-left: 12px;
}

.text.s-medium.toggle-margin-m.white {
	color: #fff;
}

.offtext,
.ontext {
	margin-bottom: 0;
}

@media screen and (min-width: 1440px) {
	.top-half {
		max-width: 1600px;
		padding-left: 32px;
		padding-right: 32px;
	}

	.newssection {
		padding-left: 10%;
		padding-right: 10%;
	}
}

@media screen and (max-width: 991px) {
	.top-nav-div {
		display: none;
	}

	.links-container.on-footer {
		text-align: center;
		padding-right: 0;
	}

	.grip-logo-link {
		float: right;
		margin-bottom: 1rem;
		margin-left: 0;
		margin-right: 0;
	}

	.footer-signoff-links {
		margin-left: 0;
		margin-right: 0.75rem;
	}

	.footer-signoff-links.last {
		margin-right: 0;
	}

	.sign-off-div-wrap {
		width: 100%;
		min-width: auto;
	}

	.signoff-div {
		flex-flow: column;
		justify-content: center;
		align-items: center;
	}

	.column-3 {
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		display: flex;
	}

	.column-2 {
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding-left: 0;
		padding-right: 0;
	}

	.footer-copyright {
		float: left;
		text-align: center;
		justify-content: center;
		align-items: center;
		margin-bottom: 0.25rem;
		margin-right: 0;
		display: flex;
	}

	.footer-signoff-link-wrap {
		float: left;
		text-align: center;
		justify-content: center;
		align-items: center;
		margin-bottom: 1.5rem;
		display: flex;
	}

	.grid-3 {
		grid-column-gap: 2rem;
		grid-row-gap: 2rem;
		flex-flow: column;
		justify-content: flex-start;
		align-items: center;
		width: 100%;
	}

	.footer-links.margin {
		text-align: center;
		justify-content: flex-start;
		align-items: center;
		margin-right: 64px;
	}

	.footer-links.margin.last {
		margin-right: 0;
	}

	.heading-4 {
		margin-left: auto;
		margin-right: auto;
	}

	.paragraph-2 {
		text-align: center;
	}

	.container-4 {
		max-width: 991px;
	}

	.container-4.nav-container {
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		width: 100%;
		max-width: none;
		height: auto;
		margin-top: 0;
		margin-left: 0;
		margin-right: 0;
		padding-left: 0;
		padding-right: 0;
		display: flex;
		position: relative;
		overflow: auto;
	}

	.dropdown-list-2 {
		display: none;
	}

	.icon-column-3 {
		flex-wrap: nowrap;
		justify-content: center;
		align-items: center;
		width: 100%;
		padding-bottom: 0;
		display: flex;
	}

	.dropdown-wrapper-3 {
		text-align: left;
		justify-content: flex-start;
		width: 100%;
	}

	.nav-menu-2 {
		z-index: 2147483647;
		text-align: left;
		background-color: #f1f1f1;
		border-top: 4px solid #bebebe;
		border-left: 1px solid #bebebe;
		justify-content: flex-start;
		width: 100%;
		height: 49vh;
		margin-top: 0;
		margin-left: 0;
		display: flex;
		overflow: auto;
	}

	.nav-menu-2._1 {
		height: 100vh;
		margin-top: 90px;
		overflow: scroll;
	}

	.nav-menu-2._1.nav-menu-scrollable {
		border-top-style: none;
		border-left-style: none;
		border-radius: 0;
		flex-direction: column;
		flex: 0 auto;
		justify-content: center;
		align-self: auto;
		align-items: center;
		width: 50%;
		height: auto;
		margin-top: 94px;
		margin-right: 0;
		padding: 16px;
		display: none;
		top: 0%;
		left: auto;
		right: 0%;
		overflow: visible;
	}

	.main-nav-link-2 {
		color: #00283b;
		text-align: left;
		text-transform: capitalize;
		border-color: #5d3f3a59;
		border-top-style: none;
		border-left-style: none;
		border-right-style: none;
		justify-content: flex-start;
		margin-top: 0;
		padding: 1rem;
		font-size: 1rem;
		font-weight: 600;
		line-height: 1.5rem;
	}

	.main-nav-link-2:hover {
		background-color: var(--white);
		color: var(--black);
		line-height: 1.5rem;
	}

	.main-nav-link-2.w--current {
		background-color: #ffffffb3;
		border-bottom-color: #5d3f3a59;
	}

	.top-half {
		flex-flow: row;
		justify-content: space-between;
		align-items: center;
		margin-right: 0;
		padding-right: 5%;
		display: flex;
		box-shadow: 0 10px 20px #0000001f;
	}

	.icon-column-wrap {
		flex-flow: row;
		justify-content: flex-start;
		align-items: flex-start;
		display: flex;
	}

	.navbar-2 {
		flex-direction: column;
		justify-content: space-around;
		height: auto;
		display: flex;
	}

	.ths05-dropdown {
		display: flex;
	}

	.columns {
		flex-flow: column;
		justify-content: center;
		align-items: center;
	}

	.footer-social-icons-links-wrap {
		flex-wrap: wrap;
		align-content: flex-start;
	}

	.footer-social-icons-links-wrap.vertical {
		justify-content: center;
		align-items: flex-start;
		margin-top: 16px;
	}

	.footer-social-icon-link.last {
		margin-right: 0;
	}

	.content-wrap-stats {
		padding-left: 0;
	}

	.footer.is-red.footer-links {
		padding-left: 5%;
	}

	.sticky_container {
		width: 80%;
		margin-left: 0;
		padding-left: 32px;
	}

	.heading-h3.mb-8 {
		font-size: 1.3em;
	}

	.sticky_txt-container {
		width: 60%;
	}

	.heading-h2 {
		font-size: 2.4em;
	}

	.heading-h2.heading-h2--large {
		font-size: 2.5em;
	}

	.heading-h2.heading-h2--large.mb-8 {
		font-size: 45px;
	}

	.search-content {
		border-top: 1px solid #5b5b5b26;
		border-radius: 0;
		width: 100%;
		margin: 172px 0 0 0%;
		padding-left: 3rem;
		padding-right: 3rem;
	}

	.search-input-3 {
		padding-left: 0;
	}

	.search-icon {
		margin-top: 10px;
	}

	.search-icon.mobile {
		margin-top: 0;
		padding-left: 0;
	}

	.search-button-icon,
	.search-button-icon:hover {
		background-size: 35px;
	}

	.chat-wrapper {
		z-index: 99;
		margin-top: 35px;
		margin-bottom: -10px;
		bottom: 0%;
	}

	.bubble-section {
		justify-content: flex-end;
		align-items: flex-end;
		inset: auto 3% 3% auto;
	}

	.project-content {
		width: 73%;
	}

	.project-image {
		width: 73%;
		height: 270px;
	}

	.project-image.six {
		padding: 0;
		height: auto;
	}

	.counter-2 {
		font-size: 30px;
	}

	.project-image.six .grid-4-columns {
		grid-column-gap: 51px;
		grid-row-gap: 51px;
		grid-template-columns: 1fr 1fr;
		max-width: 70%;
		padding: 5%;
		position: unset;
	}

	._6-categories {
		margin-top: 20px;
		padding: 50px 5% 0;
	}

	.steps-card-wrapper {
		grid-row-gap: 48px;
	}

	.steps-grid {
		grid-row-gap: 60px;
		grid-template-rows: 1fr;
		grid-template-columns: 1fr;
	}

	.grid-wrapper {
		grid-template-columns: minmax(65px, 1fr) minmax(auto, 1400px) minmax(65px, 1fr);
	}

	.steps-tracker {
		margin-left: 25px;
	}

	.steps-sticky-card {
		grid-template-columns: 1fr;
		max-width: none;
		position: relative;
		top: auto;
	}

	.navbar-link {
		border-bottom: 1px #d2d2cf;
	}

	.home-faq-top {
		grid-template-columns: 2rem 1fr auto;
	}

	.home-faq-content {
		padding-top: 2rem;
		padding-bottom: 2rem;
		padding-left: 3rem;
	}

	.text-size-regular {
		font-size: 1.1rem;
	}

	.home-service_grid-2 {
		justify-content: center;
		align-items: flex-start;
	}

	.sectionwrap {
		padding-top: 0;
		padding-left: 0;
		padding-right: 0;
	}

	.sectionwrap.padding-large {
		padding-top: 6rem;
		padding-left: 5%;
		padding-right: 5%;
	}

	.product_list {
		width: 100%;
	}

	.product_link {
		grid-column-gap: 1rem;
		grid-row-gap: 1.5rem;
		padding-bottom: 1.5rem;
		padding-left: 3vw;
		padding-right: 3vw;
	}

	.product_link.first {
		padding-top: 0;
	}

	.product_img {
		opacity: 1;
		flex: none;
		width: 27%;
		position: relative;
	}

	.product_content {
		grid-column-gap: 16px;
		grid-row-gap: 16px;
		flex-wrap: wrap;
		padding-right: 0;
	}

	.product_title {
		font-size: 1.4rem;
	}

	.navlinkwrapper {
		padding-top: 16px;
	}

	.navwrapper {
		justify-content: flex-end;
		align-self: flex-end;
		align-items: flex-end;
		right: 0;
	}

	.logo-copy {
		max-width: 100%;
		height: 70px;
		padding-right: 0;
		width: 250px;
	}

	.div-block-35 {
		margin-left: 0;
		margin-right: 0;
	}

	.logotype_white {
		width: 250px;
	}

	.green-link.on-footer-copy,
	.member-site-link.on-footer-copy {
		padding-top: 0;
		display: none;
	}

	.loginsitewrap {
		flex-wrap: wrap;
		place-content: flex-start;
		align-items: center;
	}

	.div-block-38 {
		text-align: center;
		justify-content: center;
		align-items: flex-start;
		margin-top: 16px;
	}

	.bold-text-6 {
		text-align: center;
	}

	.quicklinksmobile {
		justify-content: center;
		align-items: flex-end;
	}

	.jobopening-card {
		grid-template-columns: 1fr;
		max-width: none;
		position: relative;
		top: auto;
	}

	.advant__columns {
		grid-column-gap: 16px;
		grid-row-gap: 16px;
		flex-flow: column;
	}

	.advant__heading {
		max-width: 500px;
		height: 48px;
	}

	.advant__block {
		justify-content: flex-start;
		align-items: flex-start;
	}

	.advant__block:hover {
		transform: none;
	}

	.p.mod--advant {
		max-width: 500px;
	}

	.search-form {
		justify-content: flex-end;
		width: auto;
		margin-left: 62px;
		display: flex;
	}

	.search-form.mobile {
		justify-content: center;
		align-items: center;
		width: 100%;
		margin: 16px auto;
		display: flex;
	}

	.ths01-search-input {
		width: 0;
		padding-left: 10px;
		padding-right: 0;
		display: none;
	}

	.ths01-search-input.open {
		width: 100%;
		height: 47px;
		padding-left: 16px;
		display: block;
	}

	.main-content-column {
		padding-left: 0;
		padding-right: 0;
	}

	.left-nav-list-content-wrap {
		height: 0;
		overflow: hidden;
	}

	.image-block {
		width: 65%;
	}

	.inside-page-header-wrap {
		margin-bottom: 1rem;
		padding-top: 0;
		padding-bottom: 1rem;
		display: none;
	}

	.left-nav-column {
		display: none;
	}

	.sidebar-section-wrap {
		margin-left: 0;
	}

	.div-block-152-copy {
		width: 100%;
		margin-top: 32px;
		margin-left: 0;
	}

	.inside-content-beckground {
		max-width: 100%;
		padding: 3rem 40px 2rem;
	}

	.right-sidebar-column {
		border-top: 3px #48bdd7;
		max-width: none;
		margin-top: 0;
		padding-top: 0;
	}

	.left-nav-list {
		margin-bottom: 1.5em;
	}

	.left-side-secondary-content-box {
		width: 100%;
		margin-top: 32px;
	}

	.main-content-wrapper {
		width: 100%;
		padding-left: 0;
		padding-right: 0;
	}

	.inside-content {
		flex-flow: column;
	}

	.member-site-link-copy.on-footer-copy {
		padding-bottom: 10px;
	}

	.mobile-site-links {
		border-top: 1px solid #82c50f;
		border-bottom: 1px solid #82c50f;
		display: flex;
	}

	.link {
		text-align: left;
		font-size: 14px;
	}

	.green-link-copy.on-footer-copy {
		padding-bottom: 10px;
	}

	.burgersidemenu {
		grid-column-gap: 16px;
		grid-row-gap: 16px;
		justify-content: space-between;
		align-items: stretch;
		max-width: 800px;
		padding-top: 16px;
	}

	.ths02-menu-bars-wrapper {
		padding: 12px 8px;
		display: flex;
	}

	.wrapper-2xs {
		padding-top: 12px;
		padding-bottom: 12px;
	}

	.left-nav-div,
	.inside-page-left-column {
		display: none;
	}
}

@media screen and (max-width: 767px) {
	.top-nav-div {
		display: none;
	}

	.links-container.on-footer {
		padding-right: 0;
	}

	.grip-logo-link {
		float: none;
		margin-bottom: 0;
	}

	.footer-signoff-links.last {
		margin-right: 0;
	}

	.footer-signoff-link {
		font-size: 1rem;
	}

	.column-3 {
		justify-content: flex-start;
		align-items: center;
		overflow: hidden;
	}

	.column-2 {
		flex-direction: row;
		justify-content: center;
		align-items: center;
		padding-left: 0;
	}

	.footer-copyright {
		float: none;
	}

	.footer-signoff-link-wrap {
		float: none;
		display: inline-block;
	}

	.grid-3 {
		flex-flow: column;
		grid-template-rows: auto auto;
		grid-template-columns: 1fr 1fr;
		justify-content: center;
		align-items: stretch;
	}

	.footer-links.margin {
		text-align: center;
		flex-flow: column;
		justify-content: center;
		align-items: center;
		margin-bottom: 10px;
		margin-right: 0;
		font-size: 17px;
	}

	.paragraph-2 {
		text-align: center;
	}

	.container-4 {
		max-width: 767px;
	}

	.container-4.nav-container {
		max-width: 728px;
	}

	.icon-column-3 {
		justify-content: flex-end;
		align-items: center;
		width: auto;
		max-width: 160px;
	}

	.nav-menu-2 {
		width: 80%;
		height: 55vh;
		margin-top: 0;
		position: relative;
		overflow: auto;
	}

	.nav-menu-2._1 {
		margin-top: 60px;
		margin-left: 0;
		left: 0;
	}

	.nav-menu-2._1.nav-menu-scrollable {
		width: 70%;
		margin-top: 70px;
		position: absolute;
		left: auto;
	}

	.top-half {
		clear: right;
		justify-content: space-between;
		align-items: center;
		padding-top: 0;
		padding-bottom: 0;
		padding-right: 3rem;
		display: flex;
		position: static;
	}

	.navbar-2 {
		display: block;
	}

	.columns {
		flex-flow: column;
		justify-content: center;
		align-items: flex-start;
	}

	.footer-social-icons-links-wrap.vertical {
		justify-content: center;
		align-items: center;
		width: 100%;
	}

	.sticky_container {
		width: 100%;
		padding-top: 35vh;
		padding-right: 0;
		position: static;
	}

	.green-section-sticky {
		flex-direction: column-reverse;
		justify-content: space-between;
		align-items: flex-start;
		padding-top: 64px;
	}

	.heading-h3 {
		font-size: 1.6em;
	}

	.heading-h3.mb-8 {
		text-align: center;
		align-self: center;
		width: 80%;
	}

	.sticky_txt-container {
		max-width: none;
		margin-bottom: 30vh;
		margin-left: auto;
		margin-right: auto;
		position: static;
	}

	.heading-h2 {
		font-size: 2em;
	}

	.heading-h2.heading-h2--large {
		font-size: 2.3em;
	}

	.heading-h2.heading-h2--large.mb-8 {
		text-align: center;
		margin-top: 0;
		line-height: 1.7em;
	}

	.search-content {
		z-index: 1000;
		border-top: 1px solid #5b5b5b26;
		width: 80%;
		margin-top: 100px;
		margin-left: auto;
		margin-right: auto;
		padding-left: 2rem;
		padding-right: 2rem;
		display: flex;
		inset: 0% 0% auto 0;
	}

	.search-input-3 {
		padding-left: 0;
	}

	.search-icon.mobile {
		margin-top: 0;
		margin-left: auto;
	}

	.search-button-icon,
	.search-button-icon:hover {
		background-size: 30px;
	}

	.search-button-icon.open {
		height: 46px;
		bottom: 0;
	}

	.chat-wrapper {
		z-index: 99;
		margin-top: 0;
		margin-bottom: -16px;
		display: none;
		position: static;
	}

	.bubble-section {
		width: 100%;
	}

	.bubble-section,
	.chat-button {
		display: inline-flex;
	}

	.lightning-ixon {
		justify-content: flex-end;
	}

	.ths02-close-icon {
		width: 25px;
	}

	.button-line-1 {
		width: 18px;
	}

	.project-content {
		width: 74.4%;
	}

	.image-overlay {
		flex-flow: column;
	}

	.project-image {
		width: 74.4%;
	}

	.project-image.six {
		height: auto;
		padding-top: 15px;
		padding-bottom: 15px;
	}

	.grid-4-columns {
		position: static;
	}

	.square-icon {
		border-radius: 12px;
		max-width: 56px;
	}

	.grid-4-columns {
		grid-column-gap: 32px;
		grid-row-gap: 32px;
		flex-flow: column;
		max-width: 240px;
		display: flex;
	}

	.steps-card-wrapper {
		grid-row-gap: 36px;
	}

	.steps-grid {
		padding-left: 0;
	}

	.grid-wrapper {
		grid-template-columns: minmax(36px, 1fr) minmax(auto, 1400px) minmax(36px, 1fr);
	}

	.steps-sticky-card.lightgreen {
		width: 100%;
		margin-top: 32px;
	}

	.navbar-link {
		min-height: auto;
		padding: 1.5rem;
	}

	.text-size-large {
		font-size: 1.25rem;
	}

	.home-faq-list {
		padding-left: 1.5rem;
		padding-right: 1.5rem;
	}

	.home-faq-list.radius-regular {
		padding: 0 1rem;
	}

	.home-faq-top {
		grid-column-gap: 0.25rem;
		grid-row-gap: 0.25rem;
		grid-template-columns: 1fr auto;
		padding-top: 1.5rem;
		padding-bottom: 1.5rem;
	}

	.home-faq-content {
		margin-bottom: 1.75rem;
		padding: 1.75rem 1.5rem;
	}

	.home-faq-content.radius-regular {
		padding: 1.2rem;
	}

	.text-size-regular {
		font-size: 1rem;
	}

	.home-service_grid-2 {
		flex-flow: column;
		padding-top: 3rem;
	}

	.sectionwrap {
		padding-top: 0;
		padding-left: 0;
		padding-right: 0;
	}

	.sectionwrap.padding-large {
		margin-top: -1px;
	}

	.row-title-block {
		width: 100%;
	}

	.letter-1 {
		font-size: 8vw;
		line-height: 8vw;
	}

	.letter-animation {
		width: 100%;
		overflow: hidden;
	}

	.nav-link {
		border-top: 1px solid var(--green-grey);
		justify-content: flex-start;
		align-items: center;
		padding-top: 5px;
		padding-bottom: 5px;
	}

	.nav-link.mobile {
		border-top-style: none;
		flex-flow: column;
		justify-content: flex-start;
		align-items: flex-start;
		margin-top: 0;
		margin-left: 0;
		margin-right: 0;
		padding-top: 0;
		padding-bottom: 0;
	}

	.nav-link.mobile:hover {
		background-color: unset;
	}

	.navlinkwrapper {
		width: 100%;
		display: none;
		left: 0;
		right: 0;
	}

	.text-size-xsmall-2 {
		direction: ltr;
		text-align: left;
		white-space: normal;
	}

	.button-line-3 {
		height: 18px;
	}

	.button-4 {
		white-space: nowrap;
	}

	.button-4.mobileversion {
		background-color: #fff;
		border-radius: 1rem;
		flex-flow: column;
		justify-content: space-between;
		align-items: flex-start;
		width: 100%;
		height: 100%;
		padding: 1rem;
		transition: all 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955);
		display: flex;
		box-shadow: 0 2px 20px 1px #00000008;
	}

	.button-4.mobileversion:hover {
		background-color: #e8edea52;
		background-position: 97%;
	}

	.button-5 {
		max-width: 30px;
		max-height: 30px;
	}

	.navwrapper {
		position: static;
	}

	.div-block-35 {
		flex-flow: column;
	}

	.faq-question.special {
		padding-right: 0;
	}

	.green-link.on-footer-copy,
	.member-site-link.on-footer-copy {
		display: none;
	}

	.html-embed {
		width: 22px;
	}

	.loginsitewrap {
		justify-content: flex-end;
		align-items: center;
	}

	.footerlogo {
		width: 80%;
		height: 100px;
	}

	.wraper,
	.footer-links-copy.main {
		justify-content: center;
		align-items: center;
	}

	.div-block-38 {
		flex-flow: column;
		justify-content: center;
		align-self: center;
		align-items: flex-start;
	}

	.quicklinksmobile {
		z-index: 15000;
		background-color: #e8edea;
		justify-content: flex-start;
		align-items: stretch;
		width: 100%;
		height: 100vh;
		padding-bottom: 0;
		display: none;
		position: fixed;
		top: 0%;
		left: 0%;
		right: 0%;
		overflow: scroll;
	}

	.quicklinkwrapper {
		z-index: 100;
		grid-column-gap: 16px;
		grid-row-gap: 16px;
		background-color: #fff;
		border-radius: 20px;
		grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
		grid-template-columns: 1fr 1fr;
		grid-auto-rows: 1fr;
		grid-auto-columns: 1fr;
		width: auto;
		margin-top: -3rem;
		margin-left: 32px;
		margin-right: 32px;
		padding: 16px;
		display: grid;
		position: relative;
	}

	.quicklinksmobilemenu {
		background-image: linear-gradient(to bottom, var(--dark-green-iaec), var(--dark-green-iaec));
		color: var(--black);
		flex-flow: column;
		justify-content: flex-start;
		align-items: center;
		width: 100%;
		height: 175px;
		padding-top: 32px;
		padding-left: 32px;
		padding-right: 32px;
		display: flex;
	}

	.heading-10 {
		color: #fff;
	}

	.link-icon {
		background-image: url('/20150924150955/assets/images/icon_2.svg');
		background-position: 50%;
		background-repeat: no-repeat;
		background-size: contain;
		width: 25px;
		height: 25px;
		margin-bottom: 0.5rem;
		padding-bottom: 0;
		padding-right: 0;
	}

	.link-icon._2 {
		background-image: url('/20150924150955/assets/images/icon_8.svg');
	}

	.link-icon._3 {
		background-image: url('/20150924150955/assets/images/icon_9.svg');
	}

	.link-icon._4 {
		background-image: url('/20150924150955/assets/images/icon_5.svg');
	}

	.link-icon._5 {
		background-image: url('/20150924150955/assets/images/icon_4.svg');
	}

	.link-icon._6 {
		background-image: url('/20150924150955/assets/images/icon.svg');
	}

	.link-icon._7 {
		background-image: url('/20150924150955/assets/images/icon_1.svg');
	}

	.link-icon._8 {
		background-image: url('/20150924150955/assets/images/icon_6.svg');
	}

	.link-icon._9 {
		background-image: url('/20150924150955/assets/images/icon_7.svg');
	}

	.link-icon._10 {
		background-image: url('/20150924150955/assets/images/icon_3.svg');
	}

	.logo-mobile-links {
		opacity: 1;
		background-image: url('/20150924150955/assets/images/c4b6410f36dbd787228d606a641fae4222432bcfa2a9c9639ffb878ccd6ac78b.png');
		background-position: 50%;
		background-repeat: no-repeat;
		background-size: contain;
		height: 100px;
		margin: 32px auto 100px;
		padding-bottom: 0;
		padding-right: 0;
	}

	.jobopeningssection {
		margin-top: -1px;
	}

	.advant__columns {
		grid-column-gap: 16px;
		grid-row-gap: 16px;
		flex-flow: column;
		grid-template-rows: auto auto;
		grid-template-columns: 1fr 1fr;
		grid-auto-columns: 1fr;
		display: flex;
	}

	.dividerline {
		width: auto;
		min-width: 25%;
	}

	.advant__block {
		width: 100%;
		padding: 26px;
	}

	.search-form {
		margin-top: 0;
		margin-left: 48px;
		display: none;
	}

	.search-form.mobile {
		justify-content: center;
		align-items: center;
		width: 100%;
		margin: 16px auto;
		display: flex;
	}

	.ths01-search-input.open {
		width: 100%;
		height: 47px;
		padding-left: 16px;
		display: block;
	}

	.block-quote-2 {
		font-size: 1.125rem;
		line-height: 1.5rem;
	}

	.main-content-section {
		margin-right: 0;
	}

	.h2-header-link {
		font-size: 1.75rem;
	}

	.h4-header-link {
		font-size: 1.25rem;
	}

	.inside-page-header-wrap {
		padding-left: 0;
		padding-right: 0;
		display: none;
	}

	.unordered-list {
		padding-left: 20px;
	}

	.h3-header-link {
		font-size: 1.5rem;
	}

	.sidebar-section-wrap {
		margin-left: 0;
	}

	.intro-paragraph-2,
	.h5-header-link {
		font-size: 1.125rem;
	}

	.inside-content-beckground {
		padding-bottom: 1.5rem;
		padding-left: 30px;
		padding-right: 30px;
	}

	.ordered-list-2 {
		padding-left: 20px;
	}

	.right-sidebar-column {
		margin-top: 1.25rem;
		padding-top: 1.25rem;
	}

	.quote-content {
		font-size: 1.125rem;
		line-height: 1.5rem;
	}

	.main-content-wrapper {
		padding-left: 0;
		padding-right: 0;
	}

	.heading-19 {
		font-size: 20px;
	}

	.mobile-site-links {
		border-top: 1px solid #82c50f;
		border-bottom: 1px solid #82c50f;
		width: 100%;
		margin-bottom: 16px;
		padding-top: 6px;
		padding-bottom: 6px;
		display: flex;
	}

	.ths02-side-nav-links-wrapper {
		width: 100%;
	}

	.faq-answer {
		padding-left: 16px;
	}

	.green-link-copy.on-footer-copy {
		padding-left: 0;
	}

	.burgersidemenu {
		justify-content: space-between;
		align-items: flex-start;
		max-width: 100%;
		padding-top: 0;
	}

	.ths02-side-nav-header {
		justify-content: flex-end;
		align-items: center;
		width: 100%;
		padding-top: 16px;
		padding-bottom: 16px;
	}

	.ths02-menu-bars-wrapper {
		padding: 12px 8px;
	}

	.default-view {
		justify-content: center;
		align-items: flex-start;
		width: 100%;
		display: flex;
	}

	.top {
		justify-content: space-between;
		align-items: stretch;
		width: 100%;
		display: flex;
	}

	.wrapper-2xs {
		margin-top: 8px;
		padding-bottom: 8px;
	}

	.toggle-button-fill-m.dark {
		z-index: 1;
		justify-content: flex-end;
		align-items: center;
		width: 61px;
		display: flex;
	}

	.button-toggle-m.dark {
		margin-left: 3px;
		margin-right: 3px;
	}

	.toggle-button-m-dark {
		background-color: #052200;
		border-radius: 28px;
		width: 61px;
	}

	.offtext,
	.ontext {
		z-index: 100;
		color: var(--white);
		margin-left: auto;
		margin-right: 8px;
		font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
		font-size: 12px;
		line-height: 13px;
		display: none;
		cursor: pointer;
	}

	.offtext {
		display: flex;
	}

	.toggle-button-m-dark.menu-on {
		background-color: rgb(130, 197, 15);
	}

	.toggle-button-m-dark.menu-on p.ontext {
		display: flex;
	}

	.toggle-button-m-dark.menu-on div.button-toggle-m.dark {
		transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
		transform-style: preserve-3d;
	}

	.toggle-button-m-dark.menu-on p.offtext {
		display: none;
	}
}

@media screen and (max-width: 479px) {
	.grip-logo-link {
		float: none;
	}

	.footer-signoff-link {
		font-size: 1rem;
	}

	.signoff-div {
		flex-flow: column;
		padding-left: 16px;
	}

	.column-2 {
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.footer-copyright {
		text-align: left;
		align-self: auto;
		margin-bottom: 1rem;
		font-size: 1rem;
	}

	.footer-signoff-link-wrap {
		text-align: left;
		flex-flow: wrap;
		justify-content: flex-start;
		align-items: flex-start;
		margin-bottom: 1rem;
		display: flex;
	}

	.grid-3 {
		grid-column-gap: 1rem;
		grid-row-gap: 1rem;
		grid-template-rows: auto auto;
		grid-template-columns: 1fr 1fr;
		justify-content: flex-start;
		align-items: center;
	}

	.footer-links.margin {
		flex-flow: column;
		justify-content: center;
		align-items: center;
	}

	.heading-4 {
		text-align: center;
		margin-left: auto;
		margin-right: auto;
	}

	.container-4 {
		max-width: 479px;
	}

	.container-4.nav-container {
		max-width: none;
		height: auto;
		overflow: visible;
	}

	.nav-menu-2 {
		width: 100%;
		height: 72vh;
		margin-top: 0;
		padding-right: 1px;
		overflow: auto;
	}

	.nav-menu-2._1.nav-menu-scrollable {
		width: 100%;
		margin-top: 24px;
		top: 56px;
	}

	.main-nav-link-2 {
		font-size: 0.9rem;
	}

	.top-half {
		justify-content: space-between;
		align-items: center;
		padding: 1rem 16px 1rem 32px;
	}

	.icon-column-wrap {
		width: auto;
		margin-right: 0.75rem;
		padding-top: 0;
		padding-bottom: 0.35rem;
	}

	.navbar-2 {
		padding-left: 0;
		padding-right: 0;
	}

	.columns {
		flex-flow: column;
		justify-content: center;
		align-items: center;
	}

	.footer-social-icons-links-wrap {
		justify-content: center;
	}

	.footer-social-icon-image.xlogo {
		padding-top: 8px;
		padding-bottom: 8px;
	}

	.content-wrap-stats {
		grid-row-gap: 6px;
	}

	.footer {
		display: none;
	}

	.footer.is-red.footer-links {
		padding-left: 16px;
		display: block;
	}

	.sticky_container {
		margin-right: 0;
		padding-left: 0;
	}

	.heading-h3.mb-8 {
		font-size: 1em;
	}

	.sticky_txt-container {
		grid-column-gap: 35vh;
		grid-row-gap: 35vh;
		width: 100%;
		padding-left: 16px;
		padding-right: 16px;
	}

	.heading-h2 {
		font-size: 1.8em;
	}

	.heading-h2.heading-h2--large {
		font-size: 2em;
	}

	.heading-h2.heading-h2--large.mb-8 {
		font-size: 30px;
		line-height: 1.3em;
	}

	.search-content {
		border-top: 1px solid #5b5b5b26;
		align-items: center;
		width: 100vw;
		height: auto;
		margin-top: 100px;
		margin-left: 0%;
		margin-right: 0;
		padding: 20px 1.5rem;
		display: flex;
		top: 0;
		left: auto;
		right: 0;
	}

	.search-input-3 {
		padding-left: 0;
	}

	.search-button-2 {
		padding: 0.65rem 0;
		font-size: 0.8rem;
	}

	.search-icon {
		margin-left: 10px;
		margin-right: 10px;
	}

	.search-icon.mobile {
		margin-left: auto;
		display: block;
	}

	.search-button-icon.open {
		right: 1px;
	}

	.chat-wrapper {
		width: 285px;
		margin-bottom: -32px;
		bottom: 9%;
	}

	.bubble-section {
		grid-column-gap: 16px;
		grid-row-gap: 16px;
		flex-flow: column;
		justify-content: flex-start;
		align-items: flex-end;
	}

	.chat-button {
		justify-content: center;
		align-items: center;
		width: auto;
		bottom: 4%;
		right: 4%;
	}

	.bold-text-4 {
		font-size: 35px;
		line-height: 1.5em;
	}

	.project-content {
		width: 80%;
	}

	.project-image {
		width: 80.5%;
		height: 180px;
	}

	.square-icon {
		border-radius: 14px;
		max-width: 56px;
	}

	.div-block-36 {
		padding: 10px;
	}

	.grid-4-columns {
		grid-template-columns: 1fr;
	}

	.mg-bottom-24px {
		margin-bottom: 16px;
	}

	._6-categories {
		margin-top: 0;
		padding-top: 0;
	}

	.steps-card-wrapper {
		grid-row-gap: 24px;
		grid-template-columns: auto;
	}

	.steps-grid {
		width: 100%;
		max-width: 100%;
		padding-left: 0;
	}

	.grid-wrapper {
		grid-template-columns: minmax(24px, 1fr) minmax(auto, 1400px) minmax(24px, 1fr);
	}

	.steps-tracker {
		display: none;
	}

	.steps-sticky-card {
		min-width: auto;
		padding: 12px 12px 24px;
	}

	.steps-cards {
		grid-template-columns: auto;
		width: 100%;
	}

	.steps-circle {
		display: none;
	}

	.steps-intro {
		grid-row-gap: 24px;
	}

	.steps-description {
		flex-flow: column;
		justify-content: flex-start;
		align-items: flex-start;
		padding-top: 0;
		display: flex;
	}

	.home-faq-list {
		padding-top: 0;
		padding-bottom: 0;
	}

	.home-faq-list.radius-regular {
		padding-bottom: 1rem;
	}

	.sectionwrap {
		padding-top: 0;
		padding-left: 0;
		padding-right: 0;
	}

	.sectionwrap.padding-large,
	.sectionwrap.padding-large.more {
		padding-top: 4rem;
	}

	.product_link {
		flex-direction: column;
		padding-top: 1.5rem;
		padding-bottom: 1.5rem;
	}

	.product_img {
		width: 100%;
	}

	.nav-link.mobile {
		margin-left: 0;
	}

	.text-size-xsmall-2._2 {
		white-space: normal;
	}

	.button-4 {
		white-space: nowrap;
	}

	.button-4.mobileversion {
		grid-column-gap: 16px;
		grid-row-gap: 16px;
		flex-flow: column;
		justify-content: space-between;
		align-items: flex-start;
	}

	.navwrapper {
		margin-bottom: 0;
	}

	.logoblock {
		width: 90%;
	}

	.logo-copy {
		width: auto;
		margin-right: auto;
	}

	.logotype_white {
		background-image: url('/20150924150955/assets/images/electrical-cooperation-logo-vertical-white.png');
		background-position: 0%;
		width: 100%;
		max-width: 195px;
	}

	.faq-question.special {
		padding-top: 10px;
		padding-bottom: 10px;
	}

	.loginsitewrap {
		justify-content: center;
	}

	.footerlogo {
		width: 165px;
		height: 85px;
	}

	.wraper {
		justify-content: flex-start;
		align-items: center;
		width: 100%;
		display: flex;
	}

	.footer-links-copy.main {
		justify-content: center;
		align-items: flex-start;
	}

	.div-block-38 {
		justify-content: center;
		align-self: flex-start;
		align-items: center;
		margin-left: auto;
		margin-right: auto;
	}

	.bold-text-6 {
		text-align: left;
	}

	.quicklinksmobile {
		display: none;
		top: 0;
	}

	.quicklinkwrapper {
		grid-column-gap: 16px;
		grid-row-gap: 16px;
		flex-flow: row;
		grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
		grid-template-columns: 1fr 1fr;
		grid-auto-columns: 1fr;
		width: 100%;
		margin-top: -5rem;
		margin-left: 0;
		margin-right: 0;
		padding: 16px;
		display: grid;
	}

	.heading-10 {
		margin-top: 0;
	}

	.qlinksclose {
		margin-top: -6px;
		padding-top: 0;
	}

	.new-ql-button {
		justify-content: flex-end;
		align-items: center;
	}

	.button-1_color-wrap-2 {
		width: auto;
		padding-left: 11px;
		padding-right: 11px;
	}

	.link-icon {
		margin-bottom: 0;
	}

	.logo-mobile-links {
		background-image: url('/20150924150955/assets/images/c4b6410f36dbd787228d606a641fae4222432bcfa2a9c9639ffb878ccd6ac78b.png');
		height: 85px;
	}

	.jobopening-card {
		flex-flow: column;
		min-width: auto;
		padding: 16px;
	}

	.jobcalloutboximg {
		width: 100%;
		height: 300px;
	}

	.jobcontent {
		grid-row-gap: 24px;
		width: 100%;
	}

	.buttonwrapper {
		grid-column-gap: 16px;
		grid-row-gap: 16px;
		padding-left: 16px;
		padding-right: 16px;
	}

	.advant__columns {
		grid-template-columns: 1fr;
	}

	.advant__img-wrap {
		margin-bottom: 16px;
	}

	.advant__heading {
		height: 70px;
		margin-top: 0;
	}

	.articleimg {
		margin-bottom: 16px;
	}

	.paragraph-12 {
		font-size: 0.9rem;
	}

	.block-quote-2 {
		background-position: 125% 100%;
		background-size: 80%;
	}

	.image-block {
		width: 100%;
		height: auto;
	}

	.image-block.inside-page {
		height: auto;
	}

	.intro-paragraph-2 {
		line-height: 1.5rem;
	}

	.heading-6 {
		font-size: 2.2rem;
		line-height: 3rem;
	}

	.inside-content-beckground {
		padding-left: 20px;
		padding-right: 20px;
	}

	.inner-image-content {
		background-position: 20%;
	}

	.inner-image-content.recent-blog-post {
		height: 250px;
	}

	.right-sidebar-column {
		margin-top: 0;
		padding-top: 0;
	}

	.button-styling {
		flex-wrap: wrap;
		padding-left: 0;
	}

	.mobile-site-links {
		justify-content: flex-start;
		align-items: center;
	}

	.text-block {
		font-size: 18px;
	}

	.faq-answer {
		object-fit: fill;
	}

	.burgersidemenu {
		width: 100%;
		min-width: auto;
		max-width: none;
		padding-left: 32px;
		padding-right: 32px;
	}

	.sidemenubtn {
		font-size: 18px;
	}

	.default-view {
		justify-content: center;
		align-items: flex-start;
		display: flex;
	}

	.toggle-button-m-dark {
		width: 65px;
	}

	.text.s-medium.toggle-margin-m.white {
		color: var(--black);
	}

	.div-block-39 {
		width: 100%;
	}
}

#w-node-_61f80552-3bcb-2da6-6e86-9ed66dc9c96f-80904c7a,
#w-node-_803f1a59-c88b-5a2c-7536-571644d5e617-80904c7a,
#w-node-_803f1a59-c88b-5a2c-7536-571644d5e621-80904c7a,
#w-node-_803f1a59-c88b-5a2c-7536-571644d5e62b-80904c7a,
#w-node-_803f1a59-c88b-5a2c-7536-571644d5e635-80904c7a {
	grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-e602d102-cb49-7e7c-b6e1-217e18824270-80904c7a {
	grid-area: 1 / 1 / 2 / 3;
}

#w-node-e602d102-cb49-7e7c-b6e1-217e18824271-80904c7a {
	grid-area: span 1 / span 1 / span 1 / span 1;
	place-self: start;
}

#w-node-e602d102-cb49-7e7c-b6e1-217e18824272-80904c7a,
#w-node-b4cbe2c7-8e36-169c-026b-2e665542b9bc-80904c7a {
	grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-e602d102-cb49-7e7c-b6e1-217e18824283-80904c7a {
	grid-area: span 1 / span 1 / span 1 / span 1;
	justify-self: start;
}

#w-node-e602d102-cb49-7e7c-b6e1-217e18824284-80904c7a {
	grid-area: 1 / 2 / 2 / 3;
}

#w-node-e602d102-cb49-7e7c-b6e1-217e18824287-80904c7a {
	place-self: center stretch;
}

#w-node-e602d102-cb49-7e7c-b6e1-217e18824288-80904c7a {
	grid-area: 1 / 1 / 3 / 2;
}

#w-node-e602d102-cb49-7e7c-b6e1-217e1882428a-80904c7a {
	grid-area: 2 / 2 / 3 / 3;
}

#w-node-e602d102-cb49-7e7c-b6e1-217e18824290-80904c7a {
	align-self: stretch;
}

#w-node-e602d102-cb49-7e7c-b6e1-217e18824291-80904c7a {
	place-self: start;
}

#w-node-e602d102-cb49-7e7c-b6e1-217e18824294-80904c7a {
	align-self: end;
}

#w-node-e602d102-cb49-7e7c-b6e1-217e1882429e-80904c7a {
	align-self: stretch;
}

#w-node-e602d102-cb49-7e7c-b6e1-217e1882429f-80904c7a {
	place-self: start;
}

#w-node-e602d102-cb49-7e7c-b6e1-217e188242a2-80904c7a {
	align-self: end;
}

#w-node-e602d102-cb49-7e7c-b6e1-217e188242ac-80904c7a {
	align-self: stretch;
}

#w-node-e602d102-cb49-7e7c-b6e1-217e188242ad-80904c7a {
	place-self: start;
}

#w-node-e602d102-cb49-7e7c-b6e1-217e188242b0-80904c7a {
	align-self: end;
}

#w-node-e602d102-cb49-7e7c-b6e1-217e188242ba-80904c7a {
	align-self: stretch;
}

#w-node-e602d102-cb49-7e7c-b6e1-217e188242bb-80904c7a {
	place-self: start;
}

#w-node-e602d102-cb49-7e7c-b6e1-217e188242be-80904c7a {
	align-self: end;
}

#w-node-_19c484d3-4fcd-431e-c459-f4c5b86d3b2a-80904c7a {
	align-self: stretch;
}

#w-node-_19c484d3-4fcd-431e-c459-f4c5b86d3b2b-80904c7a {
	place-self: start;
}

#w-node-_19c484d3-4fcd-431e-c459-f4c5b86d3b2e-80904c7a {
	align-self: end;
}

#w-node-_717f877e-587c-9d70-7908-779982691f63-80904c7a {
	align-self: stretch;
}

#w-node-_717f877e-587c-9d70-7908-779982691f64-80904c7a {
	place-self: start;
}

#w-node-_717f877e-587c-9d70-7908-779982691f67-80904c7a {
	align-self: end;
}

#w-node-e39a395c-64e4-db6b-6062-46400a889a30-80904c7a,
#w-node-aeca77ed-e0c0-b539-35ec-9252d357a6d3-80904c7a,
#w-node-c365410a-3472-da69-94a3-0edd328ec944-80904c7a {
	grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_96558a14-6ed7-eb73-e826-9b129a3f84b1-9a3f84ab {
	grid-area: span 1 / span 1 / span 1 / span 1;
	place-self: auto stretch;
}

#w-node-_96558a14-6ed7-eb73-e826-9b129a3f84bd-9a3f84ab {
	justify-self: start;
}

#w-node-_96558a14-6ed7-eb73-e826-9b129a3f84c6-9a3f84ab,
#w-node-_96558a14-6ed7-eb73-e826-9b129a3f84c7-9a3f84ab {
	grid-area: span 1 / span 1 / span 1 / span 1;
	justify-self: start;
}

#w-node-_22142533-e8e1-4fe9-06fd-e10548545d01-282dc028,
#w-node-_5a5f16a6-c9b2-03d0-6829-f255c5ae5455-c5ae5453 {
	grid-area: span 1 / span 1 / span 1 / span 1;
	place-self: center start;
}

#w-node-_61f80552-3bcb-2da6-6e86-9ed66dc9c96f-a3e6815e,
#w-node-_803f1a59-c88b-5a2c-7536-571644d5e617-a3e6815e,
#w-node-_803f1a59-c88b-5a2c-7536-571644d5e621-a3e6815e,
#w-node-_803f1a59-c88b-5a2c-7536-571644d5e62b-a3e6815e,
#w-node-_803f1a59-c88b-5a2c-7536-571644d5e635-a3e6815e {
	grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-e602d102-cb49-7e7c-b6e1-217e18824270-a3e6815e {
	grid-area: 1 / 1 / 2 / 3;
}

#w-node-e602d102-cb49-7e7c-b6e1-217e18824271-a3e6815e {
	grid-area: span 1 / span 1 / span 1 / span 1;
	place-self: start;
}

#w-node-e602d102-cb49-7e7c-b6e1-217e18824272-a3e6815e,
#w-node-b4cbe2c7-8e36-169c-026b-2e665542b9bc-a3e6815e {
	grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-e602d102-cb49-7e7c-b6e1-217e18824283-a3e6815e {
	grid-area: span 1 / span 1 / span 1 / span 1;
	justify-self: start;
}

#w-node-e602d102-cb49-7e7c-b6e1-217e18824284-a3e6815e {
	grid-area: 1 / 2 / 2 / 3;
}

#w-node-e602d102-cb49-7e7c-b6e1-217e18824287-a3e6815e {
	place-self: center stretch;
}

#w-node-e602d102-cb49-7e7c-b6e1-217e18824288-a3e6815e {
	grid-area: 1 / 1 / 3 / 2;
}

#w-node-e602d102-cb49-7e7c-b6e1-217e1882428a-a3e6815e {
	grid-area: 2 / 2 / 3 / 3;
}

#w-node-e602d102-cb49-7e7c-b6e1-217e18824290-a3e6815e {
	align-self: stretch;
}

#w-node-e602d102-cb49-7e7c-b6e1-217e18824291-a3e6815e {
	place-self: start;
}

#w-node-e602d102-cb49-7e7c-b6e1-217e18824294-a3e6815e {
	align-self: end;
}

#w-node-e602d102-cb49-7e7c-b6e1-217e1882429e-a3e6815e {
	align-self: stretch;
}

#w-node-e602d102-cb49-7e7c-b6e1-217e1882429f-a3e6815e {
	place-self: start;
}

#w-node-e602d102-cb49-7e7c-b6e1-217e188242a2-a3e6815e {
	align-self: end;
}

#w-node-e602d102-cb49-7e7c-b6e1-217e188242ac-a3e6815e {
	align-self: stretch;
}

#w-node-e602d102-cb49-7e7c-b6e1-217e188242ad-a3e6815e {
	place-self: start;
}

#w-node-e602d102-cb49-7e7c-b6e1-217e188242b0-a3e6815e {
	align-self: end;
}

#w-node-e602d102-cb49-7e7c-b6e1-217e188242ba-a3e6815e {
	align-self: stretch;
}

#w-node-e602d102-cb49-7e7c-b6e1-217e188242bb-a3e6815e {
	place-self: start;
}

#w-node-e602d102-cb49-7e7c-b6e1-217e188242be-a3e6815e {
	align-self: end;
}

#w-node-_19c484d3-4fcd-431e-c459-f4c5b86d3b2a-a3e6815e {
	align-self: stretch;
}

#w-node-_19c484d3-4fcd-431e-c459-f4c5b86d3b2b-a3e6815e {
	place-self: start;
}

#w-node-_19c484d3-4fcd-431e-c459-f4c5b86d3b2e-a3e6815e {
	align-self: end;
}

#w-node-_717f877e-587c-9d70-7908-779982691f63-a3e6815e {
	align-self: stretch;
}

#w-node-_717f877e-587c-9d70-7908-779982691f64-a3e6815e {
	place-self: start;
}

#w-node-_717f877e-587c-9d70-7908-779982691f67-a3e6815e {
	align-self: end;
}

#w-node-e39a395c-64e4-db6b-6062-46400a889a30-a3e6815e,
#w-node-aeca77ed-e0c0-b539-35ec-9252d357a6d3-a3e6815e,
#w-node-c365410a-3472-da69-94a3-0edd328ec944-a3e6815e {
	grid-area: span 1 / span 1 / span 1 / span 1;
}

@media screen and (max-width: 991px) {
	#w-node-e602d102-cb49-7e7c-b6e1-217e18824270-80904c7a {
		grid-area: 1 / 2 / 2 / 3;
	}

	#w-node-e602d102-cb49-7e7c-b6e1-217e18824271-80904c7a,
	#w-node-e602d102-cb49-7e7c-b6e1-217e18824283-80904c7a {
		order: 9999;
		grid-area: span 1 / span 1 / span 1 / span 1;
	}

	#w-node-e602d102-cb49-7e7c-b6e1-217e18824288-80904c7a {
		grid-row-end: 3;
	}

	#w-node-c365410a-3472-da69-94a3-0edd328ec934-80904c7a {
		order: 9999;
	}

	#w-node-e602d102-cb49-7e7c-b6e1-217e18824270-a3e6815e {
		grid-area: 1 / 2 / 2 / 3;
	}

	#w-node-e602d102-cb49-7e7c-b6e1-217e18824271-a3e6815e,
	#w-node-e602d102-cb49-7e7c-b6e1-217e18824283-a3e6815e {
		order: 9999;
		grid-area: span 1 / span 1 / span 1 / span 1;
	}

	#w-node-e602d102-cb49-7e7c-b6e1-217e18824288-a3e6815e {
		grid-row-end: 3;
	}

	#w-node-c365410a-3472-da69-94a3-0edd328ec934-a3e6815e {
		order: 9999;
	}
}

@media screen and (max-width: 767px) {
	#w-node-aeca77ed-e0c0-b539-35ec-9252d357a6d7-80904c7a,
	#w-node-aeca77ed-e0c0-b539-35ec-9252d357a6ec-80904c7a,
	#w-node-aeca77ed-e0c0-b539-35ec-9252d357a701-80904c7a,
	#w-node-aeca77ed-e0c0-b539-35ec-9252d357a716-80904c7a,
	#w-node-aeca77ed-e0c0-b539-35ec-9252d357a72b-80904c7a,
	#w-node-_1b22be24-3b95-b4cc-f308-8d3a3e7fc890-80904c7a,
	#w-node-aeca77ed-e0c0-b539-35ec-9252d357a6d7-a3e6815e,
	#w-node-aeca77ed-e0c0-b539-35ec-9252d357a6ec-a3e6815e,
	#w-node-aeca77ed-e0c0-b539-35ec-9252d357a701-a3e6815e,
	#w-node-aeca77ed-e0c0-b539-35ec-9252d357a716-a3e6815e,
	#w-node-aeca77ed-e0c0-b539-35ec-9252d357a72b-a3e6815e,
	#w-node-_1b22be24-3b95-b4cc-f308-8d3a3e7fc890-a3e6815e {
		grid-area: span 1 / span 2 / span 1 / span 2;
	}
}

@media screen and (max-width: 479px) {
	#w-node-_803f1a59-c88b-5a2c-7536-571644d5e61a-80904c7a,
	#w-node-_803f1a59-c88b-5a2c-7536-571644d5e624-80904c7a,
	#w-node-_803f1a59-c88b-5a2c-7536-571644d5e62e-80904c7a,
	#w-node-_803f1a59-c88b-5a2c-7536-571644d5e638-80904c7a,
	#w-node-e602d102-cb49-7e7c-b6e1-217e18824284-80904c7a,
	#w-node-e602d102-cb49-7e7c-b6e1-217e1882428a-80904c7a,
	#w-node-_1b22be24-3b95-b4cc-f308-8d3a3e7fc890-80904c7a,
	#w-node-_803f1a59-c88b-5a2c-7536-571644d5e61a-a3e6815e,
	#w-node-_803f1a59-c88b-5a2c-7536-571644d5e624-a3e6815e,
	#w-node-_803f1a59-c88b-5a2c-7536-571644d5e62e-a3e6815e,
	#w-node-_803f1a59-c88b-5a2c-7536-571644d5e638-a3e6815e,
	#w-node-e602d102-cb49-7e7c-b6e1-217e18824284-a3e6815e,
	#w-node-e602d102-cb49-7e7c-b6e1-217e1882428a-a3e6815e,
	#w-node-_1b22be24-3b95-b4cc-f308-8d3a3e7fc890-a3e6815e {
		grid-area: span 1 / span 1 / span 1 / span 1;
	}
}

/****************** Breakpoints ********************/
/****************************************************/
[data-breakpoints] {
	display: none !important;
}

@media screen and (min-width: 991px) {
	[data-breakpoints*="xl"] {
		display: block !important;
	}
}

@media screen and (min-width: 767px) {
	[data-breakpoints*="md,lg"] {
		display: block !important;
	}
}

[data-breakpoints*="xs,sm"] {
	display: block !important;
}

/***************** Print ***********************/
/*************************************************/
#printFooter {
	display: none;
	visibility: hidden;
}

#printHeader {
	display: none;
	visibility: hidden;
}

/*********************** Webflow **********************/
/*****************************************************/
.w-webflow-badge {
	display: none !important;
}

::-webkit-scrollbar {
	width: 8px;
}

::-webkit-scrollbar-thumb {
	background: #008457;
	border-radius: 10px;
}

::-webkit-scrollbar-track {
	background: rgba(0, 0, 0, 0);
	padding: 0px 2px;
}

.custom-scrollbar {
	border-top-right-radius: 8px !important;
	border-bottom-right-radius: 8px !important;
}

.custom-scrollbar::-webkit-scrollbar {
	width: 8px;
	padding: 0px 1px ;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
	background: rgba(0, 0, 0, 0);
	border-radius: 8px;
}

.custom-scrollbar::-webkit-scrollbar-track {
	background: #e5ecf4;
	padding: 0px 1px;
	border-radius: 0px 8px 8px 0px;
}

* {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/*********************** Header **********************/
/*****************************************************/
.on-footer-copy {
	color: var(--white);
	text-transform: capitalize;
	padding-top: 10px;
	padding-left: 10px;
	padding-right: 10px;
	font-size: 16px;
	font-weight: 500;
	text-decoration: none;
}

.on-footer-copy:hover {
	color: var(--white);
	text-decoration: underline;
}

.loginsitewrap {
	margin-bottom: 0px;
}

.body-home .top-half {
	width: 100%;
	height: 100px;
}

.loginsitewrap li {
	display: flex;
}

.loginsitewrap li a.on-footer-copy.active,
.loginsitewrap li a.on-footer-copy.active:hover {
	color: var(--light-green-iaec) !important;
}

.loginsitewrap li a.on-footer-copy.js-nav-log-in,
.loginsitewrap li a.on-footer-copy.js-nav-log-out {
	border: 1px solid var(--green-grey);
	background-color: var(--white);
	color: var(--black) !important;
	text-align: center;
	text-transform: capitalize;
	border-radius: 100rem;
	padding: 0.75rem 1.05rem 0.75rem 1.15rem;
	font-size: 1rem;
	font-weight: 500;
	text-decoration: none;
}

.loginsitewrap li a.on-footer-copy.js-nav-log-in:hover,
.loginsitewrap li a.on-footer-copy.js-nav-log-out:hover {
	background-color: var(--light-green-iaec);
	color: var(--black);
	transition: all 0.2s;
}

.search-form {
	width: 18%;
	margin-left: 15px;
	flex-grow: 1;
	min-width: 46px;
}

.show-on-tablet {
	display: none;
}

.video-play-icon {
	display: none;
}

.navbar-2.sticky .logo-copy {
	display: block !important;
}

.navbar-2.sticky .logotype_white {
	display: none !important;
}

.navbar-2.sticky .member-site-link.on-footer-copy {
	color: rgb(39, 39, 39) !important;
}

.navbar-2.sticky .loginsitewrap li a.on-footer-copy.active,
.navbar-2.sticky .loginsitewrap li a.on-footer-copy.active:hover {
	color: var(--light-green-iaec) !important;
}

.navbar-2.inside {
	animation: fadeIn ease 1s;
}

@keyframes fadeIn {
	0% {
		opacity: 0;
		transform: translate3d(0px, -100px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
	}

	100% {
		opacity: 1;
		transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
	}
}

@media (max-width: 991px) {
	.loginsitewrap li {
		display: none;
	}

	.loginsitewrap li:last-child {
		display: inline-block;
	}

	.search-form {
		margin-left: 62px;
		width: auto;
		min-width: unset;
	}

	.show-on-tablet {
		display: block;
	}
}

.navbar-2.sticky .logo-copy.mobile {
	display: none !important;
}

@media (max-width: 479px) {
	.navbar-2.sticky .logo-copy.mobile {
		display: inline-block !important;
	}

	.navbar-2.sticky .logo-copy.desktop {
		display: none !important;
	}
}

/***************** Accesible menu **********************/
/*****************************************************/
.container-4.nav-container {
	margin-bottom: 0px;
	list-style-type: none;
}

.dropdown-wrapper-3 {
	overflow: visible;
	position: relative;
}

.nav-link {
	margin-top: 0px;
	font-family: Jost;
}

.main-nav-link-2 {
	padding: 0.6rem 1.2vw;
}

.main-nav-link-2.w--current {
	color: #fff;
	background-color: #7dbd40;
}

.dropdown-wrapper-3:hover .ths05-dropdown-list {
	display: block;
	z-index: 10000;
	background-color: #fff0;
	width: auto;
	min-width: auto;
	max-width: 500px;
	padding-top: 20px;
}

.ths05-dropdown-links {
	list-style-type: none;
	margin: 0px;
}

.ths05-dropdown-link {
	font-family: Jost;
	white-space: normal;
}

.ths05-dropdown-link:hover {
	color: var(--dark-green-iaec);
	text-decoration: none;
	font-weight: 500;
}

.dropdown-wrapper-3 .ths05-dropdown-list ul.sub-list {
	display: none;
	position: absolute;
	margin: 0;
	list-style-type: none;
	left: 100%;
	background-color: #fff;
	top: 0;
}

.ths05-dropdown-links li {
	position: relative;
}

.dropdown-wrapper-3 .ths05-dropdown-links li:hover ul.sub-list {
	display: block;
	padding: 16px;
	width: 300px;
}

.landing-page .navbar-2 {
	background-color: #008457;
}

.landing-page {
	margin-top: 180px;
}

.landing-page .nav-menu-2._1.nav-menu-scrollable {
	background-color: var(--green-grey) !important;
}

.landing-page .nav-menu-2._1.nav-menu-scrollable .text-block-11 {
	color: rgb(39, 39, 39) !important;
}

.landing-page .nav-menu-2._1.nav-menu-scrollable .main-nav-link-2.w--current .text-block-11:hover {
	color: rgb(39, 39, 39) !important;
}

.landing-page .nav-menu-2._1.nav-menu-scrollable .text-block-11:hover {
	color: #fff !important;
}

#w-dropdown-list-5 .nav-dd-link.w--current {
	background-color: transparent;
	color: #000;
}

#w-dropdown-list-5 .nav-dd-link.w--current:hover {
	color: var(--dark-green-iaec);
	background-color: var(--green-grey);
}

@media (max-width: 767px) {
	.landing-page {
		margin-top: 160px;
	}
}

@media (max-width: 1240px) {
	.main-nav-link-2 {
		padding: 0.6rem 1.6vw;
	}

	.text-block-11 {
		margin: 0px;
	}
}

/****************** Mobile menu **********************/
/*****************************************************/
@media (min-width: 992px) {
	.mobile-navigation-menu {
		display: none !important;
	}

	.mobile-navigation-menu.mm-menu_opened {
		display: none !important;
	}

	.mm-page.mm-slideout {
		width: 100% !important;
		display: inherit !important;
	}
}

@media (max-width: 991px) {
	.mobile-navigation-menu {
		display: none !important;
	}

	.mobile-navigation-menu.mm-menu_offcanvas {
		display: none !important;
		position: fixed;
		left: auto;
		z-index: 999;
		transform: translate3d(100vw, 0, 0);
		transition: transform 0.8s ease;
	}

	.mm-wrapper_opening .mobile-navigation-menu.mm-menu_offcanvas {
		transform: translate3d(0, 0, 0);
		transition: transform 0.9s ease;
	}

	.mobile-navigation-menu.mm-menu_offcanvas.mm-menu_opened {
		display: block !important;
	}

	.mm-wrapper_opening .mm-menu_offcanvas.mm-menu_opened ~ .mm-slideout.mm-page {
		transform: none !important;
		background-color: transparent;
	}

	.mm-wrapper_opened .mm-page__blocker.mm-slideout {
		z-index: -1;
	}
}

@media screen and (min-width: 767px) {
	.mobile-navigation-menu li[data-breakpoints*="xl"] {
		display: flex !important;
	}
}

@media screen and (min-width: 767px) {
	.mobile-navigation-menu li[data-breakpoints*="md,lg"] {
		display: flex !important;
	}
}

.mobile-navigation-menu li[data-breakpoints] {
	display: none !important;
}

.mobile-navigation-menu li[data-breakpoints*="xs,sm"] {
	display: flex !important;
}

.mobile-navigation-menu > .mm-panels > .mm-panel:first-child > .mm-navbar {
	display: none;
}

.mobile-navigation-menu,
.mm-panels,
.mm-panels .mm-panel,
.mm-navbar {
	background-color: #008457;
	padding: 0;
	width: 100%;
}

.mm-panels > .mm-panel {
	border-top-right-radius: 8px !important;
	border-bottom-right-radius: 8px !important;
}

.mm-panels > .mm-panel::-webkit-scrollbar {
	width: 8px;
	padding: 0px 1px ;
}

.mm-panels > .mm-panel::-webkit-scrollbar-thumb {
	background: rgba(0, 0, 0, 0);
	border-radius: 8px;
}

.mm-panels > .mm-panel::-webkit-scrollbar-track {
	background: #005a3b;
	padding: 0px 1px;
	border-radius: 0px 8px 8px 0px;
}

.mobile-navigation-menu li {
	text-transform: capitalize;
	font-size: 24px;
	font-weight: 600;
	line-height: 40px;
	color: #fff;
	width: 100%;
	font-family: Jost, sans-serif;
}

.mm-listitem a:hover {
	color: #82c50f;
}

.mobile-navigation-menu li a {
	text-transform: capitalize;
	font-size: 24px;
	font-weight: 600;
	line-height: 40px;
	color: #fff;
	font-family: Jost, sans-serif;
}

.mobile-navigation-menu li a.nav-a {
	padding: 12px 20px;
}

.mobile-navigation-menu .w-nav-link {
	padding: 0px;
}

.mm-listitem_vertical > .mm-listitem__btn {
	height: auto;
}

.mm-listitem:after {
	border-color: transparent;
	left: 0;
}

.mm-listitem__btn {
	border-color: transparent;
}


.mm-navbar > * {
	justify-content: flex-start;
}

.mm-wrapper_opened .mm-wrapper__blocker.mm-slideout {
	z-index: 200;
}

.mm-wrapper_opening .mm-menu_position-right.mm-menu_opened ~ .mm-slideout {
	-webkit-transform: translate3d(0px, 0, 0) !important;
	transform: translate3d(0px, 0, 0);
}

.mm-wrapper_opening .mm-menu_position-right.mm-menu_opened ~ .mm-slideout.mm-wrapper__blocker {
	display: none;
}

.mm-listitem_selected > .mm-listitem__text,
.mm-listitem_opened > .mm-listitem__btn,
.mm-listitem_opened > .mm-panel {
	background: none;
}

.mm-listview {
	padding: 0 30px;
}

.mobile-navigation-menu .mm-listview li ul li a.nav-a {
	padding: 0px;
	color: #fff;
	text-transform: capitalize;
	font-size: 18px;
	text-decoration: none;
	line-height: 28px;
	font-weight: normal;
	padding-left: 10px;
}

.mobile-navigation-menu .mm-listview li ul li a.nav-a:hover {
	text-decoration: underline;
}

.mobile-navigation-menu .mm-listview li a.nav-a.active {
	color: #82c50f;
}

.mm-btn_next:after {
	border-color: #fff;
}

.mm-listview ul {
	padding: 0;
	padding-left: 20px;
}

.mm-btn_next:after {
	content: "";
	background-image: url('/assets/images/ic-plus.jpg');
	background-repeat: no-repeat;
	display: inline-block;
	height: 20px;
	width: 25px;
	border: 0px;
	transform: none;
	line-height: 10px;
	font-weight: normal;
	font-size: 30px;
	background-position: center right;
}

.mm-listitem_opened > .mm-listitem__btn:after {
	content: "";
	transform: none;
	right: 19px;
	background-image: url('/assets/images/ic-minus.jpg');
	background-repeat: no-repeat;
	background-position: center;
}

.mm-listitem__btn:not(.mm-listitem__text) {
	border-left-width: 0;
	border-left-style: solid;
}

.mobile-navigation-menu .extra-content {
	display: block;
}

.search.mobile.w-form {
	position: relative;
	margin-top: 15px;
}

.mobile-navigation-menu .extra-content .on-footer-copy {
	padding-top: 0px;
	padding-bottom: 0px;
}

.mobile-navigation-menu .extra-content .mobile-site-links {
	flex-wrap: wrap;
	padding: 0px;
}

.mobile-navigation-menu .extra-content .mobile-site-links .on-footer-copy {
	text-transform: capitalize;
	padding-top: 10px;
	padding-left: 10px;
	padding-right: 10px;
	font-size: 16px;
	font-weight: 500;
}

.mobile-navigation-menu .extra-content .mobile-site-links .on-footer-copy:hover {
	color: #fff;
	text-decoration: underline;
}

.mobile-navigation-menu .extra-content .mobile-site-links .on-footer-copy.active {
	color: #82c50f;
	text-decoration: underline;
}

/********************* Homepage *********************/
/*****************************************************/
.cbtext p {
	color: inherit;
	line-height: inherit;
	font-weight: inherit;
	font-family: inherit;
	font-size: inherit !important;
}

.cbtext p:last-child {
	margin-bottom: 0px;
}

.cb2,
.cb2 p {
	text-transform: uppercase;
}

.bold-text-4 {
	font-family: Jost, sans-serif;
	font-weight: bold;
}

.p.mod--advant {
	margin-bottom: 10px;
}

@media screen and (min-width: 992px) {
	.grid-4-columns {
		padding-left: 35px;
		padding-right: 35px;
	}
}

/*********************** Slideshow ********************/
/*****************************************************/
.slide-text p {
	font-size: inherit;
	line-height: inherit;
	font-family: inherit;
	font-weight: inherit;
	color: inherit;
}

.slide-text p:last-child {
	margin-bottom: 0px;
}

.body-display.large.cbtext h2 {
	margin-top: 0;
	margin-bottom: 15px;
	text-transform: uppercase;
}

.body-display.large.cbtext.cb24 h2 {
	text-transform: none;
}

/****************** Inside Page ******************/
/*************************************************/
@media screen and (max-width: 991px) {
	iframe[src*="youtube"] {
		aspect-ratio: 16 / 9;
		height: auto;
		max-width: 100%;
	}
}

@media (max-width: 767px) {
	.mobile-friendly-image {
		display: block;
		float: none !important;
	}
}

.breadcrumb-list li:last-child .breadcrumb-divider {
	display: none;
}

.breadcrumb-list li:last-child a:hover,
.breadcrumb-list li:last-child a {
	color: var(--dark-green-iaec);
	text-transform: capitalize;
	border-bottom: 0.5px solid #0000;
	font-weight: 700;
	text-decoration: none;
	display: inline;
}

.breadcrumb-list li:last-child a:hover {
	border-bottom-style: solid;
	border-bottom-color: #643572;
}

.only-left .left-nav-column {
	width: 345px;
	min-width: 345px;
}

ul ul ul .left-nav-nested-list-link.grandchild-nav-link {
	padding-left: 2.5rem;
	font-size: 0.9em;
}

.left-nav-list-link.active {
	color: var(--black);
	background-color: #ceddd4;
	text-decoration: none;
}

.left-nav-list-link.active.left-nav-link-with-children {
	border-radius: 10px 10px 0px 0px;
}

.left-nav-nested-list-link.grandchild-nav-link.active {
	background-color: #c3cdc7;
	border-radius: 0;
	font-weight: 200;
}

.main-content-column {
	flex-grow: 1;
}

.both-columns .main-content-column {
	min-width: 410px;
}

.both-columns .left-nav-column {
	max-width: 325px;
	min-width: 240px;
}

.both-columns .right-sidebar-column {
	max-width: 335px;
	min-width: 260px;
}

.no-columns.inside-content-beckground {
	max-width: 800px;
}

@media (max-width: 991px) {
	.main-content-section,
	.both-columns .right-sidebar-column {
		max-width: 100%;
	}
}

/************** Right Sidebar ********************/
/*************************************************/
.right-sidebar-column td img {
	max-width: none;
}

.right-sidebar-column .box {
	border-top: 1px #00000026;
	line-height: 1.35rem;
	color: #000;
	font-family: Montserrat, sans-serif;
	font-size: 16px;
	padding-left: 0px;
	padding-right: 0px;
	margin-bottom: 15px;
}

.right-sidebar-column .box.tools {
	padding: 17px;
}

.right-sidebar-column .box p {
	color: #000;
	font-family: Montserrat, sans-serif;
	font-size: 16px;
}

.right-sidebar-column .box h4,
.right-sidebar-column .box h3 {
	color: var(--darker-green);
	margin-top: 0;
	margin-bottom: 0.5rem;
	font-family: Jost, sans-serif;
	font-weight: 700;
	font-size: 18px;
	line-height: 20px;
}

.right-sidebar-column .box td {
	vertical-align: top;
	padding-bottom: 12px;
	font-family: Montserrat, sans-serif;
	font-size: 16px;
}

.right-sidebar-column .box .contentbox_item_image {
	width: 24px;
}

.right-sidebar-column .box.documents td:first-child {
	padding-right: 7px;
}

.right-sidebar-column .box.tools td:first-child img {
	margin-right: 5px;
}

.right-sidebar-column a {
	font-weight: 400;
	text-decoration: underline;
	color: var(--black);
	word-break: break-all;
	overflow-wrap: break-word;
}

.right-sidebar-column a:hover {
	color: var(--dark-green-iaec);
	text-decoration: none;
}

@media (max-width: 991px) {
	.right-sidebar-column .box {
		margin-left: 0px;
		margin-right: 0px;
	}
}

/*************** Left Sidebar ********************/
/************************************************/
.left-sidebar .box {
	border-top: 1px #00000026;
	line-height: 1.35rem;
	color: #fff;
}

.left-sidebar .box p {
	font-family: Montserrat, sans-serif;
	font-size: 16px;
	color: #fff;
}

.left-sidebar a {
	color: #fff;
	text-decoration: underline;
}

.left-sidebar a:hover {
	color: var(--light-green-iaec);
	text-decoration: none;
}

.left-sidebar h2 {
	color: #fff;
	margin-top: 0;
	margin-bottom: 0.5rem;
	font-weight: 700;
	font-size: 18px;
	line-height: 20px;
}

.breadcrumb-link-1.home-link {
	text-transform: uppercase;
}

/****************** Inside Page Styles ***************/
/*****************************************************/
.main-content-column a {
	color: var(--black);
	text-decoration: underline;
}

.main-content-column a:hover {
	color: var(--dark-green-iaec);
	text-decoration: none;
}

h1 {
	line-height: 38px;
	margin-top: 0;
	margin-bottom: 2rem;
	padding: 0;
	font-size: 2.75rem;
	color: #131313;
	font-family: Jost, sans-serif;
}

h2 {
	margin-bottom: 5px;
	font-size: 32px;
	font-weight: 700;
}

h3 {
	margin-bottom: 5px;
	font-size: 24px;
	font-weight: 700;
}

h4 {
	margin-bottom: 5px;
	font-size: 22px;
	font-weight: 700;
}

h5 {
	text-transform: capitalize;
	margin-bottom: 5px;
	font-size: 18px;
	font-weight: 700;
}

h6 {
	text-transform: capitalize;
	margin-bottom: 5px;
	font-size: 16px;
	font-weight: 700;
}

@media screen and (max-width: 479px) {
	h1 {
		font-size: 2.2rem;
		line-height: 3rem;
	}
}

h1 a {
	margin-bottom: 1rem;
	font-size: inherit;
	font-weight: 400;
	line-height: inherit;
	transition: all 0.2s;
	color: var(--black);
	text-decoration: underline;
}

h2 a {
	margin-bottom: 1rem;
	font-size: 1.8rem;
	font-weight: 400;
	line-height: 2.25rem;
	transition: all 0.2s;
	color: var(--black);
	text-decoration: underline;
}

h3 a {
	margin-bottom: 1rem;
	font-size: 24px;
	font-weight: 400;
	line-height: 30px;
	transition: all 0.2s;
	color: var(--black);
	text-decoration: underline;
}

h4 a {
	margin-bottom: 1rem;
	font-size: 22px;
	font-weight: 400;
	line-height: 24px;
	transition: all 0.2s;
	color: var(--black);
	text-decoration: underline;
}

h5 a {
	text-transform: capitalize;
	margin-bottom: 1rem;
	font-size: 18px;
	font-weight: 400;
	line-height: 20px;
	transition: all 0.2s;
	color: var(--black);
	text-decoration: underline;
}

h6 a {
	text-transform: capitalize;
	margin-bottom: 1rem;
	font-size: 16px;
	font-weight: 400;
	line-height: 18px;
	transition: all 0.2s;
	color: var(--black);
	text-decoration: underline;
}

h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover {
	color: var(--dark-green-iaec);
	text-decoration: none;
}

@media screen and (max-width: 767px) {
	h2 a {
		font-size: 1.75rem;
	}

	h3 a {
		font-size: 1.5rem;
	}

	h4 a {
		font-size: 1.25rem;
	}

	h5 a {
		font-size: 1.125rem;
	}
}

.main-content-column {
	font-size: 1rem;
}

.main-content-column p {
	margin-bottom: 1rem;
	padding-bottom: 0;
	font-size: 1rem;
	line-height: 1.25rem;
	text-decoration: none;
}

.main-content-column li {
	padding-bottom: 0;
	font-size: 1rem;
	line-height: 1.25rem;
	margin-bottom: 7px;
	text-decoration: none;
}

/* buttons */
.inside-row .button,
.inside-row a.primary,
.homeboxes a.primary,
.primary,
.inside-row button[type="submit"],
.inside-row .cms_form_button.primary,
#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button.primary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.primary,
.ui-form-buttons .ui-form-button button,
.module-search button {
	display: inline-block;
	border: 1px solid #008457;
	background-color: #008457;
	color: #fff;
	text-align: center;
	text-transform: capitalize;
	border-radius: 100rem;
	padding: 0.75rem 1.05rem 0.75rem 1.15rem;
	font-size: 1rem;
	font-weight: 500;
	text-decoration: none;
	transition: all 0.2s;
	font-family: Open Sans, sans-serif;
}

.inside-row .button:hover,
.inside-row a.primary:hover,
.homeboxes a.primary:hover,
.primary:hover,
.inside-row button[type="submit"]:hover,
.inside-row .cms_form_button.primary:hover,
#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button.primary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.primary:hover,
.ui-form-buttons .ui-form-button button:hover,
.module-search button:hover {
	color: #fff !important;
	border: 1px solid #005a3b;
	background-color: #005a3b !important;
}

.secondary,
.inside-row a.secondary,
.homeboxes a.secondary,
.inside-row button[type="submit"].secondary,
.cms_form_button.secondary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.secondary,
.inside-row .cms_form_button.secondary,
.inside-row .button.secondary,
#cookie-consent-form-container form .consent-footer .consent-form-dismiss-trigger {
	display: inline-block;
	border: 1px solid #e8edea;
	background-color: #e8edea;
	color: #000;
	text-align: center;
	text-transform: capitalize;
	border-radius: 100rem;
	padding: 0.75rem 1.05rem 0.75rem 1.15rem;
	font-size: 1rem;
	font-weight: 500;
	text-decoration: none;
	transition: all 0.2s;
	font-family: Open Sans, sans-serif;
}

.secondary:hover,
.inside-row a.secondary:hover,
.inside-row button[type="submit"].secondary:hover,
.cms_form_button.secondary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.secondary:hover,
.inside-row .cms_form_button.secondary:hover,
.inside-row .button:hover,
.homeboxes a.secondary:hover,
#cookie-consent-form-container form .consent-footer .consent-form-dismiss-trigger:hover {
	color: #fff !important;
	border: 1px solid #008457;
	background-color: #008457 !important;
}

.tertiary,
.inside-row button[type="submit"].tertiary,
.cms_form_button.tertiary,
.inside-row a.tertiary,
.homeboxes a.tertiary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.tertiary {
	display: inline-block;
	border: 1px solid #e8edea;
	background-color: #fff;
	color: #272727;
	text-align: center;
	text-transform: capitalize;
	border-radius: 100rem;
	padding: 0.75rem 1.05rem 0.75rem 1.15rem;
	font-size: 1rem;
	font-weight: 500;
	text-decoration: none;
	transition: all 0.2s;
	font-family: Open Sans, sans-serif;
}

.tertiary:hover,
.inside-row a.tertiary:hover,
.inside-row button[type="submit"].tertiary:hover,
.cms_form_button.tertiary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.tertiary:hover,
.homeboxes a.tertiary:hover {
	color: #000 !important;
	border: 1px solid #82c50f;
	background-color: #82c50f !important;
}

/* messages */
#message.success {
	background-color: #11b91e40;
	border-left: 6px solid #11b91e;
	font-size: 1.1rem;
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem 1rem 1rem 1.25rem;
	font-family: Quicksand, sans-serif;
	font-size: 1rem;
	font-weight: 500;
	line-height: 1.2rem;
	color: #333;
}

#message.error {
	margin-top: 1rem;
	background-color: #fd363626;
	border-left: 6px solid #c70616;
	font-size: 1.1rem;
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem 1rem 1rem 1.25rem;
	font-family: Quicksand, sans-serif;
	font-size: 1rem;
	font-weight: 500;
	line-height: 1.2rem;
	color: #333;
}

#message.success p {
	font-family: Quicksand, sans-serif;
	font-size: 1rem;
	font-weight: 500;
	line-height: 1.2rem;
	color: #333;
}

#message.error p {
	font-family: Quicksand, sans-serif;
	font-size: 1rem;
	font-weight: 500;
	line-height: 1.2rem;
	color: #333;
}

#message.error p:last-child,
#message.success p:last-child {
	margin-bottom: 0;
}

/* tables */
table.styled {
	margin: 0 0 1em;
	border-spacing: 0px;
}

table.styled th,
table.styled td {
	border-right: 1px solid #fff;
}

table.styled tr th:last-child,
table.styled tr td:last-child {
	border-right: none;
}

table.styled thead tr th {
	font-weight: inherit;
	font-size: 1rem;
	line-height: 1.2rem;
	padding: 0.625rem 0.625rem;
	text-align: left;
	background-color: #005a3b;
	opacity: 100%;
	color: white;
	font-family: Montserrat, sans-serif;
}

table.styled th {
	font-weight: inherit;
	font-size: 1rem;
	line-height: 1.2rem;
	padding: 0.5rem 0.625rem;
	text-align: left;
	background-color: #008457;
	opacity: 100%;
	color: white;
	font-family: Montserrat, sans-serif;
}

table.styled th a,
table.styled th a:visited {
	color: inherit;
	font-weight: inherit;
	text-decoration: underline;
	border-bottom: 0px;
	background-color: transparent;
	transition: none;
}

table.styled th a:hover {
	color: inherit;
	text-decoration: none;
}

table.styled td {
	padding: 0.375rem 0.625rem;
	font-size: 0.96rem;
	line-height: 1.4rem;
	color: #131313;
	font-family: Montserrat, sans-serif;
	font-weight: 400;
}

table.styled.striped tr:nth-child(even) {
	background: #e8edea;
}

table.styled.striped tr:nth-child(odd) {
	background: white;
}

.gr-form {
	background-color: #fff;
}

table.styled tbody tr {
	border-bottom: 1px solid #fff;
}

hr {
	color: #003c43;
	background-color: #5b5b5b26;
	height: 1px;
	margin-top: 1rem;
	margin-bottom: 1rem;
	border: 0px;
}

/* forms */
.inside-row input[type="text"],
.inside-row input[type="tel"],
.inside-row input[type="password"],
.inside-row input[type="email"],
.inside-row select,
.inside-row textarea,
.inside-row input[type="search"] {
	max-width: 100%;
	vertical-align: middle;
	padding: 8px 10px;
	border-style: solid;
	border-width: 1px;
	border-color: #333;
	font-size: 1rem;
	margin-bottom: 10px;
	background-color: #fff;
}

.inside-row textarea {
	resize: vertical;
}

.inside-row label {
	font-weight: 400;
}

.inside-row option {
	font-weight: 600;
}

@media (max-width: 1149px) {
	.inside-row input[type="text"],
	.inside-row input[type="tel"],
	.inside-row input[type="password"],
	.inside-row input[type="email"],
	.inside-row select,
	.inside-row textarea,
	.inside-row input[type="search"] {
		width: 100%;
	}
}

/*blockquotes*/
blockquote {
	background-color: var(--dark-green-iaec);
	color: var(--white);
	background-image: url('/20150924150955/assets/images/reverse-iowarec-white-15-opacity.svg');
	background-position: 150% -10%;
	background-repeat: no-repeat;
	background-size: 75%;
	border-radius: 20px;
	flex-flow: column;
	margin-top: 32px;
	margin-bottom: 32px;
	padding: 32px;
	font-size: 1.3rem;
	font-style: italic;
	line-height: 1.75rem;
	font-weight: 400;
	border: 0px;
}

.grip-grid > div {
	background-color: transparent !important;
}

blockquote p {
	font-size: inherit !important;
	line-height: inherit !important;
	font-family: inherit !important;
	color: inherit !important;
	font-weight: inherit !important;
}

blockquote p:last-child {
	margin-bottom: 0px;
}

@media screen and (max-width: 767px) {
	blockquote {
		font-size: 1.125rem;
		line-height: 1.5rem;
	}
}

/********************** Footer **********************/
/****************************************************/
.phone-desktop {
	display: inline-block;
}

.phone-mobile {
	display: none;
	color: #fff;
	text-decoration: none;
	border-bottom: 1px solid transparent;
}

.footer-list {
	list-style-type: none;
	margin: 0px;
	padding: 0px;
}

.footer-list li {
	margin: 0px;
	padding: 0px;
}

.footer-list li:last-child a {
	margin-bottom: 0px;
}

#GRIPFooterLogo {
	padding-top: 0px !important;
	line-height: 11px;
}

@media screen and (max-width: 991px) {
	.phone-desktop {
		display: none;
	}

	.phone-mobile {
		display: inline-block;
	}

	#GRIPFooterLogo {
		margin-bottom: 1rem;
	}
}

@media screen and (max-width: 767px) {
	.footer-list li:last-child a {
		margin-bottom: 0px;
	}
}

/****************** Content Modules ****************/
/***************************************************/
#events_module .list-view-link-wcag {
	position: absolute;
	left: -10000px;
	top: auto;
	width: 1px;
	height: 1px;
	overflow: hidden;
}

/* ui form tags */
.ui-form-container {
	border: 0px;
}

.ui-form-container .ui-form-legend {
	background: #fff;
	margin-top: 1rem;
	margin-bottom: 0.5rem;
	color: #333;
	font-size: 1rem;
	line-height: 1.3rem;
	font-weight: bold;
	text-transform: none;
	padding: 0px;
	border-bottom: 0px;
}

.ui-form-fieldset .ui-form-legend {
	text-transform: none;
}

.ui-form-container .ui-form-fields {
	border-bottom: 0px;
}

.ui-form-container .ui-form-fields .ui-form-field.required .ui-form-label em,
.ui-form-container .required_information em {
	color: #ee0000;
	font-family: inherit;
}

.ui-form-container .ui-form-fields .select2-container-multi .select2-choices {
	border: 1px solid #cccccc;
	border-radius: 0px;
	padding: 8px 12px;
	box-shadow: none;
}

.ui-form-container .ui-form-fields {
	padding: 0 0 0 200px;
}

.ui-form-container .ui-form-label {
	left: -210px;
	width: 200px;
}

.ui-form-container .ui-form-fields .select2-container-multi .select2-choices .select2-search-field .select2-input {
	padding: 0px;
}

.ui-form-container .ui-form-fields .select2-container .select2-choice {
	box-shadow: none;
	border-radius: 0px;
	background: none;
	border: 1px solid #ccc;
	height: 38px !important;
}

.ui-form-buttons {
	padding: 0px;
	width: 100%;
	text-align: center;
}

.ui-form-container .ui-form-fields .ui-form-error em {
	color: #ee0000;
	display: block;
	font-size: 0.9rem;
	font-style: normal;
	line-height: normal;
	font-weight: 500;
	margin: 0px 0px 0px 0px;
}

.ui-element-with-example .source-code {
	overflow: hidden;
}

@media (max-width: 991px) {
	.ui-form-container .ui-form-fields {
		padding: 0px;
	}

	.ui-form-container .ui-form-label {
		position: static;
		padding: 0px;
		height: auto !important;
		display: block;
		width: 100%;
		left: 0px;
		text-align: left;
		top: auto;
		line-height: inherit;
	}

	.ui-form-container .ui-form-input {
		width: 100%;
	}

	.ui-form-container .ui-form-input input[type="text"],
	.ui-form-container .ui-form-input input[type="tel"],
	.ui-form-container .ui-form-input input[type="password"],
	.ui-form-container .ui-form-input input[type="email"],
	.ui-form-container .ui-form-input input[type="file"],
	.ui-form-container .ui-form-input select,
	.ui-form-container .ui-form-input textarea,
	.ui-form-container .ui-form-fields .select2-container,
	.ui-form-container .ui-form-fields .select2-container-multi,
	.ui-form-container .ui-form-fields .select2-container-multi .select2-choices .select2-search-field .select2-input {
		width: 100% !important;
		max-width: 100%;
	}
}

@media (max-width: 479px) {
	.ui-form-buttons button {
		margin-bottom: 10px;
	}
}

#ui-datepicker-div {
	font-size: 0.9rem;
}

/* search */
.search-collection > .search-node {
	border-top: 1px solid rgba(92, 92, 92, 0.15);
	margin: 1.5rem 0 0 0;
	padding: 1.5rem 0 0 0;
}

@media (max-width: 991px) {
	.module-search button {
		margin-top: 10px;
	}
}

/* accounts */
.login-row__login #account_module.form .form_item .label,
.login-row__login #account_module.form .form_item.required .label {
	width: auto;
}

#account_module.form .form_container .login_field {
	width: 100%;
	max-width: 243px;
	margin-bottom: 15px;
}

#account_module.form .more_options ul {
	width: 100%;
	text-align: center;
	padding-left: 0;
}

#account_module.form .form_subheading {
	border-bottom: 1px solid rgba(92, 92, 92, 0.15);
	margin: 20px 0;
	padding-bottom: 4px;
	color: #333333;
	font-weight: bold;
}

#account_module.form .ui-form-fields {
	margin-bottom: 10px;
}

#account_module.form .ui-form-label {
	margin-bottom: 3px;
}

#account_module.form .ui-form-label em {
	color: #ee0000;
}

.login-row {
	width: 80%;
}

.login-row__login.have-account-column {
	width: 90%;
}

@media (min-width: 992px) {
	#account_module.form .required_message {
		margin-right: 120px;
	}
}

@media (max-width: 991px) {
	#account_module.form .form_buttons {
		text-align: left;
	}

	#account_module.form tr.required .field {
		background: none;
		padding-left: 0;
		display: block;
		width: 100%;
	}

	#account_module.form .form_item .label,
	#account_module.form .form_item.required .label {
		display: inline-block;
		width: 100%;
		text-align: left;
		padding-bottom: 5px;
	}

	#account_module.form .form_item .label label,
	#account_module.form .form_item.required .label label {
		display: inline;
	}

	#account_module.form tr.required .label:after {
		content: url("/core/public/shared/assets/images/required.gif");
		padding-left: 10px;
	}

	#account_module.form .form_item .field {
		display: block;
		padding-left: 0;
	}

	#account_module.form .form_item .field .login_field {
		max-width: 100%;
	}

	#account_module.form .more_options ul {
		text-align: left;
	}
}

@media (max-width: 479px) {
	#account_module.form .ui-form-fields input[type="text"],
	#account_module.form .ui-form-fields select {
		width: 100% !important;
	}
}

/* submission forms */
#submissionforms_module {
	text-align: left;
}

#submissionforms_module.cms_form .required_information em {
	color: #ee0000;
	font-family: inherit;
}

#submissionforms_module.cms_form .cms_required em {
	color: #ee0000;
	font-family: inherit;
}

#submissionforms_module.cms_form .cms_steps_wrapper .cms_step span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_first span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_last span {
	padding-top: 2px;
	font-weight: normal;
	font-size: 0.9rem;
}

#submissionforms_module.cms_form .cms_steps_wrapper .cms_step span a,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_first span a,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_last span a {
	border-bottom: 0px;
}

#submissionforms_module.cms_form .cms_label div {
	display: inline;
}

#submissionforms_module.cms_form .sigPad input {
	padding: 8px 12px;
}

#submissionforms_module.cms_form .cms_field {
	padding-bottom: 15px;
}

#submissionforms_module.cms_form .checkbox_wrapper .item label,
#submissionforms_module.cms_form .radio_wrapper .item label {
	margin: 0px;
	font-size: 0.9rem;
	line-height: normal;
}

#submissionforms_module.cms_form .cms_hint,
#submissionforms_module.cms_form .cms_date_hint,
#submissionforms_module.cms_form .agreement_hint,
#submissionforms_module.cms_form .cms_field_note,
#submissionforms_module.cms_form input[type="file"],
#submissionforms_module.homepage_contentbox .cms_date_hint,
#submissionforms_module.homepage_contentbox .agreement_hint {
	font-size: 0.8rem;
	color: inherit;
}

#submissionforms_module.cms_form .signature_name {
	display: block;
}

#submissionforms_module.cms_form .sigWrapper.current {
	display: block !important;
}

#submissionforms_module.cms_form .sigPad input,
#submissionforms_module.cms_form .sigWrapper.current {
	border-color: #666;
}

#submissionforms_module.cms_form .cms_label {
	width: 30% !important;
}

@media (max-width: 767px) {
	#submissionforms_module.cms_form .cms_label {
		width: 100% !important;
	}
}

/* classifieds */
.classifieds-collection .classifieds-node .classifieds-field h2 {
	margin-top: 0px;
}

.classifieds-collection .classifieds-node .classifieds-field:last-child p {
	margin-bottom: 0px;
}

.classifieds-collection > .classifieds-node {
	border-top: 1px solid hsla(0, 0%, 66%, 0.35);
	margin: 1.5rem 0 0 0;
	padding: 1.3rem 0 0 0;
}

/* events */
#fullcalendar-controls #categories .category-wrapper .category {
	top: 0px;
}

#events_module.cms_list .cms_list_item .cms_title {
	width: 100%;
}

#events_module.cms_list .cms_list_item .cms_title h3 {
	margin-top: 0px;
}

#events_module.cms_list .cms_list_item .event-category-label {
	display: inline-block;
	margin-right: 4px;
}

#events_module.cms_list .cms_divider {
	border-top: 1px solid rgba(92, 92, 92, 0.15);
	margin-bottom: 1.5rem;
	margin-top: 1.5rem;
}


#events_module.cms_form .cms_label,
#events_module.cms_form .cms_field,
#events_module.cms_form .cms_required,
#events_module.cms_form .cms_non_required,
#events_module.cms_form .cms_td_answers {
	background-color: transparent;
	vertical-align: top;
}

#events_module.cms_form .cms_required {
	background-position: center 10px;
}

#events_module.cms_form h3 {
	margin-bottom: 0px;
}

#events_module.cms_form .cms_divider {
	margin-top: 5px !important;
	padding-top: 1px !important;
}

.fc-button-prev .fc-text-arrow,
.fc-button-next .fc-text-arrow,
.fc-grid .fc-event-time {
	font-weight: normal;
}

@media (max-width: 991px) {
	#events_module.cms_form .cms_field input[type="text"],
	#events_module.cms_form .cms_field input[type="password"],
	#events_module.cms_form .cms_field select,
	#events_module.cms_form .cms_field textarea,
	#events_module.cms_form .cms_field {
		display: block;
		clear: both;
		width: 100% !important;
		max-width: 100%;
	}

	#events_module.cms_form .cms_label {
		display: inline;
		text-align: left;
		padding-left: 0px;
		width: auto;
		float: left;
	}

	#events_module.cms_form .cms_required,
	#events_module.cms_form .cms_non_required {
		width: 20px;
		display: inline;
		text-align: left;
		padding-left: 0px;
		float: left;
	}
}

/* faqs */
#faqs_module .cms_title h3 {
	margin-top: 0px;
}

#faqs_module .cms_list_item ul {
	padding-left: 0px;
	margin: 0px;
}

#faqs_module .cms_list_item ul li {
	padding-bottom: 7px;
}

#faqs_module.cms_list .cms_list_subitem {
	padding-bottom: 5px;
}

#faqs_module.cms_list h2 {
	font-size: 1.8rem;
}

#faqs_module.cms_list .cms_item_divider,
#faqs_module.cms_entity .cms_item_divider {
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
	border-top: 1px solid rgba(92, 92, 92, 0.15);
}

/* files */
.collection--files .node--file,
.collection--categories .node--category,
.collection.collection--files {
	margin: 1.5rem 0px 0px 0px;
	padding: 1.5rem 0px 0px 0px;
	border-top: 1px solid rgba(92, 92, 92, 0.15);
}

.collection--files .node--file p,
.collection--categories .node--category p,
.collection.collection--files p {
	margin-bottom: 0px;
	margin-top: 0px;
}

.collection.collection--files .bookmark.create {
	background-color: transparent;
	color: #6b7571;
	font-weight: 400;
}

.collection.collection--files .field--file--title a,
.collection.collection--categories .field--category--title a {
	padding-left: 0px;
	margin-left: 0px;
	font-size: 1.2rem;
}

.collection.collection--files .field--file--title,
.collection.collection--files .field--category--title {
	background-position: 0 3px;
}

.right-sidebar-column .box.filter .filter-row .select2-container,
.right-sidebar-column .box.filter .filter-row #filter-keywords {
	max-width: 100%;
	width: 100% !important;
}

.right-sidebar-column .box.filter .filter-row.ui-form-buttons {
	padding: 0px;
	display: flex;
	align-items: center;
}

.right-sidebar-column .box.filter .filter-row.ui-form-buttons .ui-form-field {
	padding: 6px;
	text-align: left;
}

.right-sidebar-column .box.filter .filter-row .select2-container-multi .select2-choices,
.right-sidebar-column .box.filter .filter-row .select2-container .select2-choice {
	border-radius: 0px;
	box-shadow: none;
	border: 1px solid #666666;
	background: none;
	padding: 8px 12px;
	height: auto;
}

.right-sidebar-column .box.filter .filter-row .select2-container .select2-choice > .select2-chosen {
	line-height: 1.2rem;
}

.right-sidebar-column .box.filter .filter-row .select2-container-multi .select2-choices .select2-search-field .select2-input {
	margin: 0;
	padding: 0px;
}

.right-sidebar-column .box.filter .filter-row .select2-container-multi .select2-choices .select2-search-field input {
	border: 1px solid #fff;
	outline: 1px solid #fff !important;
}

.right-sidebar-column .box.filter .filter-row .select2-container-multi .select2-choices .select2-search-field input:focus {
	border: 1px solid #fff;
	outline: 1px solid #fff !important;
}

/* jobs */
#jobs_module.cms_list .cms_list_item {
	margin-top: 0px;
}

#jobs_module.cms_list .cms_list_item .cms_title h3,
#jobs_module.cms_list .cms_list_item .cms_date h3 {
	margin-top: 0px;
	margin-bottom: 5px;
	font-size: 1rem;
}

#jobs_module.cms_list .cms_list_item .cms_footer {
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
	border-color: rgba(92, 92, 92, 0.15);
}

#jobs_module.cms_entity .cms_content dt {
	font-weight: bold;
}

#jobs_module.cms_entity h2 {
	font-size: 1.8rem;
}

#jobs_module.cms_form .cms_label,
#jobs_module.cms_form .cms_field,
#jobs_module.cms_form .cms_required {
	background: none;
	font-weight: normal !important;
	vertical-align: top;
}

#jobs_module.cms_form .cms_divider {
	height: 0px !important;
}

#jobs_module.cms_form .cms_form_button {
	padding: 0.75rem 1.25rem;
	margin-top: 10px;
}

@media (max-width: 991px) {
	#jobs_module.cms_form .cms_field input[type="text"],
	#jobs_module.cms_form .cms_field {
		display: block;
		clear: both;
		width: 100% !important;
		max-width: 100%;
	}

	#jobs_module.cms_form .cms_label,
	#jobs_module.cms_form .cms_required {
		display: inline;
		text-align: left;
		padding-left: 0px;
		width: auto;
		float: left;
		padding-top: 7px;
	}

	#jobs_module.cms_form .cms_required {
		width: 20px;
	}
}

/* links */
#links_module li {
	margin-bottom: 1rem;
}

/* news */
#news_module.cms_list .cms_item_divider,
#news_module.cms_entity .cms_item_divider {
	margin-top: 1.5rem;
	margin-bottom: 1rem;
	border-top: 1px solid rgba(92, 92, 92, 0.15);
}

#news_module.cms_entity .cms_date h3 {
	margin-top: 0px;
}

#news_module.cms_list .cms_categories_row .image {
	margin-bottom: 10px;
}

.news_module.cms_category_list .cms_category_header_image {
	margin-bottom: 10px;
}

.box.archive .archive_links {
	overflow: hidden;
}

#news_module.cms_entity .cms_content .image {
	height: auto;
	width: 250px;
}

@media (max-width: 767px) {
	#news_module.cms_entity .cms_content .image {
		display: block;
		float: none;
		margin-left: 0px;
		height: auto;
		margin-bottom: 15px;
		margin-top: 15px;
	}
}

/* newsletter */
#newsletters_module.entity ul {
	margin: 0px;
	padding: 0px;
	list-style-type: none;
}

#newsletters_module.entity h3,
#newsletters_module.entity h4 {
	margin-top: 0px;
	margin-bottom: 10px;
	font-size: 18px;
}

#newsletters_module.form .form_item .field {
	padding-bottom: 15px;
}

#newsletters_module.list .item_divider {
	border-top: 1px solid rgba(92, 92, 92, 0.15);
	margin: 1.5rem 0px 1.5rem 0px;
}

#newsletters_module.entity .item_divider {
	margin: 20px 0 10px 0px;
}

@media (max-width: 991px) {
	#newsletters_module.form .form_item .label,
	#newsletters_module.form .form_item.required .label {
		width: auto;
		display: inline-block;
		text-align: left;
		padding-bottom: 5px;
	}

	#newsletters_module.form .form_item.required .label {
		background-image: url('/core/public/shared/assets/images/required.gif');
		background-repeat: no-repeat;
		background-position: right center;
		padding-right: 20px;
	}

	#newsletters_module.form .form_item .label label {
		display: inline-block;
	}

	#newsletters_module.form .form_item .field {
		width: 100%;
		display: block;
		padding-left: 0;
	}

	#newsletters_module.form .form_item.required .field {
		background: transparent none;
	}

	#newsletters_module.form .form_item .field table tr td {
		vertical-align: top;
	}

	#newsletters_module.form .form_buttons {
		text-align: left;
	}

	#newsletters_module.form .form_item input[type="text"] {
		width: 100% !important;
	}
}

/* photo album */
#photoalbums_module.cms_list .cms_list_item table td.cms_image {
	padding-right: 15px;
}

#photoalbums_module.cms_list .cms_list_item table td.cms_image a img {
	max-width: 150px;
}

#photoalbums_module.cms_list .cms_item_divider {
	border-top: 1px solid rgba(92, 92, 92, 0.15);
	margin-bottom: 1.5rem;
	margin-top: 1.5rem;
}

#photoalbums_module.cms_entity .slideshow-wrapper #main-slideshow .slide-caption {
	background-color: #666;
	font-family: inherit;
	color: #ffffff;
}

@media (max-width: 991px) {
	#photoalbums_module.cms_list .cms_list_item table td {
		display: block;
		width: 100%;
	}

	#photoalbums_module.cms_list .cms_list_item table td.cms_image {
		text-align: left;
		width: 100%;
		margin-bottom: 10px;
		padding-right: 0px;
	}
}

/* resources */
.right-sidebar-column .box .moduleDocuments-rss-feed a {
	border-bottom: 0px;
}

/* rss */
#feeds_module.cms_list .cms_title h3,
#feeds_module.cms_list .cms_date h4 {
	margin-top: 0px;
	font-size: 18px;
}

#feeds_module.cms_list .abstract {
	padding: 0px;
}

#feeds_module.cms_list .cms_item_divider {
	border-top: 1px solid rgba(92, 92, 92, 0.15);
	margin-bottom: 1.5rem;
	margin-top: 1.5rem;
}

/* staff */
#staffdirectory_module.cms_list .cms_list_item .image {
	padding-right: 15px;
	width: 150px;
}

#staffdirectory_module.cms_list .cms_list_item .image a img {
	max-width: 140px;
}

#staffdirectory_module.cms_list .cms_item_divider {
	border-top: 1px solid rgba(92, 92, 92, 0.15);
	margin-bottom: 1.5rem;
	margin-top: 1.5rem;
}

#staffdirectory_module.cms_list .cms_list_item .title_header {
	font-size: 1.8rem;
}

@media (max-width: 991px) {
	#staffdirectory_module.cms_list .cms_list_item .image {
		text-align: center;
		width: 100%;
		margin-bottom: 10px;
		padding-right: 0px;
	}

	#staffdirectory_module.cms_entity .image {
		float: none;
		margin-right: 0px;
		display: block;
	}
}

/* success stories */
#stories_module.cms_list .cms_list_item .cms_title h3 {
	margin-top: 0px;
	margin-bottom: 0.7rem;
}

#stories_module.cms_list .cms_list_item .image {
	max-width: 200px;
}

#stories_module.cms_list .cms_item_divider,
#stories_module.cms_entity .cms_item_divider {
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
	border-top: 1px solid rgba(92, 92, 92, 0.15);
}

@media (max-width: 991px) {
	#stories_module.cms_list .cms_list_item .image {
		padding: 0px 0px 10px 0px;
		display: block;
		float: none;
	}
}

/* testimonials */
#testimonials_module.cms_list .cms_list_item .cms_bottom,
#testimonials_module.cms_list .cms_list_item .cms_content {
	display: inline-block;
	width: 100%;
}

#testimonials_module.cms_list .cms_item_divider,
#testimonials_module.cms_entity .cms_item_divider {
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
	border-top: 1px solid rgba(92, 92, 92, 0.15);
}

/* videos */
#video_module.cms_list .cms_list_item {
	border-bottom: 1px solid rgba(92, 92, 92, 0.15);
	padding-bottom: 1.5rem;
	margin-bottom: 1.5rem;
}

#video_module.cms_list_carousel .video-container .video-file-youtube iframe,
#video_module.cms_list_carousel .video-container .video-file .cms_media_container,
#video_module.cms_list_carousel .video-container .video-file video {
	background-color: #000;
	font-family: inherit;
	color: #fff;
}

#video_module.cms_list_carousel .video-container .owl-carousel .owl-nav .owl-prev,
#video_module.cms_list_carousel .video-container .owl-carousel .owl-nav .owl-next,
#video_module.cms_list_carousel .video-container .owl-carousel .owl-nav .owl-prev span,
#video_module.cms_list_carousel .video-container .owl-carousel .owl-nav .owl-next span {
	background-color: #fff;
	font-family: inherit;
	color: #000;
}

/* white papres */
#whitepapers_module.cms_list .cms_list_item .cms_title h3 {
	margin-top: 0px;
}

#whitepapers_module.cms_list .cms_item_divider,
#whitepapers_module.cms_entity .cms_item_divider {
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
	border-top: 1px solid rgba(92, 92, 92, 0.15);
}

@media (max-width: 991px) {
	#whitepapers_module.cms_list .cms_list_item .image {
		padding: 0px 0px 10px 0px;
		display: block;
		float: none;
	}
}

/****************** Content Boxes ******************/
/***************************************************/
/* submission forms */
#submissionforms_module.homepage_contentbox {
	text-align: left;
	line-height: 1.5rem;
	font-size: 1rem;
}

#submissionforms_module.homepage_contentbox table {
	width: auto;
}

#submissionforms_module.homepage_contentbox input[type="text"],
#submissionforms_module.homepage_contentbox input[type="tel"],
#submissionforms_module.homepage_contentbox input[type="email"],
#submissionforms_module.homepage_contentbox select,
#submissionforms_module.homepage_contentbox textarea {
	width: 100%;
	max-width: 100%;
	padding: 8px 12px;
	vertical-align: middle;
	background-color: #ffffff;
	border: 1px solid #ccc;
	font-size: 0.9rem;
	margin-bottom: 10px;
}

#submissionforms_module.homepage_contentbox textarea {
	width: 100% !important;
}

#submissionforms_module.homepage_contentbox .cms_field {
	padding-bottom: 5px;
}

#submissionforms_module.homepage_contentbox input[type="checkbox"],
#submissionforms_module.homepage_contentbox input[type="radio"] {
	margin-right: 3px;
}

#submissionforms_module.homepage_contentbox .sigWrapper .pad {
	max-width: 100%;
}

/****************** Floating Buttons ******************/
/***************************************************/
.navlinkwrapper {
	display: none;
}

.nav-link {
	border-top: 0px;
	border-bottom: 0px;
}

.navlinkwrapper .nav-link:first-child,
.navlinkwrapper .nav-link:nth-child(2),
.navlinkwrapper .nav-link:nth-child(3) {
	opacity: 0;
}

.navlinkwrapper .nav-link.active:first-child {
	opacity: 1;
	transition: opacity;
	transition-delay: 0.5s;
}

.navlinkwrapper .nav-link.active:nth-child(2) {
	opacity: 1;
	transition-delay: 0.3s;
}

.navlinkwrapper .nav-link.active:nth-child(3) {
	opacity: 1;
	transition-delay: 0.1s;
}

.bubble-section {
	margin-bottom: 60px;
}

@media screen and (max-width: 991px) {
	.bubble-section.stop-scroll {
		margin-bottom: 160px;
	}
}

@media screen and (max-width: 767px) {
	.navlinkwrapper {
		display: none !important;
	}
}
